import {gql} from "@apollo/client";

export const SEE_TOTAL_CHECK_LIST = gql`
    query seeTotalCheckList(
        $take: Int!
        $cursor: Int!
        $safetyCatgory: String
        $searchTerm: String
    ) {
        seeTotalCheckList(
            take: $take
            cursor: $cursor
            safetyCatgory: $safetyCatgory
            searchTerm: $searchTerm
        ) {
            tokenExpired
            result
            totalLength
            checkItemList {
                cli_id
                cli_riskCode
                cli_safetyCategory_kr
                cli_safetyCategory_eng
                cli_checkItem_kr
                cli_checkItem_eng
            }
        }
    }
`;