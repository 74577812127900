import {gql} from "@apollo/client";

export const EDIT_PRJ_PERMISSION_CHANGE = gql`
    mutation editPrjPermissionChange(
        $prj_id: Int
        $user_id: Int
        $permission: projectPermission
    ) {
        editPrjPermissionChange(
            prj_id: $prj_id
            user_id: $user_id
            permission: $permission
        ) {
            tokenExpired
            result
            projectExcelUrl
        }
    }
`;

export const EDIT_CHECK_LIST_ITEM_SETTING = gql`
    mutation editCheckListItemSetting(
        $cliInfo: [cliInfo]
    ) {
        editCheckListItemSetting(
            cliInfo: $cliInfo
        ) {
            tokenExpired
            result
        }
    }
`;
