import { gql } from "@apollo/client";

export const CREATE_PRJ_EXCEL = gql`
  mutation createPrjExcel(
    $searchTerm: String
    $sort: projectSort
    $status: projectStatus
    $start: String
    $end: String
  ) {
    createPrjExcel(
      searchTerm: $searchTerm
      sort: $sort
      status: $status
      start: $start
      end: $end
    ) {
      tokenExpired
      result
      projectExcelUrl
    }
  }
`;
