import React, { memo, useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import StyledCheckbox from "../../../../share/StyledCheckbox";
import StyledSelect from "../../../../share/StyledSelect";
import { useNavigate } from "react-router-dom";
import emptyProfile from "../../../../../assets/icon/emptyProfile.svg";
import { groupChanger } from "../../../../../lib/UserUtils";
import { dateFormat } from "../../../../../utils/common";
import { useMutation } from "@apollo/client";
import { EDIT_USER_STOP_STATUS } from "../../../../../graphql/User/UserList/UserList/mutation";
import { toast } from "react-toastify";
import { UserContext } from "../../../../../routes/User/UserList/UserList/UserListContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.grayBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  /* display: flex;
  align-items: center; */
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  padding-top: 3px;
  color: ${({ theme }) => theme.defaultFontColor};
`;

const Image = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-repeat: no-repeat;
  background-size: ${({ src }) => (src === "" ? "50%" : "cover")};
  background-position: 50%;
`;

const ClickArea = styled.div`
  display: flex;
  align-items: center;
  ${({ END }) =>
    END &&
    css`
      flex: 1;
      height: 100%;
    `}
`;

const RowTable = () => {
  const { userList, checkIndex, onClickCheckBox, userRefetch } =
    useContext(UserContext);

  const navigate = useNavigate();

  const [editUserStopStatus] = useMutation(EDIT_USER_STOP_STATUS);

  const handleUserStop = useCallback(async (id, userStop) => {
    try {
      const { data } = await editUserStopStatus({
        variables: {
          user_id: id,
          user_stop: !userStop,
        },
      });
      if (data?.editUserStopStatus?.result) {
        toast.success("이용여부를 변경하였습니다.");
        await userRefetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  return (
    <Container>
      {userList?.map((data, index) => (
        <RowBox key={index}>
          <StyledCheckbox
            clicked={checkIndex.includes(data.user_id)}
            onClick={() => onClickCheckBox(data.user_id)}
          />
          <ClickArea
            onClick={() => {
              navigate("/user/userList/detail");
              localStorage.setItem("userId", data.user_id);
            }}
          >
            <Text width={132}>
              {dateFormat(new Date(Number(data.user_createdAt)), "yyyy.MM.dd")}
            </Text>
            <Text width={102}>
              <Image src={data.user_profileImg} />
            </Text>
            <Text width={150}>{data.user_groupCompany}</Text>
            <Text width={128}>{groupChanger(data.user_group)}</Text>
            <Text width={128}>{data.user_name}</Text>
            <Text width={109}>{data.user_rank}</Text>
            <Text width={88}>{data.user_countryCode}</Text>
            <Text width={162}>{data.user_cellphone}</Text>
            <Text width={256}>{data.user_email}</Text>
          </ClickArea>
          {/* <Text width={90}> */}
          <StyledSelect
            NOBORDER
            ARROW
            padding={0}
            width={86}
            value={data.user_stop ? "정지" : "이용중"}
            onChange={() => handleUserStop(data.user_id, data.user_stop)}
            options={["이용중", "정지"]}
          />
          {/* </Text> */}
          <ClickArea
            END
            onClick={() => {
              navigate("/user/userList/detail", { state: data.index });
              localStorage.setItem("user_id", data.index);
            }}
          />
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
