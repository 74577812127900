import { gql } from "@apollo/client";

export const CREATE_ONE_ANSWER = gql`
  mutation createOneAnswer($one_id: Int, $answerText: String) {
    createOneAnswer(one_id: $one_id, answerText: $answerText) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_ONE = gql`
  mutation deleteOne($one_id: Int) {
    deleteOne(one_id: $one_id) {
      tokenExpired
      result
    }
  }
`;
