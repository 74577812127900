import React from "react";
import styled, { css } from "styled-components";
import emptyProfile from "../../../../../assets/icon/emptyProfile.svg";
import { groupChanger } from "../../../../../lib/UserUtils";

const Container = styled.div`
  height: 722px;
  grid-row: 1 / 3;
  grid-column: 2 / 3;
  position: relative;
`;
const ColumnScroll = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.mainColor};
  }
`;

const TitleText = styled.div`
  font-weight: bold;
  position: absolute;
  top: -45.5px;
`;

const Text = styled.div`
  /* display: flex; */
  color: ${({ GRAY, theme, BOLD }) =>
    GRAY
      ? !BOLD && theme.projectDetailStateCompleteColor
      : theme.defaultFontColor};

  font-weight: ${({ BOLD }) => BOLD && "bold"};
  font-size: ${({ BOLD }) => BOLD && 18}px;
  margin-left: ${({ BOLD }) => BOLD && 12}px;
  margin-bottom: ${({ GRAY }) => GRAY && 12}px;
  width: ${({ width }) => width}px;
  margin-top: ${({ marginAuto }) => marginAuto && 4}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid red; */
`;

const MemberBox = styled.div`
  width: 290px;
  height: 119px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 11px;
  border-bottom: 1px solid ${({ theme }) => theme.graphBorderColor};
`;

const ProfileImage = styled.div`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src !== "" ? `url(${src})` : `url(${emptyProfile})`};
  background-position: 50%;
  background-size: ${({ src }) => (src !== "" ? "cover" : "40%")};
  background-repeat: no-repeat;
  border: 0.5px solid ${({ theme }) => theme.graphBorderColor};
  flex-shrink: 0;
`;

const RowBox = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

const AuthCheck = styled.div`
  width: 50px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 2.5px;
  font-weight: bold;
  border-radius: 16px;
  transform: rotate(15deg);
  ${({ state }) =>
    state === "reject"
      ? css`
          border: 1px solid ${({ theme }) => theme.projectDetailStateOAColorl};
          color: ${({ theme }) => theme.projectDetailStateOAColorl};
        `
      : state === "auth"
      ? css`
          border: 1px solid ${({ theme }) => theme.approvalBorderColor};
          color: ${({ theme }) => theme.approvalBorderColor};
        `
      : state === "unauth" &&
        css`
          border: 1px solid ${({ theme }) => theme.projectStateEndColor};
          color: ${({ theme }) => theme.projectStateEndColor};
        `}
`;

const ApprovalLine = ({ data }) => {
  return (
    <Container>
      <TitleText>결재라인</TitleText>
      <ColumnScroll>
        <MemberBox>
          <AuthCheck
            state={
              data?.create_rejectStatus
                ? "reject"
                : data?.create_status
                ? "auth"
                : "unauth"
            }
          >
            {data?.create_rejectStatus
              ? "반려"
              : data?.create_status
              ? "승인"
              : "미승인"}
          </AuthCheck>

          <Text>발행자</Text>
          <RowBox>
            <ProfileImage src={data?.create_img} />
            <ColumnBox>
              <RowBox>
                <Text GRAY>{groupChanger(data?.create_group)}</Text>
                <Text BOLD width={120}>
                  {data?.create_name} {data?.create_rank}
                </Text>
              </RowBox>
              <Text width={185}>
                {data?.create_countryCode} {data?.create_cellphone}
              </Text>
              <Text marginAuto width={185}>
                {data?.create_email}
              </Text>
            </ColumnBox>
          </RowBox>
        </MemberBox>

        <MemberBox>
          <AuthCheck
            state={
              data?.check_rejectStatus
                ? "reject"
                : data?.check_status
                ? "auth"
                : "unauth"
            }
          >
            {data?.check_rejectStatus
              ? "반려"
              : data?.check_status
              ? "승인"
              : "미승인"}
          </AuthCheck>
          <Text>발행확인자</Text>
          <RowBox>
            <ProfileImage src={data?.check_img} />
            <ColumnBox>
              <RowBox>
                <Text GRAY>{groupChanger(data?.check_group)}</Text>
                <Text BOLD width={120}>
                  {data?.check_name} {data?.check_rank}
                </Text>
              </RowBox>
              <Text width={185}>
                {data?.check_countryCode} {data?.check_cellphone}
              </Text>
              <Text marginAuto width={185}>
                {data?.check_email}
              </Text>
            </ColumnBox>
          </RowBox>
        </MemberBox>

        <MemberBox>
          <AuthCheck
            state={
              data?.action_rejectStatus
                ? "reject"
                : data?.actionCheck_status
                ? "auth"
                : "unauth"
            }
          >
            {data?.action_rejectStatus
              ? "반려"
              : data?.action_status
              ? "승인"
              : "미승인"}
          </AuthCheck>
          <Text>조치자</Text>
          <RowBox>
            <ProfileImage src={data?.actionCheck_img} />
            <ColumnBox>
              <RowBox>
                <Text GRAY>{groupChanger(data?.action_group)}</Text>
                <Text BOLD width={120}>
                  {data?.action_name} {data?.action_rank}
                </Text>
              </RowBox>
              <Text width={185}>
                {data?.action_countryCode} {data?.action_cellphone}
              </Text>
              <Text marginAuto width={185}>
                {data?.action_email}
              </Text>
            </ColumnBox>
          </RowBox>
        </MemberBox>
        <MemberBox>
          <AuthCheck
            state={
              data?.manager_rejectStatus
                ? "reject"
                : data?.manager_status
                ? "auth"
                : "unauth"
            }
          >
            {data?.manager_rejectStatus
              ? "반려"
              : data?.manager_status
              ? "승인"
              : "미승인"}
          </AuthCheck>
          <Text>책임자</Text>
          <RowBox>
            <ProfileImage src={data?.manager_img} />
            <ColumnBox>
              <RowBox>
                <Text GRAY>{groupChanger(data?.manager_group)}</Text>
                <Text BOLD width={120}>
                  {data?.manager_name} {data?.manager_rank}
                </Text>
              </RowBox>
              <Text width={185}>
                {data?.manager_countryCode} {data?.manager_cellphone}
              </Text>
              <Text marginAuto width={185}>
                {data?.manager_email}
              </Text>
            </ColumnBox>
          </RowBox>
        </MemberBox>
        <MemberBox>
          <AuthCheck
            state={
              data?.actionCheck_rejectStatus
                ? "reject"
                : data?.actionCheck_status
                ? "auth"
                : "unauth"
            }
          >
            {data?.actionCheck_rejectStatus
              ? "반려"
              : data?.actionCheck_status
              ? "승인"
              : "미승인"}
          </AuthCheck>
          <Text>조치확인자</Text>
          <RowBox>
            <ProfileImage src={data?.actionCheck_img} />
            <ColumnBox>
              <RowBox>
                <Text GRAY>{groupChanger(data?.actionCheck_group)}</Text>
                <Text BOLD width={120}>
                  {data?.actionCheck_name} {data?.actionCheck_rank}
                </Text>
              </RowBox>
              <Text width={185}>
                {data?.actionCheck_countryCode} {data?.actionCheck_cellphone}
              </Text>
              <Text marginAuto width={185}>
                {data?.actionCheck_email}
              </Text>
            </ColumnBox>
          </RowBox>
        </MemberBox>
        <MemberBox>
          <AuthCheck
            state={
              data?.finalSign_rejectStatus
                ? "reject"
                : data?.finalSign_status
                ? "auth"
                : "unauth"
            }
          >
            {data?.finalSign_rejectStatus
              ? "반려"
              : data?.finalSign_status
              ? "승인"
              : "미승인"}
          </AuthCheck>
          <Text>최종승인자</Text>
          <RowBox>
            <ProfileImage src={data?.finalSign_img} />
            <ColumnBox>
              <RowBox>
                <Text GRAY>{groupChanger(data?.finalSign_group)}</Text>
                <Text BOLD width={120}>
                  {data?.finalSign_name} {data?.finalSign_rank}
                </Text>
              </RowBox>
              <Text width={185}>
                {data?.finalSign_countryCode} {data?.finalSign_cellphone}
              </Text>
              <Text marginAuto width={185}>
                {data?.finalSign_email}
              </Text>
            </ColumnBox>
          </RowBox>
        </MemberBox>
      </ColumnScroll>
    </Container>
  );
};

export default ApprovalLine;
