import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/Qna/RowTable";
import { QnaContext } from "./QnaContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const QnaPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    handleMinus,
    page,
    totalLength,
    onClickPage,
  } = useContext(QnaContext);
  return (
    <Container>
      <GuideLine label={["운영관리", "일대일 문의"]} />
      <ContentContainer>
        <SortingForm
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          handleMinus={handleMinus}
          placeholder="작성자, 질문내용"
          ONE
          SEARCH
          QNASTATE
          RESET
          MINUS
        />
        <StyledRowTable
          RADIO
          label={[
            { text: "작성일", width: 118 },
            { text: "작성자", width: 152 },
            { text: "소속회사명", width: 150 },
            { text: "소속", width: 100 },
            { text: "연락처", width: 152 },
            { text: "메일주소", width: 180 },
            { text: "내용", width: 604 },
            { text: "상태", width: 112 },
          ]}
        />
        <RowTable />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </ContentContainer>
    </Container>
  );
};

export default QnaPresenter;
