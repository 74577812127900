import React, { memo, useCallback, useContext } from "react";
import styled from "styled-components";
import { dateFormat } from "../../../../utils/common";
import { useMutation } from "@apollo/client";
import { CLOSE_PROJECT } from "../../../../graphql/Project/EndList/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  /* display: flex;
  align-items: center; */
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-right: 20px;
  color: ${({ theme }) => theme.defaultFontColor};
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  margin-left: 14px;
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.grayBackgroundColor};
`;

const EndButton = styled.div`
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.defaultFontColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;
`;

const RowTable = ({ data, endListRefetch }) => {
  const [closeProject] = useMutation(CLOSE_PROJECT);
  const { userLogout } = useContext(AuthContext);

  const onClickEndBtn = useCallback(async (id) => {
    if (window.confirm("정말 종료하시겠습니까?")) {
      try {
        const { data } = await closeProject({
          variables: {
            prj_id: id,
          },
        });
        if (data?.closeProject?.result) {
          toast.success("프로젝트를 종료하였습니다.");
          await endListRefetch();
        }
        if (data?.closeProject && !data?.closeProject?.tokenExpired) {
          userLogout();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, []);

  return (
    <Container>
      {data.map((data, index) => (
        <RowBox key={index}>
          <Text width={100}>
            <Image src={data.prj_img} />
          </Text>
          <Text width={114}>
            {dateFormat(new Date(data.prj_endRequestDate), "yyyy.MM.dd")}
          </Text>
          <Text width={166}>{data.prj_title}</Text>
          <Text width={230}>
            {dateFormat(new Date(Number(data.prj_startDate)), "yyyy.MM.dd")} ~
            {dateFormat(new Date(Number(data.prj_endDate)), "yyyy.MM.dd")}
          </Text>
          <Text width={328}>{data.prj_address}</Text>
          <Text width={132}>{data.prj_number}</Text>
          <Text width={102}>{data.prj_memberCount} 명</Text>
          <Text width={153}>{data.prj_scarCount} 건</Text>
          <Text width={150}>{data.prj_requester}</Text>
          <Text width={117}>
            {data.prj_status === "end" ? (
              "종료"
            ) : (
              <EndButton onClick={() => onClickEndBtn(data.prj_id)}>
                종료하기
              </EndButton>
            )}
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
