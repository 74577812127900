import React from "react";
import styled, { css } from "styled-components";

const Container = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ marginRight }) => marginRight}px;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.textFieldPlaceHolder};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  transition: 0.2s;
  cursor: pointer;
  ${({ clicked }) =>
    clicked &&
    css`
      border: 1px solid ${({ theme }) => theme.mainColor};
    `}
`;

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: 0.2s;
  background-color: ${({ clicked, theme }) =>
    clicked ? theme.mainColor : theme.defaultBackgroundColor};
`;

const StyledRadioButton = ({
  clicked = false,
  marginRight = 16,
  onClick = () => {},
}) => {
  return (
    <Container clicked={clicked} onClick={onClick} marginRight={marginRight}>
      <Circle clicked={clicked} />
    </Container>
  );
};

export default StyledRadioButton;
