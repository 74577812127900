import React, { useState, useCallback, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import StyledSelect from "../../../share/StyledSelect";
import StyledModal from "../../../share/StyledModal";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";
import { useMutation } from "@apollo/client";
import {
  CREATE_CHECK_LIST_ITEM,
  EDIT_CHECK_LIST_ITEM,
} from "../../../../graphql/Management/CheckList/mutation";
import StyledInput from "../../../share/StyledInput";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
  position: relative;
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

const InputBox = styled.textarea`
  width: 560px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};

  &::placeholder {
    color: ${({ theme }) => theme.textFieldBorder};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;
const CheckListAddModal = ({
  checkListModal,
  handleClose,
  safetyOptions,
  checkListRefetch,
  selectedItem,
  setSelectedItem,
  setRadioIndex,
}) => {
  const [inputs, setInputs] = useState({
    riskCode: "",
    safeKr: "",
    safeEng: "",
    kor: "",
    eng: "",
  });

  const [createCheckListItem] = useMutation(CREATE_CHECK_LIST_ITEM);
  const [editCheckListItem] = useMutation(EDIT_CHECK_LIST_ITEM);

  // const onChangeInputs = useCallback(
  //   (e) => {
  //     const { name, value } = e.target;
  //     setInputs({ ...inputs, [name]: value });
  //   },
  //   [inputs]
  // );

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      const maxLength = e.target.maxLength;
      const truncatedValue = value.slice(0, maxLength);

      setInputs({ ...inputs, [name]: truncatedValue });
    },
    [inputs]
  );

  const handleSave = useCallback(async () => {
    if (inputs.safeKr === "") {
      toast.error("안전범주명을 선택해주세요.");
      return;
    }
    if (inputs.kor.trim() === "") {
      toast.error("체크항목(한글)을 입력해주세요.");
    }
    if (inputs.eng.trim() === "") {
      toast.error("체크항목(영어)을 입력해주세요.");
      return;
    }

    try {
      switch (checkListModal) {
        case "CREATE":
          const { data: createData } = await createCheckListItem({
            variables: {
              cliRiskCode: inputs.riskCode,
              cliSafetyCategoryKr: inputs.safeKr,
              cliSafetyCategoryEng: inputs.safeEng,
              cliCheckItemKr: inputs.kor,
              cliCheckItemEng: inputs.eng,
            },
          });

          if (createData?.createCheckListItem?.result) {
            await checkListRefetch();
            toast.success("체크리스트 항목을 추가했습니다.");
            handleClose();
          }
          break;
        case "UPDATE":
          const { data: updateData } = await editCheckListItem({
            variables: {
              cliId: selectedItem.cli_id,
              cliRiskCode: inputs.riskCode,
              cliSafetyCategoryKr: inputs.safeKr,
              cliSafetyCategoryEng: inputs.safeEng,
              cliCheckItemKr: inputs.kor,
              cliCheckItemEng: inputs.eng,
            },
          });
          if (updateData?.editCheckListItem?.result) {
            await checkListRefetch();
            toast.success("체크리스트 항목을 수정했습니다.");
            handleClose();
            setSelectedItem(null);
            setRadioIndex(null);
          }
          break;
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs, selectedItem, checkListModal]);

  useEffect(() => {
    if (selectedItem !== null) {
      setInputs({
        riskCode: selectedItem.cli_riskCode,
        safeKr: selectedItem.cli_safetyCategory_kr,
        safeEng: selectedItem.cli_safetyCategory_eng,
        kor: selectedItem.cli_checkItem_kr,
        eng: selectedItem.cli_checkItem_eng,
      });
    }
  }, [selectedItem]);

  return (
    <StyledModal
      title={`체크리스트 항목 ${checkListModal === "CREATE" ? "추가" : "수정"}`}
      width={640}
      height={687}
      btnLabel={checkListModal === "CREATE" ? "등록" : "저장"}
      handleClose={handleClose}
      handleSave={handleSave}
    >
      <StyledInput
        label="리스크코드"
        width={560}
        margin="0 0 26px"
        value={inputs.riskCode}
        onChange={(e) => setInputs({ ...inputs, riskCode: e.target.value })}
        placeholder="리스크코드를 입력해주세요."
      />
      <StyledSelect
        ADD_CHECK_LIST
        ARROW
        label="안전범주명"
        width={560}
        placeholder="안전범주를 선택해주세요."
        value={inputs.safeKr}
        onChange={(_, value, event) =>
          setInputs({
            ...inputs,
            safeKr: value,
            safeEng: event.target.dataset.value,
          })
        }
        options={safetyOptions}
      />
      <Text marginTop={26}>
        체크항목(한글)
        <Text absolute>({inputs.kor.length}/200)</Text>
      </Text>
      <InputBox
        name="kor"
        height={100}
        placeholder="체크항목을 입력하세요"
        value={inputs.kor}
        onChange={onChangeInputs}
        maxLength={200}
      />
      <Text marginTop={26}>
        체크항목(영어)
        <Text absolute>({inputs.eng.length}/200)</Text>
      </Text>
      <InputBox
        name="eng"
        height={100}
        placeholder="체크항목을 입력하세요"
        value={inputs.eng}
        onChange={onChangeInputs}
        maxLength={200}
      />
    </StyledModal>
  );
};

export default CheckListAddModal;
