import React from "react";
import styled from "styled-components";

const TabContainer = styled.div`
  width: 100%;
  display: flex;
  margin-left: 16px;
  border-bottom: 2px solid ${({theme}) => theme.textFieldBorder};
`;

const TabButton = styled.div`
  width: 147px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  color: ${({theme, select}) =>
          select ? `${theme.whiteColor}` : `${theme.defaultFontColor}`};
  background-color: ${({theme, select}) => select && `${theme.mainColor}`};
  cursor: pointer;
`;

const Text = styled.div`
  font-weight: ${({BOLD}) => BOLD && "bold"};
  margin: ${({margin}) => margin};
`;

const DetailTab = ({tab, setTab}) => {
    return (
        <>
            <Text BOLD margin="0 0 20px 16px">
                SCAR 정보
            </Text>
            <TabContainer>
                <TabButton
                    select={tab === "발행목록"}
                    onClick={() => setTab("발행목록")}
                >
                    SCAR 발행 목록
                </TabButton>
                <TabButton select={tab === "통계"} onClick={() => setTab("통계")}>
                    통계
                </TabButton>
                <TabButton
                    select={tab === "체크리스트"}
                    onClick={() => setTab("체크리스트")}
                >
                    체크리스트
                </TabButton>
                <TabButton
                    select={tab === "체크리스트 설정"}
                    onClick={() => setTab("체크리스트 설정")}
                >
                    체크리스트 설정
                </TabButton>
            </TabContainer>
        </>
    );
};

export default DetailTab;
