import { gql } from "@apollo/client";

export const AUTH_REQUEST_USER_SIGN = gql`
  mutation authRequestUserSign($user_ids: [Int!], $auth: Boolean) {
    authRequestUserSign(user_ids: $user_ids, auth: $auth) {
      tokenExpired
      result
    }
  }
`;

export const REJECT_AUTH_REQUEST_USER = gql`
  mutation rejectAuthRequestUser($user_ids: [Int]) {
    rejectAuthRequestUser(user_ids: $user_ids) {
      tokenExpired
      result
    }
  }
`;
