import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";
import ServiceCenterPresenter from "./ServiceCenterPresenter";
import { useQuery, useMutation } from "@apollo/client";
import {
  SEE_TOS_LIST,
  SEE_VOC_NUMBER,
} from "../../../graphql/Management/ServiceCenter/query";
import {
  DELETE_TOS,
  CREATE_VOC_NUMBER,
  DELETE_VOC_NUMBER,
} from "../../../graphql/Management/ServiceCenter/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const ServiceCenterContext = createContext();

const ServiceCenterContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [phoneNumberInput, setPhoneNumberInput] = useState("");
  const [radioIndex, setRadioIndex] = useState(null);
  const [addTermsModal, setAddTermsModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [tosList, setTosList] = useState([]);
  const [vocList, setVocList] = useState([]);
  const [inputs, setInputs] = useState({
    sort: "고객센터 전화번호 등록",
  });

  const {
    data: TOSData,
    loading: TOSLoading,
    refetch: TOSRefetch,
  } = useQuery(SEE_TOS_LIST, {
    variables: {
      take: 16,
      cursor: cursor,
    },
  });

  const {
    data: VOSData,
    loading: VOSLoading,
    refetch: VOSRefetch,
  } = useQuery(SEE_VOC_NUMBER, { variables: {} });

  const [deleteTOS] = useMutation(DELETE_TOS);
  const [createVOCNumber] = useMutation(CREATE_VOC_NUMBER);
  const [deleteVOCNumber] = useMutation(DELETE_VOC_NUMBER);

  const onClickAddPhoneNumber = useCallback(async () => {
    if (phoneNumberInput.trim() === "") {
      toast.error("등록할 전화반호를 입력해주세요.");
      return;
    }
    try {
      const { data } = await createVOCNumber({
        variables: {
          vn_callNumber: phoneNumberInput,
        },
      });
      if (data?.createVOCNumber?.result) {
        toast.success("전화번호가 등록되었습니다.");
        await VOSRefetch();
      }
      if (data?.createVOCNumber && !data?.createVOCNumber?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setPhoneNumberInput("");
    }
  }, [phoneNumberInput]);

  const handleMinusPhoneNumber = useCallback(async (id) => {
    if (window.confirm("전화번호를 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteVOCNumber({
          variables: {
            vn_id: id,
          },
        });
        if (data?.deleteVOCNumber?.result) {
          await VOSRefetch();
          toast.success("전화번호를 삭제하였습니다.");
        }
        if (data?.deleteVOCNumber && !data?.deleteVOCNumber?.tokenExpired) {
          userLogout();
        }
      } catch (e) {
        toast.error(e.message);
      }
    }
  }, []);

  const handlePlus = useCallback(() => {
    setAddTermsModal(!addTermsModal);
  }, []);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      window.alert("삭제할 약관을 선택해주세요.");
      return;
    }
    if (window.confirm("약관을 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteTOS({
          variables: {
            tos_id: tosList[radioIndex].tos_id,
          },
        });
        if (data?.deleteTOS?.result) {
          await TOSRefetch();
          toast.success("약관을 삭제하였습니다.");
        }
        if (data?.deleteTOS && !data?.deleteTOS?.tokenExpired) {
          userLogout();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setRadioIndex(null);
      }
    }
    setRadioIndex(null);
  }, [radioIndex]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  const handleAddTermsModal = useCallback(() => {
    setAddTermsModal(!addTermsModal);
  }, [addTermsModal]);

  useEffect(() => {
    if (TOSData?.seeTOSList?.tokenExpired) {
      setTosList(TOSData.seeTOSList.tosList);
      setTotalLength(TOSData.seeTOSList.totalLength);
    }
    if (TOSData?.seeTOSList && !TOSData?.seeTOSList?.tokenExpired) {
      userLogout();
    }
  }, [TOSData]);

  useEffect(() => {
    if (VOSData?.seeVOCNumber?.tokenExpired) {
      setVocList(VOSData.seeVOCNumber.vocNumberList);
    }
    if (VOSData?.seeVOCNumber && !VOSData?.seeVOCNumber?.tokenExpired) {
      userLogout();
    }
  }, [VOSData]);

  return (
    <ServiceCenterContext.Provider
      value={{
        inputs,
        setInputs,
        handlePlus,
        handleMinus,
        page,
        totalLength,
        onClickPage,
        addTermsModal,
        setAddTermsModal,
        phoneNumberInput,
        setPhoneNumberInput,
        onClickAddPhoneNumber,
        radioIndex,
        onClickRadio,
        tosList,
        handleAddTermsModal,
        vocList,
        handleMinusPhoneNumber,
        TOSRefetch,
      }}
    >
      <ServiceCenterPresenter />
    </ServiceCenterContext.Provider>
  );
};

export default ServiceCenterContainer;
