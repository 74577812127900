import React, { useContext, useState } from "react";
import styled from "styled-components";
import StyledIconButton from "../../../../share/StyledIconButton";
import { UserDetailContext } from "../../../../../routes/User/UserList/UserDetail/UserDetailContainer";
import { dateFormat } from "../../../../../utils/common";
import { groupChanger } from "../../../../../lib/UserUtils";
import MailModal from "./MailModal";
import MessageModal from "./MessageModal";
import EditModal from "./editModal";
import emptyProfile from "../../../../../assets/icon/emptyProfile.svg";

const Container = styled.div`
  width: 1646px;
  height: 132px;
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 8px 0 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const ProfileImage = styled.div`
  width: 100px;
  height: 100px;
  margin-right: 40px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  background-repeat: no-repeat;
  background-position: 50%;
`;

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
  padding-right: 20px;
  margin-right: ${({ DEEPGRAY }) => DEEPGRAY && 6}px;
  color: ${({ theme, GRAY, DEEPGRAY }) =>
    GRAY
      ? `${theme.textFieldPlaceHolder}`
      : DEEPGRAY
      ? `${theme.profileTeamFontColor}`
      : `${theme.defaultFontColor}`};
`;

const TextSet = styled.div`
  width: ${({ width }) => width}px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const IconBtnSet = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 30px;
`;

const Summary = () => {
  const { userInfo } = useContext(UserDetailContext);

  const [messageModal, setMessageModal] = useState(false);
  const [mailModal, setMailModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  return (
    <>
      <Container>
        {console.log(userInfo)}
        <ProfileImage src={userInfo?.user_profileImg} />
        <TextSet width={148}>
          <Text GRAY>가입일</Text>
          <Text>
            {dateFormat(
              new Date(Number(userInfo?.user_createdAt)),
              "yyyy.MM.dd"
            )}
          </Text>
        </TextSet>
        <TextSet width={169}>
          <Text GRAY>소속회사명</Text>
          <Text>{userInfo?.user_groupCompany}</Text>
        </TextSet>
        <TextSet width={109}>
          <Text GRAY>소속</Text>
          {/* <Text style={{cursor: "pointer"}} onClick={() => setEditModal(true)}>{groupChanger(userInfo?.user_group)}</Text> */}
          <Text>{groupChanger(userInfo?.user_group)}</Text>
        </TextSet> 
        <TextSet width={162}>
          <Text GRAY>이름</Text>
          <Text>{userInfo?.user_name}</Text>
        </TextSet>
        <TextSet width={128}>
          <Text GRAY>직책</Text>
          <Text>{userInfo?.user_rank === "" ? "-" : userInfo?.user_rank}</Text>
        </TextSet>
        <TextSet width={88}>
          <Text GRAY>국가</Text>
          <Text>{userInfo?.user_countryCode}</Text>
        </TextSet>
        <TextSet width={162}>
          <Text GRAY>연락처</Text>
          <Text>{userInfo?.user_cellphone}</Text>
        </TextSet>
        <TextSet width={221}>
          <Text GRAY>메일주소</Text>
          <Text>{userInfo?.user_email}</Text>
        </TextSet>
        <IconBtnSet>
          <StyledIconButton EDIT onClick={() => setEditModal(true)} />
          <StyledIconButton MESSAGE onClick={() => setMessageModal(true)} />
          <StyledIconButton MAIL onClick={() => setMailModal(true)} />
        </IconBtnSet>
      </Container>
      {messageModal && (
        <MessageModal
          id={userInfo?.user_id}
          handleMessage={() => setMessageModal(false)}
        />
      )}
      {mailModal && (
        <MailModal
          id={userInfo?.user_id}
          handleMail={() => setMailModal(false)}
        />
      )}
      {editModal && (
        <EditModal
        id={userInfo}
        handleUser={() => setEditModal(false)}
      />
      )}
    </>
  );
};

export default Summary;
