import React, {memo, useCallback, useEffect} from "react";
import styled from "styled-components";
import StyledInput from "../share/StyledInput";
import StyledSelect from "../share/StyledSelect";
import StyledButton from "../share/StyledButton";
import StyledIconButton from "../share/StyledIconButton";
import StyledSelectDate from "../share/StyledSelectDate";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SortingWrapper = styled.div`
  display: flex;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  margin-top: auto;
`;

const Margin = styled.div`
  width: 18px;
`;

const SortingForm = ({
                         SEARCH,
                         SORT,
                         STATE,
                         DETAILSTATE,
                         TEAM,
                         USESTATE,
                         NOTICE,
                         PRJSORT,
                         FAQ,
                         ONE,
                         QNASTATE,
                         APPROVAL,
                         SERVICESORT,
                         USERSORT,
                         DETAILDATE,
                         DETAILLABEL,
                         DATE,
                         SCARSORT,
                         RESET,
                         GRADE,
                         PLUS,
                         MINUS,
                         EDIT,
                         CHECK_LIST_CATEGORY,
                         MAIL,
                         MESSAGE,
                         CHECKLIST,
                         EXCEL,
                         CHECK_LIST_SORT,
                         CHECK_LIST_RATIO,
                         CHECK_LIST_STATUS,
                         labelList,
                         inputs = {},
                         label = "정렬",
                         placeholder = "",
                         width = 280,
                         onChangeDate = () => null,
                         setInputs = () => null,
                         handleReset = () => null,
                         handlePlus = () => null,
                         handleMinus = () => null,
                         handleEdit = () => null,
                         handleMail = () => null,
                         handleMessage = () => null,
                         handleExcel = () => null,
                         safetyOptions = [],
                     }) => {
    const { searchTerm, safe, detailLabel, grade, team, sort, state, date, checkListSort, checkListStatus, checkListRatio } = inputs;

    const onChangeInputs = useCallback((e) => {
        const {name, value} = e.target;
        setInputs({...inputs, [name]: value});
    }, [inputs]);

    const onChangeSelect = useCallback((name, value) => {
        setInputs({
            ...inputs,
            [name]: value
        });
    }, [inputs]);

    return (
        <Container>
            <SortingWrapper>
                {CHECKLIST && (
                    <>
                        <StyledSelect
                            width={250}
                            ARROW
                            label={"안전범주"}
                            name="safe"
                            value={safe}
                            onChange={onChangeSelect}
                            options={safetyOptions.map(opt => opt.safetyKr)}
                        />
                        <Margin/>
                    </>
                )}
                {SEARCH && (
                    <>
                        <StyledInput
                            width={width}
                            SEARCH
                            label="검색"
                            placeholder={placeholder}
                            name="searchTerm"
                            value={searchTerm}
                            onChange={onChangeInputs}
                        />
                        <Margin/>
                    </>
                )}

                {DETAILDATE && (
                    <>
                        <StyledSelectDate
                            label="집계 기간"
                            date={date}
                            onChange={onChangeDate}
                        />
                        <Margin/>
                    </>
                )}
                {PRJSORT && (
                    <>
                        <StyledSelect
                            ARROW
                            label={"정렬"}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["최신순", "오래된순", "프로젝트명", "현장주소"]}
                        />
                        <Margin/>
                    </>
                )}
                {SORT && (
                    <>
                        <StyledSelect
                            ARROW
                            label={label}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["최신 순", "오래된 순"]}
                        />
                        <Margin/>
                    </>
                )}
                {SCARSORT && (
                    <>
                        <StyledSelect
                            ARROW
                            label={label}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["최신순", "위반사항"]}
                        />
                        <Margin/>
                    </>
                )}
                {NOTICE && (
                    <>
                        <StyledSelect
                            ARROW
                            label={label}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["게시일 순", "제목 순"]}
                        />
                        <Margin/>
                    </>
                )}
                {FAQ && (
                    <>
                        <StyledSelect
                            ARROW
                            label={label}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["등록일 순", "질문명 순"]}
                        />
                        <Margin/>
                    </>
                )}

                {USERSORT && (
                    <>
                        <StyledSelect
                            ARROW
                            label={label}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["최신순", "오래된순", "이름순", "회사명순"]}
                        />
                        <Margin/>
                    </>
                )}
                {ONE && (
                    <>
                        <StyledSelect
                            ARROW
                            label={label}
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["작성일순", "작성자순"]}
                        />
                        <Margin/>
                    </>
                )}
                {STATE && (
                    <>
                        <StyledSelect
                            ARROW
                            label={"필터"}
                            name="state"
                            value={state}
                            onChange={onChangeSelect}
                            options={["전체", "진행중", "종료예정", "종료"]}
                        />
                        <Margin/>
                    </>
                )}
                {CHECK_LIST_CATEGORY && (
                    <>
                        <StyledSelect
                            ARROW
                            width={200}
                            label={"안전범주"}
                            name="safe"
                            value={safe}
                            onChange={onChangeSelect}
                            options={["전체", "현장정보", "개인 안전 보호 장비", "전기"]}
                        />
                        <Margin/>
                    </>
                )}
                {DETAILSTATE && (
                    <>
                        <StyledSelect
                            ARROW
                            label="상태"
                            name="state"
                            value={state}
                            onChange={onChangeSelect}
                            options={[
                                "전체",
                                "미조치",
                                "진행중",
                                "완료",
                                "조치불가",
                                "반려",
                                "지연",
                            ]}
                        />
                        <Margin/>
                    </>
                )}
                {GRADE && (
                    <>
                        <StyledSelect
                            ARROW
                            label="위험등급"
                            name="grade"
                            value={grade}
                            onChange={onChangeSelect}
                            options={["전체", "상", "중", "하"]}
                        />
                        <Margin/>
                    </>
                )}
                {DETAILLABEL && (
                    <>
                        <StyledSelect
                            ARROW
                            label="라벨"
                            name="detailLabel"
                            value={detailLabel}
                            onChange={onChangeSelect}
                            options={["전체", ...labelList]}
                        />
                        <Margin/>
                    </>
                )}
                {TEAM && (
                    <>
                        <StyledSelect
                            ARROW
                            label="소속"
                            name="team"
                            value={team}
                            onChange={onChangeSelect}
                            options={["전체", "PM본사", "PM현장", "시공사", "발주처"]}
                        />
                        <Margin/>
                    </>
                )}
                {USESTATE && (
                    <>
                        <StyledSelect
                            ARROW
                            label="이용여부"
                            name="state"
                            value={state}
                            onChange={onChangeSelect}
                            options={["전체", "이용중", "정지"]}
                        />
                        <Margin/>
                    </>
                )}
                {QNASTATE && (
                    <>
                        <StyledSelect
                            ARROW
                            label="상태"
                            name="state"
                            value={state}
                            onChange={onChangeSelect}
                            options={["전체", "답변완료", "미완료"]}
                        />
                        <Margin/>
                    </>
                )}
                {APPROVAL && (
                    <>
                        <StyledSelect
                            ARROW
                            label="승인여부"
                            name="state"
                            value={state}
                            onChange={onChangeSelect}
                            options={["전체", "승인", "미승인"]}
                        />
                        <Margin/>
                    </>
                )}
                {SERVICESORT && (
                    <>
                        <StyledSelect
                            ARROW
                            width={220}
                            label="구분"
                            name="sort"
                            value={sort}
                            onChange={onChangeSelect}
                            options={["고객센터 전화번호 등록", "이용약관 등록"]}
                        />
                        <Margin/>
                    </>
                )}
                {DATE && (
                    <>
                        <StyledSelectDate date={date} onChange={onChangeDate}/>
                        <Margin/>
                    </>
                )}

                {/* 체크리스트 */}
                {CHECK_LIST_SORT && (
                    <StyledSelect
                        ARROW
                        label={label}
                        name="checkListSort"
                        value={checkListSort}
                        onChange={onChangeSelect}
                        options={["최신순", "평가완료일", "준수율"]}
                    />
                )}
                {CHECK_LIST_STATUS && (
                    <StyledSelect
                        ARROW
                        label='상태'
                        name="checkListStatus"
                        value={checkListStatus}
                        onChange={onChangeSelect}
                        options={["전체", "미평가", "평가중", "평가완료"]}
                        margin='0 18px'
                    />
                )}
                {CHECK_LIST_RATIO && (
                    <StyledSelect
                        ARROW
                        label='준수율'
                        name="checkListRatio"
                        value={checkListRatio}
                        onChange={onChangeSelect}
                        options={["전체", "준수", "대부분준수", "일부준수"]}
                        margin='0 18px 0 0'
                    />
                )}

                {RESET && (
                    <>
                        <StyledButton RESET label="초기화" onClick={handleReset}/>
                        <Margin/>
                    </>
                )}
            </SortingWrapper>
            <IconButtonWrapper>
                {PLUS && <StyledIconButton PLUS onClick={handlePlus}/>}
                {MINUS && <StyledIconButton MINUS onClick={handleMinus}/>}
                {EDIT && <StyledIconButton EDIT onClick={handleEdit}/>}
                {MESSAGE && <StyledIconButton MESSAGE onClick={handleMessage}/>}
                {MAIL && <StyledIconButton MAIL onClick={handleMail}/>}
                {EXCEL && <StyledIconButton EXCEL onClick={handleExcel}/>}
            </IconButtonWrapper>
        </Container>
    );
};

export default memo(SortingForm);
