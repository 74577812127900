import React, {memo, useContext, useState} from "react";
import styled from "styled-components";

import {FiImage} from "react-icons/fi";
import {PrjDetailContext} from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import {dateFormat} from "../../../../../utils/common";
import {checkListStatusChanger} from "../../../../../lib/ProjectUtils";
import {groupChanger} from "../../../../../lib/UserUtils";
import CheckListDetailModal from "./CheckListDetailModal";

const Container = styled.div`
  width: 100%;
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({theme}) => theme.scrollBackgroundColor};
  }
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({theme}) => theme.grayBackgroundColor};
  background-color: ${({theme}) => theme.defaultBackgroundColor};
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.grayBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({width}) => width}px;
  margin: ${({margin}) => margin};
  /* display: flex;
  align-items: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
  padding-right: 30px;
  white-space: nowrap;
  color: ${({theme, GRAY}) =>
          GRAY ? `${theme.tableLinkFontColor}` : `${theme.defaultFontColor}`};

  & > span + span {
    margin: 0 2px;
  }

  & > span::after {
    content: ",";
  }

  & > span:last-child::after {
    content: "";
  }
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  color: ${({theme}) => theme.textFieldBorder};
  object-fit: cover;
  border: 1px solid ${({theme}) => theme.grayBackgroundColor};
`;

const EmptyBox = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({theme}) => theme.whiteColor};
  background-color: ${({theme}) => theme.textFieldBorder};
`;

const CheckListRowTable = ({checkList = []}) => {
    const {onClickCheckListDetail} = useContext(PrjDetailContext);

    return (
        <>
            <Container>
                {checkList.map((data, index) => (
                    <RowBox
                        key={index}
                        onClick={() => onClickCheckListDetail(data?.wcl_id)}
                    >
                        <Text width={172}>
                            {dateFormat(parseInt(data.wcl_createdAt, 10), "yyyy-MM-dd hh:mm")}
                        </Text>
                        <Text width={166}>
                            {data.wcl_year}년 {data.wcl_month}월 {data.wcl_week}주차
                        </Text>
                        <Text width={148}>{checkListStatusChanger(data.wcl_status)}</Text>
                        <Text width={102}>{data.wcl_complyRate}%</Text>
                        <Text width={88}>{data.wcl_totalScore}</Text>
                        <Text width={115}>{data.wcl_evaluationScore}</Text>
                        <Text width={125}>{data.wcl_repeatedViolation}</Text>
                        <Text width={410}>{data.wcl_evaluation}</Text>
                        <DotWrapper>
                            {data?.wcl_img.length > 0 ? (
                                <Image src={data?.wcl_img[0]?.wcli_url}/>
                            ) : (
                                <EmptyBox>
                                    <FiImage/>
                                </EmptyBox>
                            )}
                            <Text width={80} margin="0 0  0 10px" GRAY>
                                × {data?.wcl_img.length}
                            </Text>
                        </DotWrapper>

                        <Text width={170}>
                            {groupChanger(data.wcl_evaluatorGroup)} {data.wcl_evaluatorName}
                        </Text>
                    </RowBox>
                ))}
            </Container>
        </>
    );
};

export default memo(CheckListRowTable);
