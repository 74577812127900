export const noticeSortChanger = (value) => {
  switch (value) {
    case "게시일 순":
      return "createdAt";
    case "제목 순":
      return "title";
  }
};

export const faqSortChanger = (value) => {
  switch (value) {
    case "등록일 순":
      return "createdAt";
    case "질문명 순":
      return "question";
  }
};

export const qnaSortChanger = (value) => {
  switch (value) {
    case "작성일순":
      return "createdAt";
    case "작성자순":
      return "writer";
  }
};

export const qnaStatusChanger = (value) => {
  switch (value) {
    case "전체":
      return "total";
    case "답변완료":
      return "true";
    case "미완료":
      return "false";
  }
};
