import React, {useContext} from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import {CheckListContext} from "./CheckListContainer";
import StyledRowTable from "../../../components/share/StyledRowTable";
import RowTable from "../../../components/feature/Management/CheckList/RowTable";
import PageNation from "../../../components/layout/PageNation";
import CheckListAddModal from "../../../components/feature/Management/CheckList/CheckListAddModal";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({theme}) => theme.defaultBackgroundColor};
`;

const CheckListPresenter = ({
                                safetyOptions,
                                checkListRefetch,
                                checkItemList,
                                selectedItem,
                                setSelectedItem,
                                setRadioIndex
                            }) => {
    const {
        inputs,
        setInputs,
        handleReset,
        handlePlus,
        handleMinus,
        handleEdit,
        page,
        onClickPage,
        totalLength,
        checkListModal,
        setCheckListModal,
    } = useContext(CheckListContext);
    return (
        <>
            <Container>
                <GuideLine label={["운영관리", "체크리스트 관리"]}/>
                <ContentContainer>
                    <SortingForm
                        inputs={inputs}
                        setInputs={setInputs}
                        handleReset={handleReset}
                        handlePlus={handlePlus}
                        handleMinus={handleMinus}
                        handleEdit={handleEdit}
                        safetyOptions={safetyOptions}
                        placeholder=""
                        SEARCH
                        CHECKLIST
                        RESET
                        PLUS
                        MINUS
                        EDIT
                    />
                    <StyledRowTable
                        RADIO
                        label={[
                            {text: "안전범주", width: 340},
                            {text: "리스크코드", width: 100},
                            {text: "체크항목", width: 1000},
                        ]}
                    />
                    <RowTable
                        checkItemList={checkItemList}
                    />
                    <PageNation
                        page={page}
                        take={10}
                        onClickPage={onClickPage}
                        totalLength={totalLength}
                    />
                </ContentContainer>
            </Container>

            {checkListModal !== null && (
                <CheckListAddModal
                    checkListModal={checkListModal}
                    handleClose={() => setCheckListModal(null)}
                    safetyOptions={safetyOptions}
                    checkListRefetch={checkListRefetch}
                    selectedItem={selectedItem}
                    setSelectedItem={setSelectedItem}
                    setRadioIndex={setRadioIndex}
                />
            )}
        </>
    );
};

export default CheckListPresenter;
