import React, { useState, useCallback, useContext, useEffect } from "react";
import styled, { css } from "styled-components";
import StyledButton from "../../../share/StyledButton";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";
import { useMutation } from "@apollo/client";
import { EDIT_NOTICE } from "../../../../graphql/Management/Notice/mutatioin";
import { toast } from "react-toastify";
import { NoticeContext } from "../../../../routes/Management/Notice/NoticeContainer";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
  position: relative;
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

const InputBox = styled.textarea`
  width: 520px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const FileUpLoadCover = styled.div`
  white-space: nowrap;
  width: 240px;
  height: 46px;
  padding: 14px 16px;
  margin-top: 11px;
  margin-right: 16px;
  border-radius: 8px;
  color: ${({ GRAY, theme }) => GRAY && `${theme.textFieldPlaceHolder}`};
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  overflow-x: auto;
`;

const FileUpLoad = styled.input`
  display: none;
`;

const NoticeEditModal = ({ handleClose }) => {
  const { userLogout } = useContext(AuthContext);
  const { noticeRefetch, noticeList, radioIndex } = useContext(NoticeContext);
  const editData = noticeList[radioIndex];

  const [inputs, setInputs] = useState({
    title: editData.noti_title,
    contents: editData.noti_text,
    file: editData.noti_img,
  });

  const [editNotice] = useMutation(EDIT_NOTICE);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const onClickUpload = (e) => {
    e.preventDefault();
    const fileUpload = document.getElementById("fileUpload");
    fileUpload.click();
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];

    if (file.size / 1024 / 1024 > 5) {
      toast.error("이미지 파일은 5MB 이하로 업로드해주세요.");
      return;
    }

    setInputs({ ...inputs, file: file });
  };

  const handleSave = useCallback(async () => {
    if (inputs.title.trim() === "") {
      toast.error("제목을 입력해주세요.");
      return;
    }
    if (inputs.contents.trim() === "") {
      toast.error("내용을 입력해주세요.");
    }

    try {
      let variables;
      if (typeof inputs.file === "string") {
        variables = {
          noti_id: editData.noti_id,
          noti_title: inputs.title,
          noti_text: inputs.contents,
          noti_imgSelect: false,
        };
      } else {
        variables = {
          noti_id: editData.noti_id,
          noti_title: inputs.title,
          noti_text: inputs.contents,
          noti_imgSelect: true,
          noti_img: inputs.file,
        };
      }
      const { data } = await editNotice({
        variables: variables,
      });
      if (data?.editNotice?.result) {
        toast.success("공지를 수정하였습니다.");
        await noticeRefetch();
      }
      if (data?.editNotice && !data?.editNotice?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      handleClose();
    }
  }, [inputs]);

  return (
    <StyledModal
      title="공지 수정하기"
      width={600}
      height={698}
      handleClose={handleClose}
      handleSave={handleSave}
    >
      <StyledInput
        name="title"
        label="제목"
        width={520}
        placeholder="공지 제목을 입력하세요"
        value={inputs.title}
        onChange={onChangeInputs}
      />

      <Text marginTop={26}>
        내용
        <Text absolute>({inputs.contents.length}/200)</Text>
      </Text>
      <InputBox
        name="contents"
        height={247}
        placeholder="공지 내용을 입력하세요"
        value={inputs.contents}
        onChange={onChangeInputs}
        maxLength={200}
      />
      <Text marginTop={26}>첨부파일</Text>
      <RowBox>
        <FileUpLoadCover GRAY={inputs.file === ""}>
          {inputs.file === ""
            ? "첨부파일을 업로드해주세요"
            : typeof inputs.file === "string" && inputs.file.length > 4
            ? inputs.file.substring(0, 4) === "http" &&
              inputs.file.substring(43)
            : inputs.file.name}
        </FileUpLoadCover>
        <FileUpLoad
          id="fileUpload"
          name="file"
          type="file"
          accept=".jpeg, .jpg, .png"
          onChange={onChangeFile}
        />
        <StyledButton
          BLACK
          label="파일첨부"
          onClick={(e) => onClickUpload(e)}
        />
      </RowBox>
    </StyledModal>
  );
};

export default NoticeEditModal;
