import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";
import ApprovalListPresenter from "./ApprovalListPresenter";
import { SEE_AUTH_REQUEST_USER_LIST } from "../../../graphql/User/Approval/query";
import { useQuery, useMutation } from "@apollo/client";
import { authChanger, userSortChanger } from "../../../lib/UserUtils";
import {
  AUTH_REQUEST_USER_SIGN,
  REJECT_AUTH_REQUEST_USER,
} from "../../../graphql/User/Approval/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const ApprovalContext = createContext();

const ApprovalListContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [checkIndex, setCheckIndex] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [userList, setUserList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신 순",
    state: "전체",
  });
  const [select, setSelect] = useState([]);

  const {
    data: userData,
    loading: userLoading,
    refetch: userRefetch,
  } = useQuery(SEE_AUTH_REQUEST_USER_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: userSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  const [authRequestUserSign] = useMutation(AUTH_REQUEST_USER_SIGN);
  const [rejectAuthRequestUser] = useMutation(REJECT_AUTH_REQUEST_USER);

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "최신 순",
      state: "전체",
    });
  }, [inputs]);

  const handleApproval = useCallback(
    async (auth) => {
      if (checkIndex.length === 0) {
        toast.error("유저를 선택해주세요.");
        return;
      }

      for (let i = 0; i < userList.length; i++) {
        for (let j = 0; j < checkIndex.length; j++) {
          if (
            userList[i].user_id === checkIndex[j] &&
            userList[i].user_headAuth
          ) {
            toast.error(
              auth
                ? "이미 승인된 유저가 포함되어 있습니다."
                : "승인된 유저에 대해서는 거절할 수 없습니다."
            );
            return;
          }
        }
      }

      if (auth) {
        // 승인
        try {
          const { data } = await authRequestUserSign({
            variables: {
              user_ids: checkIndex,
              auth: auth,
            },
          });

          if (data?.authRequestUserSign?.result) {
            await userRefetch();
            toast.success(`${auth ? "승인" : "거절"}을 완료하였습니다.`);
            onClickCheckBox(-1);
          }
        } catch (e) {
          toast.error(e.message);
        }
      } else {
        // 거절
        try {
          const { data } = await rejectAuthRequestUser({
            variables: {
              user_ids: checkIndex,
            },
          });
          if (data?.rejectAuthRequestUser?.result) {
            await userRefetch();
            toast.success("거절을 완료하였습니다.");
            onClickCheckBox(-1);
          }
        } catch (e) {
          toast.error(e.message);
        }
      }
    },
    [checkIndex]
  );

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickCheckBox = useCallback(
    (id) => {
      if (id === -1) {
        if (checkIndex.length !== 0) setCheckIndex([]);
        else setCheckIndex(userList.map((user) => user.user_id));
        return;
      }
      if (checkIndex.includes(id)) {
        setCheckIndex(checkIndex.filter((userId) => id !== userId));
        return;
      }
      setCheckIndex([...checkIndex, id]);
    },
    [checkIndex]
  );

  useEffect(() => {
    if (userData?.seeAuthRequestUserList?.result) {
      setUserList(userData.seeAuthRequestUserList.userList);
      setTotalLength(userData?.seeAuthRequestUserList?.totalLength);
    }
    if (
      userData?.seeAuthRequestUserList &&
      !userData?.seeAuthRequestUserList?.tokenExpired
    ) {
      userLogout();
    }
  }, [userData]);

  return (
    <ApprovalContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handleApproval,
        page,
        totalLength,
        onClickPage,
        checkIndex,
        onClickCheckBox,
        userList,
      }}
    >
      <ApprovalListPresenter />
    </ApprovalContext.Provider>
  );
};

export default ApprovalListContainer;
