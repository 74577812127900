import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Admin/AdminList/RowTable";
import AddModal from "../../../components/feature/Admin/AdminList/AddModal";
import { AdminContext } from "./AdminListContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const AdminListPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    handlePlus,
    handleMinus,
    page,
    totalLength,
    onClickPage,
    addAdminModal,
  } = useContext(AdminContext);
  return (
    <>
      <Container>
        <GuideLine label={["사용자관리", "사용자 목록"]} />
        <ContentContainer>
          <SortingForm
            inputs={inputs}
            setInputs={setInputs}
            handleReset={handleReset}
            handlePlus={handlePlus}
            handleMinus={handleMinus}
            placeholder="사원명"
            SEARCH
            RESET
            PLUS
            MINUS
          />
          <StyledRowTable
            RADIO
            label={[
              { text: "순번", width: 88 },
              { text: "부서", width: 128 },
              { text: "사원명", width: 102 },
              { text: "직급", width: 108 },
              { text: "아이디", width: 162 },
              { text: "비밀번호", width: 176 },
              { text: "권한", width: 428 },
              { text: "권한수정", width: 170 },
            ]}
          />
          <RowTable />
          <PageNation
            page={page}
            onClickPage={onClickPage}
            totalLength={totalLength}
          />
        </ContentContainer>
      </Container>
      {addAdminModal && <AddModal />}
    </>
  );
};

export default AdminListPresenter;
