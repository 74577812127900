import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import UserDetailPresenter from "./UserDetailPresenter";
import { useQuery } from "@apollo/client";
import {
  SEE_USER_DETAIL_INFO,
  SEE_USER_PRJ_LIST,
} from "../../../../graphql/User/UserList/UserDetail/query";
import { userSortChanger } from "../../../../lib/UserUtils";
import { AuthContext } from "../../../../context/AuthProvider";

export const UserDetailContext = createContext();

const UserDetailContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState(null);
  const [prjList, setPrjList] = useState([]);
  const [cursor, setCursor] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [page, setPage] = useState(1);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신순",
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "최신순",
    });
  }, [inputs]);

  const {
    data: userDetailData,
    loading: userDetailLoading,
    refetch: userDetailRefetch,
  } = useQuery(SEE_USER_DETAIL_INFO, {
    variables: {
      user_id: Number(localStorage.getItem("userId")),
    },
  });

  const {
    data: userPrjData,
    loading: userPrjLoading,
    refetch: userPrjRefetch,
  } = useQuery(SEE_USER_PRJ_LIST, {
    variables: {
      userId: Number(localStorage.getItem("userId")),
      searchTerm: inputs.searchTerm,
      sort: userSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  useEffect(() => {
    if (userDetailData?.seeUserDetailInfo?.result) {
      setUserInfo(userDetailData.seeUserDetailInfo.userInfo);
    }
    if (
      userDetailData?.seeUserDetailInfo &&
      !userDetailData?.seeUserDetailInfo?.tokenExpired
    ) {
      userLogout();
    }
  }, [userDetailData]);

  useEffect(() => {
    if (userPrjData?.seeUserPrjList?.result) {
      setTotalLength(userPrjData?.seeUserPrjList?.totalLength);
      setPrjList(userPrjData?.seeUserPrjList?.userProjectList);
    }
    if (
      userPrjData?.seeUserPrjList &&
      !userPrjData?.seeUserPrjList?.tokenExpired
    ) {
      userLogout();
    }
  }, [userPrjData]);

  return (
    <UserDetailContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        userDetailRefetch,
        userInfo,
        prjList,
        onClickPage,
        page,
        totalLength,
      }}
    >
      <UserDetailPresenter />
    </UserDetailContext.Provider>
  );
};

export default UserDetailContainer;
