import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/Faq/RowTable";
import FaqAddModal from "../../../components/feature/Management/Faq/FaqAddModal";
import FaqEditModal from "../../../components/feature/Management/Faq/FaqEditModal";
import { FaqContext } from "./FaqContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const FaqPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    handlePlus,
    handleMinus,
    handleEdit,
    page,
    totalLength,
    onClickPage,
    addModal,
    editModal,
  } = useContext(FaqContext);
  return (
    <>
      <Container>
        <GuideLine label={["운영관리", "자주 묻는 질문"]} />
        <ContentContainer>
          <SortingForm
            inputs={inputs}
            setInputs={setInputs}
            handleReset={handleReset}
            handlePlus={handlePlus}
            handleMinus={handleMinus}
            handleEdit={handleEdit}
            placeholder="질문내용, 답변"
            SEARCH
            FAQ
            RESET
            PLUS
            MINUS
            EDIT
          />
          <StyledRowTable
            RADIO
            label={[
              { text: "등록일", width: 142 },
              { text: "질문명", width: 360 },
              { text: "내용", width: 700 },
            ]}
          />
          <RowTable />
          <PageNation
            page={page}
            onClickPage={onClickPage}
            totalLength={totalLength}
          />
        </ContentContainer>
      </Container>
      {addModal && <FaqAddModal />}
      {editModal && <FaqEditModal />}
    </>
  );
};

export default FaqPresenter;
