import { gql } from "@apollo/client";

export const EDIT_USER_STOP_STATUS = gql`
  mutation editUserStopStatus($user_id: Int, $user_stop: Boolean) {
    editUserStopStatus(user_id: $user_id, user_stop: $user_stop) {
      tokenExpired
      result
    }
  }
`;

export const SEND_MESSAGE_USER = gql`
  mutation sendMessageUser($user_ids: [Int!], $msg: String) {
    sendMessageUser(user_ids: $user_ids, msg: $msg) {
      tokenExpired
      result
    }
  }
`;

export const SEND_EMAIL_USER = gql`
  mutation sendEmailUser($user_ids: [Int!], $subject: String, $msg: String) {
    sendEmailUser(user_ids: $user_ids, subject: $subject, msg: $msg) {
      tokenExpired
      result
    }
  }
`;
