import React, { memo, useContext, useState } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../share/StyledRadioButton";
import CodeDetailModal from "./CodeDetailModal";
import { RiskcodeContext } from "../../../../routes/Management/RiskCode/RiskCodeContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  &:hover {
    background-color: ${({ theme }) => theme.grayBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  font-weight: 500;
  color: ${({ theme }) => theme.defaultFontColor};
  padding-top: 3px;
`;

const ClickArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RowTable = () => {
  const { riskcodeList, radioIndex, onClickRadio } =
    useContext(RiskcodeContext);

  const [index, setIndex] = useState(0);
  const [detailModal, setDetailModal] = useState(false);

  return (
    <>
      <Container>
        {riskcodeList.length !== 0 &&
          riskcodeList.map((data, index) => (
            <RowBox key={index}>
              <StyledRadioButton
                clicked={radioIndex === index}
                onClick={() => onClickRadio(index)}
              />
              <ClickArea
                onClick={() => {
                  setIndex(index);
                  setDetailModal(true);
                }}
              >
                <Text width={202}>{data.rc_name}</Text>
                <Text width={358}>{data.rc_title}</Text>
                <Text width={978}>{data.rc_text}</Text>
              </ClickArea>
            </RowBox>
          ))}
      </Container>
      {detailModal && (
        <CodeDetailModal
          data={riskcodeList[index]}
          handleClose={() => setDetailModal(false)}
        />
      )}
    </>
  );
};

export default memo(RowTable);
