import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import {
  riskGradeChanger,
  scarStatusChanger,
} from "../../../../../lib/ProjectUtils";
import { groupChanger } from "../../../../../lib/UserUtils";
import { dateFormat } from "../../../../../utils/common";
import StyledModal from "../../../../share/StyledModal";
import StyledSwiper from "../../../../share/StyledSwiper";
import ApprovalLine from "./ApprovalLine";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  column-gap: 50px;
  grid-template-columns: 1fr 310px;
  grid-template-rows: 1fr 1fr;
`;

const RowBox = styled.div`
  grid-column: 1 / 2;
  width: 100%;
  display: flex;
  &:first-child {
    border-bottom: 1px solid ${({ theme }) => theme.textFieldBorder};
    height: 359px;
  }
`;

const ColumnBox = styled.div`
  width: 100%;
  height: 389px;
  display: flex;
  flex-direction: column;
`;

const InfoBox = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : 290)}px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 26px;
`;

const Text = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  margin-bottom: ${({ BOLD }) => BOLD && 26}px;
  color: ${({ theme, GRAY }) => GRAY && theme.textFieldPlaceHolder};
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: -45.5px;
    `}
  & > span::after {
    content: ", ";
    margin-right: 4px;
  }
  & > span:last-child::after {
    content: "";
    margin: 0;
  }
`;

const TextWrapper = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => (height ? height : 48)}px;
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;

  & > ${Text}:last-child {
    margin-top: 12px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailModal = ({ scarInfo, handleClose }) => {
  return (
    <StyledModal
      title="SCAR 발행정보"
      width={1372}
      height={936}
      CLOSEBTN
      handleClose={handleClose}
    >
      <Container>
        <RowBox>
          <StyledSwiper imageList={scarInfo?.img?.map((img) => img.si_img)} />
          <InfoBox>
            <TextWrapper width={113}>
              <Text GRAY>발행일</Text>
              <Text>
                {dateFormat(
                  new Date(Number(scarInfo?.scar_createdAt)),
                  "yyyy.MM.dd"
                )}
              </Text>
            </TextWrapper>
            <TextWrapper width={112}>
              <Text GRAY>조치기한</Text>
              <Text>
                {" "}
                {dateFormat(
                  new Date(Number(scarInfo?.scar_endDate)),
                  "yyyy.MM.dd"
                )}
              </Text>
            </TextWrapper>
            <TextWrapper width={152}>
              <Text GRAY>상태</Text>
              <Text>{scarStatusChanger(scarInfo?.scar_signStatus)}</Text>
            </TextWrapper>
            <TextWrapper width={195}>
              <Text GRAY>발행자</Text>
              <Text>
                {groupChanger(scarInfo?.signLine?.create_group)}{" "}
                {scarInfo?.signLine?.create_name}{" "}
                {scarInfo?.signLine?.create_rank}
              </Text>
            </TextWrapper>
            <TextWrapper width={113}>
              <Text GRAY>위험등급</Text>
              <Text>{riskGradeChanger(scarInfo?.scar_riskGrade)}</Text>
            </TextWrapper>
            <TextWrapper width={112}>
              <Text GRAY>리스크코드</Text>
              <Text>{scarInfo?.scar_riskcode}</Text>
            </TextWrapper>
            <TextWrapper width={152}>
              <Text GRAY>발행 번호</Text>
              <Text>{scarInfo?.scar_number}</Text>
            </TextWrapper>
            <TextWrapper width={195} height={69}>
              <Text GRAY>라벨</Text>
              <Text>
                {scarInfo?.scar_label?.map((label, index) => (
                  <span key={index}>{label?.sLbl_name}</span>
                ))}
              </Text>
            </TextWrapper>
            <TextWrapper width={519}>
              <Text GRAY>위반위치</Text>
              <Text>{scarInfo?.scar_spot}</Text>
            </TextWrapper>
            <TextWrapper width={519}>
              <Text GRAY>위반사항</Text>
              <Text>{scarInfo?.scar_title}</Text>
            </TextWrapper>
          </InfoBox>
        </RowBox>
        <ApprovalLine data={scarInfo?.signLine} />
        <ColumnBox>
          <Text BOLD>조치사항정보</Text>
          <RowBox>
            <StyledSwiper
              imageList={scarInfo?.scarAction?.scarActionImg?.map(
                (img) => img.sai_img
              )}
            />
            <InfoBox height={122}>
              <TextWrapper width={304}>
                <Text GRAY>완료시간 (소요시간)</Text>
                <Text>
                  {scarInfo?.scarAction?.sa_completeDate !== ""
                    ? dateFormat(
                        new Date(scarInfo?.scarAction?.sa_completeDate),
                        "yyyy.MM.dd - hh:mm"
                      )
                    : "-"}
                </Text>
              </TextWrapper>
              <TextWrapper width={287}>
                <Text GRAY>조치자</Text>
                <Text>
                  {groupChanger(scarInfo?.signLine?.action_group)}{" "}
                  {scarInfo?.signLine?.action_name}{" "}
                  {scarInfo?.signLine?.action_rank}
                </Text>
              </TextWrapper>
              <TextWrapper width={591}>
                <Text GRAY>조치사항</Text>
                <Text>
                  {scarInfo?.scarAction?.sa_text !== ""
                    ? scarInfo?.scarAction?.sa_text
                    : "-"}
                </Text>
              </TextWrapper>
            </InfoBox>
          </RowBox>
        </ColumnBox>
      </Container>
    </StyledModal>
  );
};

export default DetailModal;
