import React from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Project/EndList/RowTable";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const EndPresenter = ({
  inputs,
  setInputs,
  handleReset,
  handleExcel,
  page,
  totalLength,
  onClickPage,
  prjList,
  endListRefetch,
}) => {
  return (
    <Container>
      <GuideLine label={["프로젝트 관리", "종료 요청 목록"]} />
      <ContentContainer>
        <SortingForm
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          handleExcel={handleExcel}
          SEARCH
          placeholder="프로젝트명, 종료 요청자명"
          PRJSORT
          RESET
          EXCEL
        />
        <StyledRowTable
          label={[
            { text: "메인이미지", width: 100 },
            { text: "종료요청일", width: 114 },
            { text: "프로젝트 명", width: 166 },
            { text: "프로젝트 기간", width: 230 },
            { text: "현장 주소", width: 328 },
            { text: "문서번호", width: 132 },
            { text: "구성원", width: 102 },
            { text: "SCAR 발행 건수", width: 153 },
            { text: "종료 요청자", width: 150 },
            { text: "종료 상태", width: 117 },
          ]}
        />
        <RowTable data={prjList} endListRefetch={endListRefetch} />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </ContentContainer>
    </Container>
  );
};

export default EndPresenter;
