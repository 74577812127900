import React, {
    useState,
    useCallback,
    useEffect,
    createContext,
    useContext,
} from "react";
import UserListPresenter from "./UserListPresenter";
import {SEE_USER_LIST} from "../../../../graphql/User/UserList/UserList/query";
import {useQuery} from "@apollo/client";
import {
    groupChanger,
    userSortChanger,
    userStopChanger,
} from "../../../../lib/UserUtils";
import {AuthContext} from "../../../../context/AuthProvider";

export const UserContext = createContext();

const UserListContainer = () => {
    const {userLogout} = useContext(AuthContext);
    const [checkIndex, setCheckIndex] = useState([]);
    const [cursor, setCursor] = useState(0);
    const [page, setPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [messageModal, setMessageModal] = useState(false);
    const [mailModal, setMailModal] = useState(false);
    const [userList, setUserList] = useState([]);
    const [inputs, setInputs] = useState({
        searchTerm: "",
        sort: "최신순",
        team: "전체",
        state: "전체",
    });

    React.useEffect(() => {
        // console.info('컨테이더 : ', inputs);
    }, [inputs]);

    const {
        data: userData,
        loading: userLoading,
        refetch: userRefetch,
    } = useQuery(SEE_USER_LIST, {
        variables: {
            searchTerm: inputs.searchTerm,
            sort: userSortChanger(inputs.sort),
            group: groupChanger(inputs.team),
            userStop: userStopChanger(inputs.state),
            take: 16,
            cursor: cursor,
        },
        pollInterval: 5000,
    });

    const handleReset = useCallback(() => {
        setInputs({
            searchTerm: "",
            sort: "최신 순",
            team: "전체",
            state: "전체",
        });
    }, [inputs]);

    const handleMail = useCallback(() => {
        setMailModal(!mailModal);
    }, [mailModal]);

    const handleMessage = useCallback(() => {
        setMessageModal(!messageModal);
    }, [messageModal]);

    const onClickPage = useCallback((page) => {
        setPage(page);
        if (page !== 1) {
            setCursor((page - 1) * 16);
        }
        if (page === 1) {
            setCursor(0);
        }
    }, []);

    const onClickCheckBox = useCallback(
        (id) => {
            if (id === -1) {
                if (checkIndex.length !== 0) setCheckIndex([]);
                else setCheckIndex(userList.map((user) => user.user_id));
                return;
            }
            if (checkIndex.includes(id)) {
                setCheckIndex(checkIndex.filter((userId) => id !== userId));
                return;
            }
            setCheckIndex([...checkIndex, id]);
        },
        [checkIndex]
    );

    useEffect(() => {
        if (userData?.seeUserList?.result) {
            setUserList(userData.seeUserList.userList);
            setTotalLength(userData.seeUserList.totalLength);
        }
        if (userData?.seeUserList && !userData?.seeUserList?.tokenExpired) {
            userLogout();
        }
    }, [userData]);

    return (
        <UserContext.Provider
            value={{
                inputs,
                setInputs,
                handleReset,
                handleMail,
                handleMessage,
                page,
                totalLength,
                onClickPage,
                messageModal,
                setMessageModal,
                mailModal,
                setMailModal,
                checkIndex,
                onClickCheckBox,
                userList,
                userRefetch,
            }}
        >
            <UserListPresenter/>
        </UserContext.Provider>
    );
};

export default UserListContainer;
