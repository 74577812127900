import React, { memo, useContext } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { CheckListContext } from "../../../../routes/Management/CheckList/CheckListContainer";
import { dateFormat } from "../../../../utils/common";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 76px;
  display: flex;
  align-items: flex-start;
  padding: 16px 0;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  padding-right: 20px;
  color: ${({ theme }) => theme.defaultFontColor};
  padding-top: 3px;
`;

const TextColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  height: 100%;
  justify-content: space-between;
`;

const RowTable = ({ checkItemList = [] }) => {
  const { radioIndex, onClickRadio } = useContext(CheckListContext);
  return (
    <Container>
      {checkItemList.map((data, index) => (
        <RowBox key={index}>
          <StyledRadioButton
            clicked={radioIndex === data.cli_id}
            onClick={() => onClickRadio(data.cli_id)}
          />
          <TextColumnBox>
            <Text width={340}>{data.cli_safetyCategory_kr}</Text>
            <Text width={340}>{data.cli_safetyCategory_eng}</Text>
          </TextColumnBox>
          <TextColumnBox>
            <Text width={100}>{data.cli_riskCode}</Text>
          </TextColumnBox>
          <TextColumnBox>
            <Text width={1000}>{data.cli_checkItem_kr}</Text>
            <Text width={1000}>{data.cli_checkItem_eng}</Text>
          </TextColumnBox>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
