import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/Notice/RowTable";
import NoticeAddModal from "../../../components/feature/Management/Notice/NoticeAddModal";
import NoticeEditModal from "../../../components/feature/Management/Notice/NoticeEditModal";
import { NoticeContext } from "./NoticeContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const NoticePresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    handlePlus,
    handleMinus,
    handleEdit,
    page,
    totalLength,
    onClickPage,
    addModal,
    setAddModal,
    editModal,
    setEditModal,
  } = useContext(NoticeContext);
  return (
    <>
      <Container>
        <GuideLine label={["운영관리", "공지사항"]} />
        <ContentContainer>
          <SortingForm
            inputs={inputs}
            setInputs={setInputs}
            handleReset={handleReset}
            handlePlus={handlePlus}
            handleMinus={handleMinus}
            handleEdit={handleEdit}
            placeholder="제목, 내용"
            SEARCH
            NOTICE
            RESET
            PLUS
            MINUS
            EDIT
          />
          <StyledRowTable
            RADIO
            label={[
              { text: "게시일", width: 142 },
              { text: "제목", width: 238 },
              { text: "내용", width: 946 },
              { text: "첨부파일", width: 244 },
            ]}
          />
          <RowTable />
          <PageNation
            page={page}
            onClickPage={onClickPage}
            totalLength={totalLength}
          />
        </ContentContainer>
      </Container>
      {addModal && <NoticeAddModal handleClose={() => setAddModal(false)} />}
      {editModal && <NoticeEditModal handleClose={() => setEditModal(false)} />}
    </>
  );
};

export default NoticePresenter;
