import React, { useState, useCallback, useContext, useEffect } from "react";
import styled from "styled-components";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";
import { RiskcodeContext } from "../../../../routes/Management/RiskCode/RiskCodeContainer";
import { EDIT_RISK_CODE } from "../../../../graphql/Management/RiskCode/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const InputBox = styled.textarea`
  width: 560px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CodeEditModal = () => {
  const { setEditModal, riskcodeList, radioIndex, riskcodeRefetch } =
    useContext(RiskcodeContext);
  const { userLogout } = useContext(AuthContext);

  const rcInfo = riskcodeList[radioIndex];

  const [editRiskCode] = useMutation(EDIT_RISK_CODE);

  const [inputs, setInputs] = useState({
    codeName: "",
    title: "",
    contents: "",
  });

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const handleSave = useCallback(async () => {
    if (
      inputs.codeName.trim() === "" ||
      inputs.title.trim() === "" ||
      inputs.contents.trim() === ""
    ) {
      toast.error("빈칸을 모두 채워주세요.");
      return;
    }
    try {
      const { data } = await editRiskCode({
        variables: {
          rc_id: rcInfo?.rc_id,
          rc_name: inputs.codeName,
          rc_title: inputs.title,
          rc_text: inputs.contents,
        },
      });
      if (data?.editRiskCode?.result) {
        toast.success("리스크코드를 수정하였습니다.");
        setEditModal(false);
        await riskcodeRefetch();
      }
      if (data?.editRiskCode && !data?.editRiskCode?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  useEffect(() => {
    setInputs({
      codeName: rcInfo?.rc_name,
      title: rcInfo?.rc_title,
      contents: rcInfo?.rc_text,
    });
  }, [rcInfo]);

  return (
    <StyledModal
      title="리스크 코드 수정"
      width={640}
      height={580}
      handleClose={() => setEditModal(false)}
      handleSave={handleSave}
      btnLabel="등록"
    >
      <RowBox>
        <StyledInput
          name="codeName"
          label="코드명"
          width={220}
          placeholder="코드명을 입력하세요"
          value={inputs.codeName}
          onChange={onChangeInputs}
        />
        <StyledInput
          name="title"
          label="제목"
          width={322}
          placeholder="제목을 입력하세요"
          value={inputs.title}
          onChange={onChangeInputs}
        />
      </RowBox>
      <Text marginTop={26}>코드 내용</Text>
      <InputBox
        name="contents"
        height={230}
        placeholder="코드 내용을 입력하세요"
        value={inputs.contents}
        onChange={onChangeInputs}
      />
    </StyledModal>
  );
};

export default CodeEditModal;
