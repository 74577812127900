import React, { useState, useEffect } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import styled from "styled-components";
import calendarIcon from "../../assets/icon/calendarIcon.svg";
// import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "../../styles/DateRangePicker.css";

const Container = styled.div`
  max-height: 77px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

const Lable = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-bottom: 12px;
`;

const CalendarIcon = styled.img`
  position: absolute;
  right: 16px;
  bottom: 16px;
`;

const StyledSelectDate = ({ label = "기간", date, onChange = () => {} }) => {
  return (
    <Container>
      <Lable>{label}</Lable>
      <DateRangePicker
        locale="ko"
        onChange={onChange}
        value={date}
        clearIcon={null}
        calendarIcon={null}
        rangeDivider="~ "
        format="yyyy. MM. dd"
        calendarType="US"
        formatDay={(locale, date) =>
          date.toLocaleString("en", { day: "numeric" })
        }
      />
      <CalendarIcon src={calendarIcon} />
    </Container>
  );
};

export default StyledSelectDate;
