import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";
import { CREATE_RISK_CODE } from "../../../../graphql/Management/RiskCode/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { RiskcodeContext } from "../../../../routes/Management/RiskCode/RiskCodeContainer";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const InputBox = styled.textarea`
  width: 560px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CodeAddModal = () => {
  const { riskcodeRefetch, handlePlus } = useContext(RiskcodeContext);
  const { userLogout } = useContext(AuthContext);
  const [inputs, setInputs] = useState({
    codeName: "",
    title: "",
    contents: "",
  });

  const [createRiskCode] = useMutation(CREATE_RISK_CODE);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const handleSave = useCallback(async () => {
    if (inputs.codeName.trim() === "") {
      toast.error("코드명을 입력해주세요.");
      return;
    }
    if (inputs.title.trim() === "") {
      toast.error("제목을 입력해주세요.");
      return;
    }
    if (inputs.contents.trim() === "") {
      toast.error("코드 내용을 입력해주세요.");
      return;
    }
    try {
      const { data } = await createRiskCode({
        variables: {
          rc_name: inputs.codeName,
          rc_title: inputs.title,
          rc_text: inputs.contents,
        },
      });
      if (data?.createRiskCode?.result) {
        toast.success("리스크 코드를 등록하였습니다.");
        await riskcodeRefetch();
        handlePlus();
      }
      if (data?.createRiskCode && !data?.createRiskCode?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  return (
    <StyledModal
      title="리스크 코드 등록"
      width={640}
      height={580}
      handleClose={handlePlus}
      handleSave={handleSave}
      btnLabel="등록"
    >
      <RowBox>
        <StyledInput
          name="codeName"
          label="코드명"
          width={220}
          placeholder="코드명을 입력하세요"
          maxLength={10}
          value={inputs.codeName}
          onChange={onChangeInputs}
        />
        <StyledInput
          name="title"
          label="제목"
          width={322}
          maxLength={100}
          placeholder="제목을 입력하세요"
          value={inputs.title}
          onChange={onChangeInputs}
        />
      </RowBox>
      <Text marginTop={26}>코드 내용</Text>
      <InputBox
        name="contents"
        height={230}
        maxLength={800}
        placeholder="코드 내용을 입력하세요"
        value={inputs.contents}
        onChange={onChangeInputs}
      />
    </StyledModal>
  );
};

export default CodeAddModal;
