import React from "react";
import styled from "styled-components";
import mailIcon from "../../assets/icon/mailIcon.svg";
import messageIcon from "../../assets/icon/messageIcon.svg";
import minusIcon from "../../assets/icon/minusIcon.svg";
import minusIconGray from "../../assets/icon/minusIconGray.svg";
import plusIcon from "../../assets/icon/plusIcon.svg";
import editIcon from "../../assets/icon/editIcon.svg";
import excelIcon from "../../assets/icon/excelIcon.svg";

const IconButton = styled.img`
  width: ${({ width }) => (width ? width : 45)}px;
  height: ${({ height }) => (height ? height : 45)}px;
  cursor: pointer;
`;

const StyledIconButton = ({
  MAIL,
  PLUS,
  MINUS,
  EDIT,
  MESSAGE,
  EXCEL,
  GRAY,
  onClick = () => {},
}) => {
  return (
    <>
      {MAIL && <IconButton src={mailIcon} onClick={onClick} />}
      {PLUS && <IconButton src={plusIcon} onClick={onClick} />}
      {MINUS && (
        <IconButton src={GRAY ? minusIconGray : minusIcon} onClick={onClick} />
      )}
      {EDIT && <IconButton src={editIcon} onClick={onClick} />}
      {EXCEL && <IconButton src={excelIcon} onClick={onClick} />}
      {MESSAGE && <IconButton src={messageIcon} onClick={onClick} />}
    </>
  );
};

export default StyledIconButton;
