import React, { memo, useContext, useState } from "react";
import styled from "styled-components";
import DetailModal from "./DetailModal";
import { dateFormat } from "../../../../../utils/common";
import { FiImage } from "react-icons/fi";
import {
  riskGradeChanger,
  scarStatusChanger,
} from "../../../../../lib/ProjectUtils";
import { PrjDetailContext } from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import { groupChanger } from "../../../../../lib/UserUtils";
import { theme } from "../../../../../styles/theme";

const Container = styled.div`
  width: 100%;
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.scrollBackgroundColor};
  }
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.grayBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  margin: ${({ margin }) => margin};
  /* display: flex;
  align-items: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
  padding-right: 20px;
  white-space: nowrap;
  color: ${({ theme, GRAY }) =>
    GRAY ? `${theme.tableLinkFontColor}` : `${theme.defaultFontColor}`};
  
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  color: ${({ theme }) => theme.textFieldBorder};
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
  border: 1px solid ${({ theme }) => theme.grayBackgroundColor};
`;

const EmptyBox = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.textFieldBorder};
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${({ bgColor, theme }) => {
    switch (bgColor) {
      case "impossible": //조치불가
        return theme.projectDetailStateOAColorl;

      case "reject":
        return theme.projectDetailStateOAColorl;

      case "complete": //완료
        return theme.projectDetailStateCompleteColor;

      default:
        return theme.projectDetailStateIngColor;
    }
  }};
`;

const RowTable = () => {
  const { scarList } = useContext(PrjDetailContext);
  const [scarInfo, setScarInfo] = useState(null);
  
  const [modal, setModal] = useState(false);

  return (
    <>
      <Container>
        {scarList.length !== 0 &&
          scarList.map((data, index) => (
            <RowBox
              key={index}
              onClick={() => {
                setModal(!modal);
                setScarInfo(data);
              }}
            >
              <DotWrapper>
                {data?.img?.length > 0 ? (
                  <Image src={data?.img[0]?.si_img} />
                ) : (
                  <EmptyBox>
                    <FiImage />
                  </EmptyBox>
                )}
                {/* <Image src={data?.img?.length > 0 ? data?.img[0]?.si_img : ""}>
                  {data?.img?.length === 0 && }
                </Image> */}
                <Text width={50} margin="0 0  0 10px" GRAY>
                  × {data?.img?.length}
                </Text>
              </DotWrapper>
              <Text width={155}>
                {dateFormat(Number(data?.scar_createdAt), "yyyy.MM.dd hh:mm")}
              </Text>
              <Text width={155}>
                {data?.scarAction?.sa_completeDate !== ""
                  ? dateFormat(
                      data?.scarAction?.sa_completeDate,
                      "yyyy.MM.dd hh:mm"
                    )
                  : "-"}
              </Text>

              <DotWrapper>
                <Dot bgColor={data.scar_signStatus} />
                <Text width={105}>
                  {scarStatusChanger(data.scar_signStatus)}
                </Text>
              </DotWrapper>
              <Text width={185}>{data?.scar_title}</Text>
              <Text width={190}>{data?.scar_spot}</Text>
              <Text width={139}>
                {data?.scar_label?.length === 0
                  ? "-"
                  : data?.scar_label?.map((label, index) => (
                      <span key={index}>{label?.sLbl_name}</span>
                    ))}
              </Text>
              <Text width={85}>{riskGradeChanger(data?.scar_riskGrade)}</Text>
              <Text width={189}>
                {data?.scar_riskcode === "" ? "-" : data?.scar_riskcode}
              </Text>
              <Text width={145}>
                {data?.now_signLine_name !== ""
                  ? groupChanger(data?.now_signLine_group) +
                    " " +
                    data?.now_signLine_name
                  : "-"}
              </Text>
              <Text width={145}>
                {groupChanger(data?.signLine?.create_group)}{" "}
                {data?.signLine?.create_name}
              </Text>
            </RowBox>
          ))}
      </Container>
      {modal && (
        <DetailModal scarInfo={scarInfo} handleClose={() => setModal(false)} />
      )}
    </>
  );
};

export default memo(RowTable);
