import React, { useContext } from "react";
import styled from "styled-components";
import DetailTab from "../../../../components/feature/User/UserList/UserDetail/DetailTab";
import RowTable from "../../../../components/feature/User/UserList/UserDetail/RowTable";
import Summary from "../../../../components/feature/User/UserList/UserDetail/Summary";
import GuideLine from "../../../../components/layout/GuideLine";
import PageNation from "../../../../components/layout/PageNation";
import SortingForm from "../../../../components/layout/SortingForm";
import StyledRowTable from "../../../../components/share/StyledRowTable";
import { UserDetailContext } from "./UserDetailContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 100vh;
  min-height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const DetailContainer = styled.div`
  width: 1646px;
  height: 701px;
  margin: 20px 16px 0 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const PaddingBox = styled.div`
  padding: 20px 20px 0 20px;
`;

const UserDetailPresenter = () => {
  const { inputs, setInputs, handleReset, onClickPage, page, totalLength } =
    useContext(UserDetailContext);
  return (
    <>
      <Container>
        <GuideLine label={["유저관리", "유저 목록", "유저 상세 정보"]} />
        <Summary />
        <DetailTab />
        <DetailContainer>
          <PaddingBox>
            <SortingForm
              SEARCH
              PRJSORT
              RESET
              inputs={inputs}
              setInputs={setInputs}
              handleReset={handleReset}
              width={320}
              placeholder="프로젝트명, 현장주소, 생성자 이름"
            />
          </PaddingBox>
          <StyledRowTable
            label={[
              { text: "메인이미지", width: 98 },
              { text: "프로젝트 명", width: 147 },
              { text: "상태", width: 126 },
              { text: "프로젝트 기간", width: 231 },
              { text: "현장 주소", width: 320 },
              { text: "문서번호", width: 141 },
              { text: "구성원", width: 107 },
              { text: "SCAR 발행 건수", width: 163 },
              { text: "프로젝트 생성자", width: 180 },
            ]}
          />
          <RowTable />
          <PaddingBox>
            <PageNation
              onClickPage={onClickPage}
              page={page}
              totalLength={totalLength}
            />
          </PaddingBox>
        </DetailContainer>
      </Container>
    </>
  );
};

export default UserDetailPresenter;
