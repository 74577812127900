import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import StyledModal from "../../../../share/StyledModal";
import { UserDetailContext } from "../../../../../routes/User/UserList/UserDetail/UserDetailContainer";
import { EDIT_USER_CHANGE } from "../../../../../graphql/User/UserList/UserDetail/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import StyledRadioButton from "../../../../share/StyledRadioButton";

const RowBox = styled.div`
  display: flex;
  justify-content: ${({ END }) => END && "flex-end"};
  margin-top: ${({ END }) => END && 30}px;
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize && fontSize}px;
  color: ${({ GRAY, theme }) => GRAY && theme.projectDetailStateCompleteColor};
  margin-bottom: 10px;
  margin-right: ${({ check }) => check && 18}px;
`;

const CheckWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const EditModal = ({handleUser, id}) => {
    const { userDetailRefetch } = useContext(UserDetailContext);
    const [permission, setPermission] = useState(id?.user_group);

    const [editUserChange] = useMutation(EDIT_USER_CHANGE);

    const handleSave = useCallback(async () => {
        try {
          const { data } = await editUserChange({            
            variables: {
              user_id: id?.user_id,
              user_group: permission,
            },
          });
          console.log(data);
          if (data?.editUserChange?.result) {
            toast.success("소속을 변경하였습니다.");
            handleUser();
            await userDetailRefetch();
          }
        } catch (e) {
          toast.error(e.message);
        }
      }, [permission]);

    return (
        <StyledModal
          title="소속 변경"
          width={450}
          height={250}
          handleClose={handleUser}
          handleSave={handleSave}
        >
          <RowBox END>
        <CheckWrapper onClick={() => setPermission("head")}>
          <StyledRadioButton marginRight={10} clicked={permission === "head"} />
          <Text check>PM본사</Text>
        </CheckWrapper>
        <CheckWrapper onClick={() => setPermission("local")}>
          <StyledRadioButton
            marginRight={10}
            clicked={permission === "local"}
          />
          <Text check>PM현장</Text>
        </CheckWrapper>
        <CheckWrapper onClick={() => setPermission("builder")}>
          <StyledRadioButton
            marginRight={10}
            clicked={permission === "builder"}
          />
          <Text check>시공사</Text>
        </CheckWrapper>
        <CheckWrapper onClick={() => setPermission("owner")}>
          <StyledRadioButton
            marginRight={10}
            clicked={permission === "owner"}
          />
          <Text>발주처</Text>
        </CheckWrapper>
      </RowBox>
        </StyledModal>
      );
}

export default EditModal;