import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import StyledModal from "../../../../share/StyledModal";
import StyledRadioButton from "../../../../share/StyledRadioButton";
import { groupChanger } from "../../../../../lib/UserUtils";
import { toast } from "react-toastify";
import { EDIT_PRJ_PERMISSION_CHANGE } from "../../../../../graphql/Project/Project/ProjectDetail/mutation";
import { useMutation } from "@apollo/client";
import { PrjDetailContext } from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import { AuthContext } from "../../../../../context/AuthProvider";
import emptyProfile from "../../../../../assets/icon/emptyProfile.svg";

const ProfileImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 10px;
`;

const RowBox = styled.div`
  display: flex;
  justify-content: ${({ END }) => END && "flex-end"};
  margin-top: ${({ END }) => END && 30}px;
`;

const ColumnBox = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: ${({ fontSize }) => fontSize && fontSize}px;
  color: ${({ GRAY, theme }) => GRAY && theme.projectDetailStateCompleteColor};
  margin-bottom: 10px;
  margin-right: ${({ check }) => check && 18}px;
`;

const CheckWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const MemberModal = ({ prjId, editUser, handleClose }) => {
  const { prjDetailRefetch } = useContext(PrjDetailContext);
  const { userLogout } = useContext(AuthContext);

  const [permission, setPermission] = useState(null);

  const [editPrjPermissionChange] = useMutation(EDIT_PRJ_PERMISSION_CHANGE);

  useEffect(() => {
    setPermission(
      editUser?.user_changedPermission !== ""
        ? editUser?.user_changedPermission
        : editUser?.user_group,
    );
  }, [editUser]);

  const changePermission = useCallback(async () => {
    try {
      const { data } = await editPrjPermissionChange({
        variables: {
          prj_id: prjId,
          user_id: editUser?.user_id,
          permission: permission,
        },
      });
      if (data?.editPrjPermissionChange?.result) {
        toast.success("권한을 변경하였습니다.");
        handleClose();
        await prjDetailRefetch();
      }
      if (
        data?.editPrjPermissionChange &&
        !data?.editPrjPermissionChange?.tokenExpired
      ) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [permission]);

  return (
    <StyledModal
      title="프로젝트 권한 변경"
      width={500}
      height={348}
      btnLabel="저장"
      handleClose={handleClose}
      handleSave={changePermission}
    >
      <RowBox>
        <ProfileImage src={editUser?.user_profileImg || emptyProfile} />
        <ColumnBox width={99}>
          <Text GRAY>{groupChanger(editUser?.user_group)}</Text>
          <Text fontSize={18}>{editUser?.user_name}</Text>
        </ColumnBox>

        <ColumnBox width={89}>
          <Text GRAY>소속회사명</Text>
          <Text GRAY>연락처</Text>
          <Text GRAY>메일주소</Text>
        </ColumnBox>
        <ColumnBox width={133}>
          <Text>{editUser?.user_groupCompany}</Text>
          <Text>{editUser?.user_cellphone}</Text>
          <Text>{editUser?.user_email}</Text>
        </ColumnBox>
      </RowBox>
      <RowBox END>
        <CheckWrapper onClick={() => setPermission("head")}>
          <StyledRadioButton marginRight={10} clicked={permission === "head"} />
          <Text check>PM본사</Text>
        </CheckWrapper>
        <CheckWrapper onClick={() => setPermission("local")}>
          <StyledRadioButton
            marginRight={10}
            clicked={permission === "local"}
          />
          <Text check>PM현장</Text>
        </CheckWrapper>
        <CheckWrapper onClick={() => setPermission("builder")}>
          <StyledRadioButton
            marginRight={10}
            clicked={permission === "builder"}
          />
          <Text check>시공사</Text>
        </CheckWrapper>
        <CheckWrapper onClick={() => setPermission("owner")}>
          <StyledRadioButton
            marginRight={10}
            clicked={permission === "owner"}
          />
          <Text>발주처</Text>
        </CheckWrapper>
      </RowBox>
    </StyledModal>
  );
};

export default MemberModal;
