import React, { memo, useState, useContext } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../share/StyledRadioButton";
import QnaAnswerModal from "./QnaAnswerModal";
import { QnaContext } from "../../../../routes/Management/Qna/QnaContainer";
import { autoHyphen, dateFormat } from "../../../../utils/common";
import { groupChanger } from "../../../../lib/UserUtils";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  color: ${({ theme }) => theme.defaultFontColor};
  padding-top: 3px;
`;

const EndButton = styled.div`
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.defaultFontColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;
`;

const RowTable = () => {
  const { radioIndex, onClickRadio, qnaList } = useContext(QnaContext);
  const [answerModal, setAnswerModal] = useState(false);
  const [answerIndex, setAnswerIndex] = useState(null);

  return (
    <>
      <Container>
        {qnaList.map((data, index) => (
          <RowBox key={index}>
            <StyledRadioButton
              clicked={radioIndex === index}
              onClick={() => onClickRadio(index)}
            />
            <Text width={118}>
              {dateFormat(new Date(Number(data.one_createdAt)), "yyyy.MM.dd")}
            </Text>
            <Text width={152}>{data.user_name}</Text>
            <Text width={150}>{data.user_groupCompany}</Text>
            <Text width={100}>{groupChanger(data.user_group)}</Text>
            <Text width={152}>{autoHyphen(data.user_cellphone)}</Text>
            <Text width={180}>{data.user_email}</Text>
            {console.log(">>", data)}
            <Text width={604}>{data.one_question}</Text>
            <Text width={112}>
              {data.one_answer ? (
                "완료"
              ) : (
                <EndButton
                  onClick={() => {
                    setAnswerModal(true);
                    setAnswerIndex(index);
                  }}
                >
                  답변하기
                </EndButton>
              )}
            </Text>
          </RowBox>
        ))}
      </Container>
      {answerModal && (
        <QnaAnswerModal
          handleClose={() => setAnswerModal(false)}
          oneData={qnaList[answerIndex]}
        />
      )}
    </>
  );
};

export default memo(RowTable);
