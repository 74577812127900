import React, { useState, useEffect } from "react";
import AppRoutes from "./Router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "./context/themeProvider";
import GlobalStyles from "./styles/GlobalStyles";
import { useLocation, useNavigate } from "react-router-dom";

function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const previousLoading = () => {
    try {
      const token = localStorage.getItem("hanmi_admin_token");
      if (token) {
        setIsLoggedIn(true);
        navigate(pathname);
      } else {
        setIsLoggedIn(false);
      }
    } catch (e) {
      toast.error(e.message);
    }
  };

  useEffect(() => {
    previousLoading();
  }, []);

  // console.log = function no_console() {};
  // console.warn = function no_console() {};
  // console.info = function no_console() {};

  return (
    <ThemeProvider>
      <GlobalStyles />
      <ToastContainer position={toast.POSITION.TOP_RIGHT} />
      <AppRoutes isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </ThemeProvider>
  );
}

export default App;
