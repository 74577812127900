import React, { useState } from "react";
import styled from "styled-components";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { FaHardHat } from "react-icons/fa";

const Container = styled.div`
  max-width: 280px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid skyblue; */
`;

const BigImageHidden = styled.div`
  overflow: hidden;
  position: relative;
`;

const BigImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ index }) => "translateX(-" + index * 280 + "px)"};
`;
const BigImage = styled.img`
  width: 280px;
  height: 280px;
  border-radius: 8px;
  flex-shrink: 0;
  /* background-image: ${({ src }) => `url(${src})`};
  background-position: 50%;
  background-size: cover; */
  object-fit: cover;
`;

const Button = styled.div`
  width: 28px;
  height: 28px;
  display: ${({ view }) => (view ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 126px;
  left: ${({ RIGHT }) => !RIGHT && "10px"};
  right: ${({ RIGHT }) => RIGHT && "10px"};
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.6);
  background-color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  z-index: 100;
`;

const SmallImageHidden = styled.div`
  width: 100%;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SmallImageWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: ${({ index }) => "translateX(-" + index * 58 + "px)"};
`;

const SmallImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  margin: 10px 10px 10px 0;
  /* background-image: ${({ src }) => `url(${src})`};
  background-position: 50%;
  background-size: cover; */
  object-fit: cover;
  &:last-child {
    margin-right: 0;
  }
`;

const EmptyImage = styled.div`
  width: 280px;
  height: 280px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: ${({ theme }) => theme.defaultBackgroundColor};
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const StyledSwiper = ({ imageList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleArrow = (arrow) => {
    switch (arrow) {
      case "left":
        if (currentIndex !== 0) {
          setCurrentIndex(currentIndex - 1);
        }
        return;
      case "right":
        if (currentIndex < imageList.length - 1) {
          setCurrentIndex(currentIndex + 1);
        }
        return;
    }
  };

  return (
    <Container>
      {imageList.length === 0 ? (
        <EmptyImage>
          <FaHardHat />
        </EmptyImage>
      ) : (
        <>
          <BigImageHidden>
            <Button
              onClick={() => handleArrow("left")}
              view={currentIndex !== 0}
            >
              <FaChevronLeft />
            </Button>
            <BigImageWrapper index={currentIndex}>
              {imageList.map((image, index) => (
                <BigImage key={index} src={image} />
              ))}
            </BigImageWrapper>
            <Button
              RIGHT
              onClick={() => handleArrow("right")}
              view={currentIndex !== imageList.length - 1}
            >
              <FaChevronRight />
            </Button>
          </BigImageHidden>
          <SmallImageHidden>
            <SmallImageWrapper
              index={
                currentIndex < imageList.length - 5
                  ? currentIndex
                  : imageList.length - 5
              }
            >
              {imageList.map((image, index) => (
                <SmallImage key={index} src={image} />
              ))}
            </SmallImageWrapper>
          </SmallImageHidden>
        </>
      )}
    </Container>
  );
};

export default StyledSwiper;
