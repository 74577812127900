import React from "react";
import styled from "styled-components";

const Button = styled.button`
  width: ${({ width }) => width}px;
  height: 46px;
  font-size: 16px;
  font-weight: 500;
  margin: ${({ margin }) => margin};
  margin-top: ${({ RESET }) => RESET && 28}px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  color: ${({ BLACK, theme }) =>
    BLACK ? `${theme.whiteColor}` : `${theme.styledBorderColor}`};
  background-color: ${({ BLACK, theme }) =>
    BLACK ? `${theme.mainColor}` : `${theme.whiteColor}`};
  cursor: pointer;
  white-space: nowrap;
  &:hover {
  }
`;

const StyledButton = ({
  BLACK,
  RESET,
  width = 74,
  margin,
  label,
  onClick = () => {},
}) => {
  return (
    <Button
      BLACK={BLACK}
      RESET={RESET}
      width={width}
      margin={margin}
      onClick={onClick}
    >
      {label}
    </Button>
  );
};

export default StyledButton;
