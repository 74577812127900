import React, {useContext} from "react";
import styled from "styled-components";
import CheckListDetail from "../../../../components/feature/Project/Project/ProjectDetail/CheckListDetail";
import CheckListEditModal from "../../../../components/feature/Project/Project/ProjectDetail/CheckListEditModal";
import CheckListRowTable from "../../../../components/feature/Project/Project/ProjectDetail/CheckListRowTable";
import CheckSetRowTable from "../../../../components/feature/Project/Project/ProjectDetail/CheckSetRowTable";
import DetailStat from "../../../../components/feature/Project/Project/ProjectDetail/DetailStat";
import DetailTab from "../../../../components/feature/Project/Project/ProjectDetail/DetailTab";
import RowTable from "../../../../components/feature/Project/Project/ProjectDetail/RowTable";
import Summary from "../../../../components/feature/Project/Project/ProjectDetail/Summary";
import GuideLine from "../../../../components/layout/GuideLine";
import PageNation from "../../../../components/layout/PageNation";
import SortingForm from "../../../../components/layout/SortingForm";
import StyledRowTable from "../../../../components/share/StyledRowTable";
import {PrjDetailContext} from "./ProjectDetailContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.grayBackgroundColor};
`;

const DetailContainer = styled.div`
  width: 1646px;
  height: 701px;
  margin: 20px 16px 0 16px;
  background-color: ${({theme}) => theme.defaultBackgroundColor};
`;

const PaddingBox = styled.div`
  padding: 20px 20px 0 20px;
`;

const ProjectDetailPresenter = () => {
    const {
        tab,
        setTab,
        inputs,
        setInputs,
        handleReset,
        onChangeDate,
        labelList,
        page,
        totalLength,
        onClickPage,
        selectCheckList,
        checkSetModal,
        handleEditModal,
        checkListPage,
        checkListData,
        checkListDetailData,
        safetyOptions,
        checkListSettingPage,
        checkListSettingData
    } = useContext(PrjDetailContext);

    return (
        <Container>
            <GuideLine label={["프로젝트 관리", "프로젝트 상세보기"]}/>
            <Summary/>
            <DetailTab tab={tab} setTab={setTab}/>
            <DetailContainer>
                {tab === "발행목록" && (
                    <>
                        <PaddingBox>
                            <SortingForm
                                inputs={inputs}
                                setInputs={setInputs}
                                handleReset={handleReset}
                                onChangeDate={onChangeDate}
                                labelList={labelList}
                                placeholder="위반사항, 발행자로 검색"
                                SEARCH
                                DETAILDATE
                                SCARSORT
                                DETAILSTATE
                                DETAILLABEL
                                GRADE
                                RESET
                                DENGER
                            />
                        </PaddingBox>
                        <StyledRowTable
                            label={[
                                {text: "이미지", width: 96},
                                {text: "발행일", width: 155},
                                {text: "조치완료일", width: 155},
                                {text: "상태", width: 120},
                                {text: "위반사항", width: 185},
                                {text: "위반위치", width: 190},
                                {text: "라벨", width: 139},
                                {text: "위험등급", width: 85},
                                {text: "리스크코드", width: 189},
                                {text: "현재 결재라인", width: 145},
                                {text: "발행자", width: 145},
                            ]}
                        />
                        <RowTable/>
                        <PaddingBox>
                            <PageNation
                                page={page}
                                totalLength={totalLength}
                                onClickPage={onClickPage}
                            />
                        </PaddingBox>
                    </>
                )}
                {tab === "통계" && (
                    <>
                        <DetailStat/>
                    </>
                )}
                {tab === "체크리스트" &&
                ((selectCheckList && checkListDetailData) ? (
                    <CheckListDetail />
                ) : (
                    <>
                        <PaddingBox>
                            <SortingForm
                                inputs={inputs}
                                setInputs={setInputs}
                                handleReset={handleReset}
                                onChangeDate={onChangeDate}
                                labelList={labelList}
                                placeholder="위반사항, 발행자로 검색"
                                CHECK_LIST_SORT
                                CHECK_LIST_RATIO
                                CHECK_LIST_STATUS
                                RESET
                                DENGER
                            />
                        </PaddingBox>
                        <StyledRowTable
                            label={[
                                {text: "평가일", width: 172},
                                {text: "주차", width: 166},
                                {text: "상태", width: 148},
                                {text: "준수율", width: 102},
                                {text: "총점", width: 88},
                                {text: "평가점수", width: 115},
                                {text: "반복위반", width: 125},
                                {text: "평가 의견", width: 410},
                                {text: "평가사진", width: 124},
                                {text: "평가자", width: 140},
                            ]}
                        />
                        <CheckListRowTable
                            checkList={checkListData?.weeklyCheckList}
                        />
                        <PaddingBox>
                            <PageNation
                                page={checkListPage}
                                totalLength={checkListData?.totalLength}
                                onClickPage={onClickPage}
                            />
                        </PaddingBox>
                    </>
                ))}
                {tab === "체크리스트 설정" && (
                    <>
                        <PaddingBox>
                            <SortingForm
                                inputs={inputs}
                                setInputs={setInputs}
                                onChangeDate={onChangeDate}
                                handleEdit={handleEditModal}
                                safetyOptions={safetyOptions}
                                CHECKLIST
                                EDIT
                            />
                        </PaddingBox>
                        <StyledRowTable
                            RADIO
                            label={[
                                {text: "안전범주명", width: 263},
                                {text: "설정 개수", width: 166},
                                {text: "조정 개수", width: 999},
                                {text: "마지막 수정일", width: 150},
                            ]}
                        />
                        <CheckSetRowTable
                            checkListSettingList={checkListSettingData?.prjSafetyCategorySetting}
                        />
                        <PaddingBox>
                            <PageNation
                                page={checkListSettingPage}
                                totalLength={checkListSettingData?.totalLength}
                                onClickPage={onClickPage}
                            />
                        </PaddingBox>
                        {checkSetModal && <CheckListEditModal/>}
                    </>
                )}
            </DetailContainer>
        </Container>
    );
};

export default ProjectDetailPresenter;
