import React, { useEffect, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import SortingForm from "../../../../layout/SortingForm";
import CircleChart from "./CircleChart";
import { useQuery } from "@apollo/client";
import {
  SEE_SCAR_TOTAL_COUNT,
  SEE_CHECK_LIST_STATISTICS,
} from "../../../../../graphql/Project/Project/ProjectDetail/query";
import StyledSelect from "../../../../share/StyledSelect";
import StickChart from "./StickChart";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  padding: 30px 20px;
`;

const Table = styled.div`
  width: 400px;
  height: 42px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme, GRAY }) =>
    GRAY && `${theme.projectDetailTableGrayColor}`};
  border-bottom: 1px solid ${({ theme }) => theme.projectDetailTableBolder};
  ${({ RESULT }) =>
    RESULT &&
    css`
      height: 39px;
      align-items: flex-end;
      border-top: 1px solid ${({ theme }) => theme.projectDetailTableBolder};
      border-bottom: 0;
    `};
`;

const TableSet = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 24px;

  ${({ width }) =>
    width &&
    css`
      width: ${width}%;
    `};
`;

const ScrollWrapper = styled.div`
  width: 400px;
  max-height: 419px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.mainColor};
  }
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  margin: ${({ margin }) => margin};
  text-align: ${({ END }) => END && "right"};
  color: ${({ theme, BLUE }) => BLUE && `${theme.mainColor}`};
  font-weight: ${({ BOLD }) => BOLD && 500};
  padding-top: 3px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ paddingRight }) =>
    paddingRight &&
    css`
      padding-right: ${paddingRight}px;
    `};
`;

const DetailStat = () => {
  const [labelCount, setLabelCount] = useState([]);
  const [scarCount, setScarCount] = useState(null);
  const [type, setType] = useState("SCAR 통계");
  const [status, setStatus] = useState(null);
  const [checkListStatus, setCheckListStatus] = useState(null);
  const [labelTotal, setLabelTotal] = useState(null);

  const [inputs, setInputs] = useState({
    date: [
      new Date(new Date().getFullYear() - 5, 0, 1),
      new Date(new Date().getFullYear(), 11, 31),
    ],
  });

  const onChangeDate = useCallback(
    (value) => {
      setInputs({ date: value });
    },
    [inputs]
  );

  const {
    data: countData,
    loading: countLoading,
    refetch: countRefetch,
  } = useQuery(SEE_SCAR_TOTAL_COUNT, {
    variables: {
      prjId: Number(localStorage.getItem("prjId")),
      searchStart: inputs.date[0],
      searchEnd: inputs.date[1],
    },
  });
  const {
    data: checkListData,
    loading: checkListLoading,
    refetch,
  } = useQuery(SEE_CHECK_LIST_STATISTICS, {
    variables: {
      prjId: Number(localStorage.getItem("prjId")),
      searchStart: inputs.date[0],
      searchEnd: inputs.date[1],
    },
  });

  React.useEffect(() => {
    console.info("checkListData", checkListData);
  }, [checkListData]);

  useEffect(() => {
    if (countData?.seeScarTotalCount?.result) {
      setLabelCount(countData?.seeScarTotalCount?.byLabelScarCount);
      setScarCount(countData?.seeScarTotalCount?.byScarCount);
      setStatus([
        {
          name: "최종완료",
          value: countData?.seeScarTotalCount?.byStatus?.completeCount,
          ratio: countData?.seeScarTotalCount?.byStatus?.completeRatio,
        },
        {
          name: "진행중",
          value: countData?.seeScarTotalCount?.byStatus?.inProgressCount,
          ratio: countData?.seeScarTotalCount?.byStatus?.inProgressRatio,
        },
        {
          name: "지연",
          value: countData?.seeScarTotalCount?.byStatus?.delayCount,
          ratio: countData?.seeScarTotalCount?.byStatus?.delayRatio,
        },
      ]);
    }
  }, [countData]);

  useEffect(() => {
    if (!checkListLoading && checkListData) {
      setCheckListStatus([
        {
          name: "준수",
          value:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.compliantCount,
          ratio:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.compliant,
        },
        {
          name: "대부분준수",
          value:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.mainlyCompliantCount,
          ratio:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.mainlyCompliant,
        },
        {
          name: "일부준수",
          value:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.partiallyCompliantCount,
          ratio:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.partiallyCompliant,
        },
        {
          name: "해당없음",
          value:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.notapplicableCount,
          ratio:
            checkListData?.seeCheckListStatistics?.checkListSocreRate
              ?.notapplicable,
        },
      ]);
    }
  }, [checkListLoading, checkListData]);

  useEffect(() => {
    let createTotal = 0;
    let completeTotal = 0;
    let imcompleteCount = 0;
    labelCount.forEach((label) => {
      createTotal += label.createCount;
      completeTotal += label.completeCount;
      imcompleteCount += label.imcompleteCount;
    });
    setLabelTotal({
      createTotal: createTotal,
      completeTotal: completeTotal,
      imcompleteCount: imcompleteCount,
    });
  }, [labelCount]);

  React.useEffect(() => {
    console.info(checkListStatus);
  }, [checkListStatus]);

  return (
    <Container>
      <ColumnBox>
        <SortingForm
          DETAILDATE
          inputs={inputs}
          setInputs={setInputs}
          onChangeDate={onChangeDate}
        />
        <StyledSelect
          ARROW
          label="항목"
          margin="26px 0 0 0"
          width={280}
          options={["SCAR 통계", "체크리스트 통계"]}
          value={type}
          onChange={(_, value) => setType(value)}
        />
      </ColumnBox>
      {type === "SCAR 통계" && (
        <>
          <TableSet>
            <Text margin="0 0 12px 0">SCAR 기간 내 총 발행</Text>
            <Table GRAY>
              <Text width={138} margin="0 0 0 30px">
                총 건수
              </Text>
              <Text width={145}>처리누계</Text>
              <Text width={70}>미처리</Text>
            </Table>
            <Table>
              <Text width={138} margin="0 0 0 30px">
                {scarCount?.total} 건
              </Text>
              <Text width={145}>{scarCount?.complete} 건</Text>
              <Text width={70}>{scarCount?.unprocessed} 건</Text>
            </Table>

            <CircleChart data={status} />
          </TableSet>
          <TableSet>
            <Text margin="0 0 12px 0">SCAR 라벨별</Text>
            <Table GRAY>
              <Text width={103} margin="0 0 0 20px">
                분류
              </Text>
              <Text width={68}>발행</Text>
              <Text width={58}>완료</Text>
              <Text width={116}>미완료</Text>
              <Text width={18}>%</Text>
            </Table>
            <ScrollWrapper>
              {labelCount?.map((label, index) => (
                <Table key={index}>
                  <Text width={86} margin="0 0 0 20px">
                    {label?.labelName}
                  </Text>
                  <Text width={45} END>
                    {label?.createCount}
                  </Text>
                  <Text width={68} END>
                    {label?.completeCount}
                  </Text>
                  <Text width={72} END>
                    {label?.imcompleteCount}
                  </Text>
                  <Text width={88} END BLUE BOLD>
                    {label?.ratio} %
                  </Text>
                </Table>
              ))}
            </ScrollWrapper>
            <Table RESULT>
              <Text width={86} margin="0 0 0 20px">
                합계
              </Text>
              <Text width={45} END>
                {labelTotal?.createTotal}
              </Text>
              <Text width={68} END>
                {labelTotal?.completeTotal}
              </Text>
              <Text width={72} END>
                {labelTotal?.imcompleteCount}
              </Text>
            </Table>
          </TableSet>
        </>
      )}

      {type === "체크리스트 통계" && (
        <>
          <TableSet>
            <Text margin="0 0 12px 0">체크리스트 총 평가 통계</Text>
            <Table GRAY>
              <Text width={100} margin="0 0 0 30px">
                총준수율
              </Text>
              <Text width={100}>평균 총점</Text>
              <Text width={100}>총 평균 점수</Text>
              <Text width={100}>반복 평균</Text>
            </Table>
            <Table>
              <Text width={100} margin="0 0 0 30px">
                {
                  checkListData?.seeCheckListStatistics?.totalEvaluationInfo
                    ?.totalComplyRate
                }
                %
              </Text>
              <Text width={100}>
                {
                  checkListData?.seeCheckListStatistics?.totalEvaluationInfo
                    ?.avgTotalScore
                }
              </Text>
              <Text width={100}>
                {
                  checkListData?.seeCheckListStatistics?.totalEvaluationInfo
                    ?.avgEvaluationScore
                }
              </Text>
              <Text width={100}>
                {
                  checkListData?.seeCheckListStatistics?.totalEvaluationInfo
                    ?.avgRepeatViolation
                }
              </Text>
            </Table>
          </TableSet>
          <TableSet>
            <Text margin="0 0 12px 0">체크리스트 범주별 평균 통계</Text>
            <Table GRAY>
              <Text width={120} margin="0 0 0 20px">
                ITEM
              </Text>
              <Text width={90}>MA/CO.</Text>
              <Text width={60}>PE.</Text>
              <Text width={60}>RE.</Text>
              <Text width={60}>SC.</Text>
            </Table>
            <ScrollWrapper>
              {checkListData?.seeCheckListStatistics?.bySafetyCategoryStatistics?.categoryStatisticsList.map(
                (list, index) => (
                  <Table key={index}>
                    <Text width={120} paddingRight={10} margin="0 0 0 20px">
                      {list?.item_kr}
                    </Text>
                    <Text width={90}>
                      {list?.MA} / {list?.CO}
                    </Text>
                    <Text width={60}>{list?.PE}</Text>
                    <Text width={60}>{list?.RE}</Text>
                    <Text width={60}>{list?.SC}</Text>
                  </Table>
                )
              )}
            </ScrollWrapper>
            <Table RESULT>
              <Text width={120} margin="0 0 0 20px">
                합계
              </Text>
              <Text width={90}>
                {
                  checkListData?.seeCheckListStatistics
                    ?.bySafetyCategoryStatistics?.totalMA
                }
                &nbsp;/&nbsp;
                {
                  checkListData?.seeCheckListStatistics
                    ?.bySafetyCategoryStatistics?.totalCO
                }
              </Text>
              <Text width={60}>
                {
                  checkListData?.seeCheckListStatistics
                    ?.bySafetyCategoryStatistics?.totalPE
                }
              </Text>
              <Text width={60}>
                {
                  checkListData?.seeCheckListStatistics
                    ?.bySafetyCategoryStatistics?.totalRE
                }
              </Text>
              <Text width={60}>
                {
                  checkListData?.seeCheckListStatistics
                    ?.bySafetyCategoryStatistics?.totalSC
                }
              </Text>
            </Table>
          </TableSet>
          <TableSet width={100}>
            <CircleChart
              CHECK_LIST
              title="체크리스트 비율 분포"
              data={checkListStatus}
              checkListTotal={
                checkListData?.seeCheckListStatistics?.checkListSocreRate
                  ?.totalCheckItemCount
              }
            />

            <StickChart
              data={checkListData?.seeCheckListStatistics?.byMonthStatistics}
            />
          </TableSet>
        </>
      )}
    </Container>
  );
};

export default DetailStat;
