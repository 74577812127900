import React, { memo, useContext } from "react";
import styled from "styled-components";
import StyledCheckbox from "../../../share/StyledCheckbox";
import profileImage from "../../../../assets/image/profileImage.png";
import { dateFormat } from "../../../../utils/common";
import { ApprovalContext } from "../../../../routes/User/ApprovalList/ApprovalListContainer";
import { groupChanger } from "../../../../lib/UserUtils";
import emptyProfile from "../../../../assets/icon/emptyProfile.svg";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 20px;
  color: ${({ theme }) => theme.defaultFontColor};
  padding-top: 3px;
`;

const Image = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-position: 50%;
  background-size: ${({ src }) => (src === "" ? "50%" : "cover")};
  background-repeat: no-repeat;
`;

const RowTable = () => {
  const { userList, checkIndex, onClickCheckBox } = useContext(ApprovalContext);

  return (
    <Container>
      {userList?.map((data, index) => (
        <RowBox key={index}>
          <StyledCheckbox
            clicked={checkIndex.includes(data.user_id)}
            onClick={() => onClickCheckBox(data?.user_id)}
          />
          <Text width={152}>
            {dateFormat(new Date(Number(data?.user_createdAt)), "yyyy.MM.dd")}
          </Text>
          <Text width={102}>
            <Image src={data?.user_profileImg} />
          </Text>
          <Text width={150}>{data?.user_groupCompany}</Text>
          <Text width={98}>{groupChanger(data?.user_group)}</Text>
          <Text width={118}>{data?.user_name}</Text>
          <Text width={89}>{data?.user_rank}</Text>
          <Text width={88}>{data?.user_countryCode}</Text>
          <Text width={162}>{data?.user_cellphone}</Text>
          <Text width={256}>{data?.user_email}</Text>
          <Text width={200}>{data?.user_headAuth ? "승인" : "미승인"}</Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
