import React from "react";
import styled from "styled-components";
import SideBarTabCircle from "../../../assets/icon/SideBarTabCircle.svg";
import SideBarTabPlus from "../../../assets/icon/SideBarTabPlus.svg";

const Container = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.whiteColor};
  margin: ${({ margin }) => margin && "0 8px"};
`;

const Icon = styled.img`
  width: 16px;
  margin-left: auto;
`;

const TabMenu = ({ TAB, active, label, onClick = () => {} }) => {
  return (
    <Container onClick={onClick}>
      {!TAB && <Text margin>-</Text>}
      <Text>{label}</Text>
      {active && <Icon src={TAB ? SideBarTabPlus : SideBarTabCircle} />}
    </Container>
  );
};

export default TabMenu;
