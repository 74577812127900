import React, {
  useState,
  useCallback,
  createContext,
  useEffect,
  useContext,
} from "react";
import NoticePresenter from "./NoticePresenter";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_NOTICE } from "../../../graphql/Management/Notice/mutatioin";
import { SEE_NOTICE_LIST } from "../../../graphql/Management/Notice/query";
import { noticeSortChanger } from "../../../lib/ManageUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const NoticeContext = createContext();

const NoticeContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [radioIndex, setRadioIndex] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "게시일 순",
  });

  const [deleteNotice] = useMutation(DELETE_NOTICE);

  const {
    data: noticeData,
    loading: noticeLoading,
    refetch: noticeRefetch,
  } = useQuery(SEE_NOTICE_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: noticeSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "게시일 순",
    });
  }, [inputs]);

  const handlePlus = useCallback(() => {
    setAddModal(!addModal);
  }, []);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      window.alert("삭제할 공지를 선택해주세요");
      return;
    }
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteNotice({
          variables: {
            noti_id: noticeList[radioIndex].noti_id,
          },
        });
        if (data?.deleteNotice?.result) {
          await noticeRefetch();
          toast.success("공지를 삭제하였습니다.");
        }
        if (data?.deleteNotice && !data?.deleteNotice?.tokenExpired) {
          userLogout();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setRadioIndex(null);
      }
    }
  }, [radioIndex]);

  const handleEdit = useCallback(() => {
    if (radioIndex === null) {
      window.alert("수정할 공지를 선택해주세요");
      return;
    }
    setEditModal(!editModal);
  }, [radioIndex]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  useEffect(() => {
    if (noticeData?.seeNoticeList?.tokenExpired) {
      setNoticeList(noticeData.seeNoticeList.noticeList);
      setTotalLength(noticeData.seeNoticeList.totalLength);
    }
    if (noticeData?.seeNoticeList && !noticeData?.seeNoticeList?.tokenExpired) {
      userLogout();
    }
  }, [noticeData]);

  return (
    <NoticeContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handlePlus,
        handleMinus,
        handleEdit,
        page,
        totalLength,
        onClickPage,
        addModal,
        setAddModal,
        editModal,
        setEditModal,
        radioIndex,
        onClickRadio,
        noticeList,
        noticeRefetch,
      }}
    >
      <NoticePresenter />
    </NoticeContext.Provider>
  );
};

export default NoticeContainer;
