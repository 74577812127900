import React, {
  useState,
  useEffect,
  useCallback,
  createContext,
  useContext,
} from "react";
import RiskCodePresenter from "./RiskCodePresenter";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_RISKCODE_LIST } from "../../../graphql/Management/RiskCode/query";
import { DELETE_RISK_CODE } from "../../../graphql/Management/RiskCode/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const RiskcodeContext = createContext();

const RiskCodeContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [radioIndex, setRadioIndex] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [riskcodeList, setRiskcodeList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
  });

  const {
    data: riskcodeData,
    loading: riskcodeLoading,
    refetch: riskcodeRefetch,
  } = useQuery(SEE_RISKCODE_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      take: 16,
      cursor: cursor,
    },
  });

  const [deleteRiskCode] = useMutation(DELETE_RISK_CODE);

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
    });
  }, [inputs]);

  const handlePlus = useCallback(() => {
    setAddModal(!addModal);
  }, [addModal]);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      toast.error("삭제할 리스크코드를 선택해주세요.");
      return;
    }
    try {
      if (window.confirm("리스크코드를 삭제하시겠습니까?")) {
        const { data } = await deleteRiskCode({
          variables: {
            rc_id: riskcodeList[radioIndex].rc_id,
          },
        });
        if (data?.deleteRiskCode?.result) {
          toast.success("리스크코드를 삭제하였습니다.");
          await riskcodeRefetch();
          setRadioIndex(null);
        }
        if (data?.deleteRiskCode && !data?.deleteRiskCode?.tokenExpired) {
          userLogout();
        }
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [radioIndex]);

  const handleEdit = useCallback(() => {
    if (radioIndex === null) {
      window.alert("수정할 코드를 선택해주세요.");
      return;
    }
    setEditModal(!editModal);
  }, [radioIndex]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  useEffect(() => {
    if (riskcodeData?.seeRiskCodeList?.tokenExpired) {
      setTotalLength(riskcodeData?.seeRiskCodeList?.totalLength);
      setRiskcodeList(riskcodeData?.seeRiskCodeList?.riskCodeList);
    }
    if (
      riskcodeData?.seeRiskCodeList &&
      !riskcodeData?.seeRiskCodeList?.tokenExpired
    ) {
      userLogout();
    }
  }, [riskcodeData]);

  return (
    <RiskcodeContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handlePlus,
        handleMinus,
        handleEdit,
        page,
        totalLength,
        onClickPage,
        addModal,
        setAddModal,
        editModal,
        setEditModal,
        radioIndex,
        riskcodeRefetch,
        onClickRadio,
        riskcodeList,
      }}
    >
      <RiskCodePresenter />
    </RiskcodeContext.Provider>
  );
};

export default RiskCodeContainer;
