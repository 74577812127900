import React, {
  useState,
  useCallback,
  createContext,
  useContext,
  useEffect,
} from "react";
import CheckListPresenter from "./CheckListPresenter";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_TOTAL_CHECK_LIST } from "../../../graphql/Management/CheckList/query";
import { SEE_SAFETY_CATEGORY } from "../../../graphql/Management/Category/query";
import { DELETE_CHECK_LIST_ITEM } from "../../../graphql/Management/CheckList/mutation";

export const CheckListContext = createContext();

const CheckListContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [radioIndex, setRadioIndex] = useState(null);
  const [checkListModal, setCheckListModal] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [safetyOptions, setSafetyOptions] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [inputs, setInputs] = useState({
    searchTerm: "",
    safe: "전체",
  });

  const {
    data: checkListData,
    loading: checkListLoading,
    refetch: checkListRefetch,
  } = useQuery(SEE_TOTAL_CHECK_LIST, {
    variables: {
      take: 10,
      cursor: cursor,
      safetyCatgory: inputs.safe === "전체" ? "" : inputs.safe,
      searchTerm: inputs.searchTerm,
    },
  });
  const {
    data: safetyData,
    loading: safetyLoading,
    refetch: safetyRefetch,
  } = useQuery(SEE_SAFETY_CATEGORY, {
    fetchPolicy: "network-only",
  });

  // Mutations
  const [deleteCheckListItem] = useMutation(DELETE_CHECK_LIST_ITEM);

  React.useEffect(() => {
    console.info("checkListData", checkListData?.seeTotalCheckList);
    console.info("selectedItem", selectedItem);
  }, [checkListData, selectedItem]);

  useEffect(() => {
    if (!safetyLoading && safetyData) {
      setSafetyOptions([
        { safetyKr: "전체" },
        ...safetyData?.seeSafetyCategory?.safetyCategoryList.map((opt) => ({
          safetyKr: opt.sc_name_kr,
          safetyEng: opt.sc_name_eng,
        })),
      ]);
    }
  }, [safetyLoading, safetyData]);

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      safe: "전체",
    });
  }, [inputs]);

  const handlePlus = useCallback(() => setCheckListModal("CREATE"), []);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      toast.error("삭제할 체크항목을 선택해주세요.");
      return;
    }
    try {
      if (!window.confirm("체크항목을 삭제하시겠습니까?")) return;

      const { data } = await deleteCheckListItem({
        variables: {
          cliId: radioIndex,
        },
      });

      if (data?.deleteCheckListItem?.result) {
        toast.success("선택한 체크리스트를 삭제했습니다.");
        await checkListRefetch();
        setRadioIndex(null);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [radioIndex]);

  const handleEdit = useCallback(() => {
    if (radioIndex === null) {
      window.alert("수정할 항목을 선택해주세요.");
      return;
    }
    setCheckListModal("UPDATE");
    setSelectedItem(
      checkListData?.seeTotalCheckList?.checkItemList.find(
        (list) => list.cli_id === radioIndex
      )
    );
  }, [checkListData, radioIndex]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 10);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  return (
    <CheckListContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handlePlus,
        handleMinus,
        handleEdit,
        page,
        totalLength: checkListData?.seeTotalCheckList?.totalLength,
        onClickPage,
        checkListModal,
        setCheckListModal,
        radioIndex,
        onClickRadio,
        checkListRefetch,
        checkItemList: checkListData?.seeTotalCheckList?.checkItemList,
      }}
    >
      <CheckListPresenter
        safetyOptions={safetyOptions}
        checkListRefetch={checkListRefetch}
        checkItemList={checkListData?.seeTotalCheckList?.checkItemList}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        setRadioIndex={setRadioIndex}
      />
    </CheckListContext.Provider>
  );
};

export default CheckListContainer;
