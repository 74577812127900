import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/User/ApprovalList/RowTable";
import StyledButton from "../../../components/share/StyledButton";
import { ApprovalContext } from "./ApprovalListContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  position: relative;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 51px;
`;

const ApprovalListPresenter = () => {
  const {
    inputs,
    setInputs,
    handleReset,
    handleApproval,
    page,
    totalLength,
    onClickPage,
    checkIndex,
    onClickCheckBox,
    userList,
  } = useContext(ApprovalContext);

  return (
    <Container>
      <GuideLine label={["유저관리", "승인 요청 목록"]} />
      <ContentContainer>
        <SortingForm
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          placeholder="유저명으로 검색"
          SEARCH
          USERSORT
          RESET
        />
        <ButtonWrapper>
          <StyledButton
            label="거절"
            margin="0 12px 0 0"
            onClick={() => handleApproval(false)}
          />
          <StyledButton
            label="승인"
            BLACK
            onClick={() => handleApproval(true)}
          />
        </ButtonWrapper>
        <StyledRowTable
          CHECKBOX
          clicked={checkIndex.length === 16}
          handleCheckbox={onClickCheckBox}
          label={[
            { text: "가입일", width: 152 },
            { text: "프로필", width: 102 },
            { text: "소속회사명", width: 150 },
            { text: "소속", width: 98 },
            { text: "이름", width: 118 },
            { text: "직책", width: 89 },
            { text: "국가", width: 88 },
            { text: "연락처", width: 162 },
            { text: "메일주소", width: 256 },
            { text: "승인여부", width: 200 },
          ]}
        />
        <RowTable
          data={userList}
          checkIndex={checkIndex}
          onClickCheckBox={onClickCheckBox}
        />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </ContentContainer>
    </Container>
  );
};

export default ApprovalListPresenter;
