import React from "react";
import styled, { css } from "styled-components";
import checkbox from "../../assets/icon/checkbox.svg";
import checkboxgray from "../../assets/icon/checkboxgray.svg";

const Container = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.checkboxBorderColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  transition: 0.2s;
  cursor: pointer;
  ${({ clicked }) =>
    clicked &&
    css`
      background-color: ${({ theme }) => theme.mainColor};
      border: none;
    `}
`;

const CheckIcon = styled.img`
  transition: 0.3s;
`;

const StyledCheckbox = ({ name = "", clicked = false, onClick = () => {} }) => {
  return (
    <Container clicked={clicked} onClick={onClick}>
      <CheckIcon clicked={clicked} src={clicked ? checkbox : checkboxgray} />
    </Container>
  );
};

export default React.memo(StyledCheckbox);
