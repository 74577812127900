import React, { memo, useContext } from "react";
import styled from "styled-components";
import { FiImage } from "react-icons/fi";
import projectImage from "../../../../../assets/image/projectImage.jpeg";
import { UserDetailContext } from "../../../../../routes/User/UserList/UserDetail/UserDetailContainer";
import { dateFormat } from "../../../../../utils/common";
import { projectStatusChanger } from "../../../../../lib/ProjectUtils";

const Container = styled.div`
  width: 100%;
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 10px;
    /* height: 5px; */
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.scrollBackgroundColor};
  }
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const DotWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  margin: ${({ margin }) => margin};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 1px 20px 1px 0;
  color: ${({ theme, GRAY }) =>
    GRAY ? `${theme.tableLinkFontColor}` : `${theme.defaultFontColor}`};
`;

const Image = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.textFieldBorder};
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${({ bgColor, theme }) =>
    bgColor === "진행중" || bgColor === "발행"
      ? `${theme.projectDetailStateIngColor}`
      : bgColor === "최종완료"
      ? `${theme.projectDetailStateCompleteColor}`
      : `${theme.projectDetailStateOAColorl}`};
`;

const RowTable = () => {
  const { prjList } = useContext(UserDetailContext);

  return (
    <Container>
      {prjList.length !== 0 &&
        prjList.map((data, index) => (
          <RowBox key={index}>
            <Text width={98}>
              <Image src={data.prj_img}>
                {data?.prj_img === "" && <FiImage />}
              </Image>
            </Text>
            <Text width={147}>{data.prj_title}</Text>
            <DotWrapper>
              <Dot bgColor={projectStatusChanger(data.prj_status)} />
              <Text width={106}>{projectStatusChanger(data.prj_status)}</Text>
            </DotWrapper>
            <Text width={231}>
              {dateFormat(new Date(Number(data.prj_startDate)), "yyyy.MM.dd")} ~{" "}
              {dateFormat(new Date(Number(data.prj_endDate)), "yyyy.MM.dd")}
            </Text>
            <Text width={320}>{data.prj_address}</Text>
            <Text width={141}>{data.prj_number}</Text>
            <Text width={107}>{data.prj_memberCount}명</Text>
            <Text width={163}>{data.prj_scarCount}건</Text>
            <Text width={180}>
              {data.prj_creatorInfo
                .replace("head", "PM본사")
                .replace("local", "PM현장")
                .replace("owner", "발주처")
                .replace("builder", "시공사")}
            </Text>
          </RowBox>
        ))}
    </Container>
  );
};

export default memo(RowTable);
