import React from "react";
import styled from "styled-components";
import tableLinkIcon from "../../../../assets/icon/tableLinkIcon.svg";
import { useNavigate } from "react-router-dom";
import { dateFormat } from "../../../../utils/common";

const Container = styled.div`
  width: ${({ SHORT }) => (SHORT ? 297 : 648)}px;
  padding: 17px 18px;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.defaultFontColor};
`;

const LinkButon = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.tableLinkFontColor};
  cursor: pointer;
`;

const LinkIcon = styled.img`
  margin-left: 9px;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 2px solid ${({ theme }) => theme.graphBorderColor};
`;

const TableRow = styled.div`
  width: 100%;
  height: 43px;
  display: flex;
  align-items: center;
  padding: 0 18px 0 18px;
  border-bottom: 1px solid ${({ theme }) => theme.graphBorderColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-size: 14px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-y: hidden;
  padding-right: 10px;
  padding-top: 3px;
`;

const Table = ({
  SHORT,
  title = "최근 일대일 문의",
  label,
  width,
  dataKey,
  data,
  link,
}) => {
  const navigate = useNavigate();

  return (
    <Container SHORT={SHORT}>
      <HeaderRow>
        <Title>{title}</Title>
        <LinkButon onClick={() => navigate(link)}>
          바로가기
          <LinkIcon src={tableLinkIcon} />
        </LinkButon>
      </HeaderRow>
      <TableRow>
        {label.map((text, index) => (
          <Text key={index} width={width[index]}>
            {text}
          </Text>
        ))}
      </TableRow>
      {data.map((dataObj, index) => (
        <TableRow key={index}>
          {dataKey.map((key, index) => (
            <Text key={index} width={width[index]}>
              {key.includes("createdAt")
                ? dateFormat(new Date(Number(dataObj[key])), "yyyy.MM.dd")
                : dataObj[key]}
            </Text>
          ))}
        </TableRow>
      ))}
    </Container>
  );
};

export default Table;
