import React, {
    useState,
    useCallback,
    useEffect,
    createContext,
    useContext,
} from "react";
import ProjectDetailPresenter from "./ProjectDetailPresenter";
import {useLazyQuery, useQuery} from "@apollo/client";
import {
    SEE_PRJ_DETAIL_INFO,
    SEE_PRJ_SCAR_LIST,
    SEE_LABEL_LIST,
    SEE_ADMIN_PRJ_CHECK_LIST,
    SEE_ADMIN_PRJ_CHECK_LIST_DETAIL,
    SEE_CHECK_LIST_SETTING_LIST,
} from "../../../../graphql/Project/Project/ProjectDetail/query";
import {
    scarSortChanger,
    riskGradeChanger,
    scarStatusChanger,
    checkListSortChanger,
    checkListStatusChanger,
    checkListRatioChanger,
} from "../../../../lib/ProjectUtils";
import {AuthContext} from "../../../../context/AuthProvider";
import {toast} from "react-toastify";
import {SEE_SAFETY_CATEGORY} from "../../../../graphql/Management/Category/query";

export const PrjDetailContext = createContext(null);

const ProjectDetailContainer = () => {
    const {userLogout} = useContext(AuthContext);
    const [cursor, setCursor] = useState(0);
    const [tab, setTab] = useState("발행목록");
    const [checkSetModal, setCheckSetModal] = useState(false);
    const [selectCheckList, setSelectCheckList] = useState(null);
    const [radioIndex, setRadioIndex] = useState(null);
    const [page, setPage] = useState(1);
    const [totalLength, setTotalLength] = useState(0);
    const [labelList, setLabelList] = useState([]);
    const [scarList, setScarList] = useState([]);
    const [prjInfo, setPrjInfo] = useState(null);
    const now = new Date();
    const searchStartDate = new Date(2019, 0, 1);
    const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);
    const [inputs, setInputs] = useState({
        searchTerm: "",
        date: [searchStartDate, searchEndDate],
        sort: "최신순",
        state: "전체",
        grade: "전체",
        detailLabel: "전체",
        safe: "전체",
        checkListSort: "최신순",
        checkListRatio: "전체",
        checkListStatus: "전체",
    });
    const [checkListCursor, setCheckListCursor] = useState(0);
    const [checkListPage, setCheckListPage] = useState(1);
    const [safetyOptions, setSafetyOptions] = useState([]);
    const [checkListSettingCursor, setCheckListSettingCursor] = useState(0);
    const [checkListSettingPage, setCheckListSettingPage] = useState(1);

    const {
        data: prjDetailData,
        loading: prjDetailLoading,
        refetch: prjDetailRefetch,
    } = useQuery(SEE_PRJ_DETAIL_INFO, {
        variables: {
            prjId: Number(localStorage.getItem("prjId")),
        },
    });
    const {
        data: labelData,
        loading: labelLoading,
        refetch: labelRefetch,
    } = useQuery(SEE_LABEL_LIST, {
        variables: {
            searchTerm: "",
        },
    });
    const {
        data: checkListData,
        loading: projectCheckListLoading,
        refetch: projectCheckListRefetch,
    } = useQuery(SEE_ADMIN_PRJ_CHECK_LIST, {
        variables: {
            prjId: Number(localStorage.getItem("prjId")),
            sort: checkListSortChanger(inputs.checkListSort),
            status: checkListStatusChanger(inputs.checkListStatus),
            complyRate: checkListRatioChanger(inputs.checkListRatio),
            take: 10,
            cursor: checkListCursor,
        },
    });
    const {
        data: safetyData,
        loading: safetyLoading,
        refetch: safetyRefetch,
    } = useQuery(SEE_SAFETY_CATEGORY);
    const {
        data: checkListSettingData,
        loading: checkListSettingLoading,
        refetch: checkListSettingRefetch,
    } = useQuery(SEE_CHECK_LIST_SETTING_LIST, {
        variables: {
            prjId: Number(localStorage.getItem("prjId")),
            safetyCategory: inputs.safe === "전체" ? "" : inputs.safe,
            take: 10,
            cursor: checkListSettingCursor,
        },
    });
    const [
        seeAdminPrjCheckListDetail,
        {
            data: checkListDetailData,
            loading: checkListDetailLoading,
            refetch: checkListDetailRefetch,
        },
    ] = useLazyQuery(SEE_ADMIN_PRJ_CHECK_LIST_DETAIL);

    const onClickCheckListDetail = useCallback(async (wclId) => {
        try {
            setSelectCheckList(wclId);
            await seeAdminPrjCheckListDetail({
                variables: {
                    wclId: wclId,
                },
            });
        } catch (e) {
            toast.error(e.message);
        }
    }, []);

    const {
        data: scarData,
        loading: scarLoading,
        refetch: scarRefetch,
    } = useQuery(SEE_PRJ_SCAR_LIST, {
        variables: {
            prj_id: Number(localStorage.getItem("prjId")),
            searchTerm: inputs.searchTerm,
            start: inputs.date[0],
            end: inputs.date[1],
            sort: scarSortChanger(inputs.sort),
            status: scarStatusChanger(inputs.state),
            riskGrade: riskGradeChanger(inputs.grade),
            label: inputs.detailLabel === "전체" ? "" : inputs.detailLabel,
            take: 16,
            cursor: cursor,
        },
    });

    const handleReset = useCallback(() => {
        setInputs({
            searchTerm: "",
            date: [searchStartDate, searchEndDate],
            sort: "최신순",
            state: "전체",
            grade: "전체",
            detailLabel: "전체",
            safe: "전체",
            checkListSort: "최신순",
            checkListRatio: "전체",
            checkListStatus: "전체",
        });
    }, [inputs]);

    const handleEditModal = useCallback(() => {
        if (radioIndex === null) {
            toast.error("수정할 안전범주를 선택해주세요.");
            return;
        }
        setCheckSetModal(true);
    });

    const onChangeDate = useCallback(
        (value) => {
            setInputs({...inputs, date: value});
        },
        [inputs]
    );

    const onClickPage = useCallback(
        (page) => {
            switch (tab) {
                case "발행목록":
                    setPage(page);
                    if (page !== 1) {
                        setCursor((page - 1) * 16);
                    }
                    if (page === 1) {
                        setCursor(0);
                    }
                    break;
                case "체크리스트":
                    setCheckListPage(page);
                    if (page !== 1) {
                        setCheckListCursor((page - 1) * 10);
                    }
                    if (page === 1) {
                        setCheckListCursor(0);
                    }
                    break;
                case "체크리스트 설정":
                    setCheckListSettingPage(page);
                    if (page !== 1) {
                        setCheckListSettingCursor((page - 1) * 10);
                    }
                    if (page === 1) {
                        setCheckListSettingCursor(0);
                    }
                    break;
            }
        },
        [tab]
    );

    useEffect(() => {
        if (prjDetailData?.seePrjDetailInfo?.result) {
            setPrjInfo(prjDetailData.seePrjDetailInfo.projectInfo);
        }
        if (
            prjDetailData?.seePrjDetailInfo &&
            !prjDetailData?.seePrjDetailInfo?.tokenExpired
        ) {
            userLogout();
        }
    }, [prjDetailData]);

    useEffect(() => {
        if (labelData?.seeLabelList?.tokenExpired) {
            setLabelList(
                labelData.seeLabelList.labelList.map((label) => label.lb_name)
            );
        }
        if (labelData?.seeLabelList && !labelData?.seeLabelList?.tokenExpired) {
            userLogout();
        }
    }, [labelData]);

    useEffect(() => {
        if (scarData?.seePrjScarList?.result) {
            setScarList(scarData.seePrjScarList.scarList);
            setTotalLength(scarData.seePrjScarList.totalLength);
        }
        if (scarData?.seePrjScarList && !scarData?.seePrjScarList?.tokenExpired) {
            userLogout();
        }
    }, [scarData]);

    useEffect(() => {
        if (!safetyLoading && safetyData) {
            setSafetyOptions([
                {safetyKr: "전체"},
                ...safetyData?.seeSafetyCategory?.safetyCategoryList.map((opt) => ({
                    safetyKr: opt.sc_name_kr,
                    safetyEng: opt.sc_name_eng,
                })),
            ]);
        }
    }, [safetyLoading, safetyData]);

    useEffect(() => {
        if (inputs.safe !== '') {
            setCheckListSettingCursor(0);
            setCheckListSettingPage(1);
        }
    }, [inputs]);

    return (
        <PrjDetailContext.Provider
            value={{
                tab,
                setTab,
                inputs,
                setInputs,
                handleReset,
                onChangeDate,
                labelList,
                prjInfo,
                scarList,
                prjDetailRefetch,
                totalLength,
                onClickPage,
                selectCheckList,
                setSelectCheckList,
                onClickCheckListDetail, // 체크리스트 상세보기 클릭
                radioIndex,
                setRadioIndex,
                checkSetModal,
                setCheckSetModal,
                handleEditModal,

                // 페이지
                page, // SCAR 발행 목록
                checkListPage, // 체크리스트
                checkListSettingPage, // 체크리스트 설정

                checkListData: checkListData?.seeAdminPrjCheckList, // 체크리스트 데이터
                checkListDetailData: checkListDetailData?.seeAdminPrjCheckListDetail, // 체크리스트 상세 데이터
                checkListSettingData: checkListSettingData?.seeCheckListSettingList, // 체크리스트 설정 데이터

                safetyOptions,
                checkListSettingRefetch,
            }}
        >
            <ProjectDetailPresenter/>
        </PrjDetailContext.Provider>
    );
};

export default ProjectDetailContainer;
