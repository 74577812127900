import React, { useRef, useState, useEffect, memo, useCallback } from "react";
import styled from "styled-components";
import selectArrow from "../../assets/icon/selectArrow.svg";
import { Text } from "recharts";

const Container = styled.div`
  width: ${({ width }) => width}px;
  position: relative;
  cursor: pointer;
  margin-bottom: ${({ NOBORDER }) => (NOBORDER ? 12 : 0)}px;
  margin: ${({ margin }) => margin};
`;

const SelectBox = styled.div`
  width: 100%;
  height: 46px;
  display: flex;
  align-items: center;
  padding: ${({ padding }) => padding};
  border-radius: 8px;
  border: ${({ NOBORDER, theme }) =>
    NOBORDER ? "" : `1px solid ${theme.styledBorderColor}`};
  font-size: 16px;
`;

const Label = styled.div`
  margin-bottom: 12px;
  color: ${({ theme }) => theme.textFieldPlaceHolder};
`;

const Icon = styled.img`
  width: 14px;
  position: absolute;
  right: 15px;
  bottom: calc(23px - 4px);
`;

const OptionBox = styled.div`
  width: 100%;

  height: ${({ height }) => (height ? `${height}px` : "auto")};
  max-height: 250px;
  padding: 10px 0;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  position: absolute;
  background-color: ${({ theme }) => theme.whiteColor};
  overflow-y: auto;
  z-index: 1;
`;

const Option = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  &:hover {
    background-color: ${({ theme }) => theme.grayBackgroundColor};
  }
  /* & > div {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  } */
`;

const TextBox = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 3px;
`;
const Placeholder = styled.div`
  color: ${({ theme }) => theme.textFieldBorder};
`;

const StyledSelect = ({
  ADD_CHECK_LIST,
  ARROW,
  NOBORDER,
  width = 160,
  padding = "0 16px",
  name = "",
  label = "",
  value = "",
  onChange = () => {},
  placeholder,
  options = [],
  margin,
}) => {
  const [optionView, setOptionView] = useState(false);
  const boxRef = useRef(null);
  const optRef = useRef(null);

  const onClickOutSide = useCallback(
    ({ target }) => {
      let boxRefCurrent = boxRef.current.contains(target);
      let optRefCurrent = optionView && optRef.current.contains(target);

      if (!boxRefCurrent && !optRefCurrent) setOptionView(false);
    },
    [boxRef, optRef]
  );

  const onClickOption = useCallback(
    (name, value, event) => {
      onChange(name, value, event);
      setOptionView(false);
    },
    [onChange]
  );

  useEffect(() => {
    window.addEventListener("click", onClickOutSide);
    return () => window.removeEventListener("click", onClickOutSide);
  }, [onClickOutSide]);

  return (
    <Container width={width} NOBORDER={NOBORDER} margin={margin}>
      <Label>{label}</Label>
      <SelectBox
        NOBORDER={NOBORDER}
        padding={padding}
        ref={boxRef}
        onClick={() => setOptionView(!optionView)}
      >
        <TextBox>{value}</TextBox>
        {value === "" && placeholder && (
          <Placeholder>{placeholder}</Placeholder>
        )}
      </SelectBox>
      {ARROW && <Icon src={selectArrow} />}

      {optionView && (
        <OptionBox ref={optRef}>
          {!ADD_CHECK_LIST &&
            options.map((value, index) => (
              <Option key={index} onClick={() => onClickOption(name, value)}>
                <TextBox>{value}</TextBox>
              </Option>
            ))}
          {ADD_CHECK_LIST &&
            options.map((value, index) => (
              <Option
                key={index}
                data-value={value.safetyEng}
                onClick={(e) => onClickOption(name, value.safetyKr, e)}
              >
                <TextBox>{value.safetyKr}</TextBox>
              </Option>
            ))}
        </OptionBox>
      )}
    </Container>
  );
};

export default memo(StyledSelect);
