import React from "react";
import AuthProvider from "./context/AuthProvider";
import { Route, Routes } from "react-router-dom";
import Login from "./routes/Login";
import SideBar from "./components/layout/SideBar/SideBar";
import styled from "styled-components";
import Stat from "./routes/Home/Stat";
import ProjectList from "./routes/Project/Project/ProjectList";
import ProjectDetail from "./routes/Project/Project/ProjectDetail";
import EndList from "./routes/Project/EndList";
import UserList from "./routes/User/UserList/UserList";
import ApprovalList from "./routes/User/ApprovalList";
import Category from "./routes/Management/Category";
import RiskCode from "./routes/Management/RiskCode";
import Qna from "./routes/Management/Qna";
import Faq from "./routes/Management/Faq";
import Notice from "./routes/Management/Notice";
import ServiceCenter from "./routes/Management/ServiceCenter";
import AdminList from "./routes/Admin/AdminList";
import UserDetail from "./routes/User/UserList/UserDetail";
import CheckList from "./routes/Management/CheckList";

const Container = styled.div`
  display: flex;
`;

const LoggedOutRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<Login />} />
    </Routes>
  );
};

const LoggedInRoutes = () => {
  return (
    <Container>
      <SideBar />
      <Routes>
        <Route path="/" element={<Stat />} />
        <Route path="/home" element={<Stat />} />
        <Route path="/project/projectList" element={<ProjectList />} />
        <Route path="/project/projectList/detail" element={<ProjectDetail />} />
        <Route path="/project/endList" element={<EndList />} />
        <Route path="/user/userList" element={<UserList />} />
        <Route path="/user/userList/detail" element={<UserDetail />} />
        <Route path="/user/approvalList" element={<ApprovalList />} />
        <Route path="/management/category" element={<Category />} />
        <Route path="/management/checkList" element={<CheckList />} />
        <Route path="/management/riskCode" element={<RiskCode />} />
        <Route path="/management/qna" element={<Qna />} />
        <Route path="/management/faq" element={<Faq />} />
        <Route path="/management/notice" element={<Notice />} />
        <Route path="/management/serviceCenter" element={<ServiceCenter />} />
        <Route path="/admin/adminList" element={<AdminList />} />
      </Routes>
    </Container>
  );
};

const AppRoutes = ({ isLoggedIn }) => {
  return (
    <AuthProvider>
      {isLoggedIn ? <LoggedInRoutes /> : <LoggedOutRoutes />}
    </AuthProvider>
  );
};
export default AppRoutes;
