import React from "react";
import styled from "styled-components";
import { css } from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import StyledIconButton from "../../../components/share/StyledIconButton";
import StyledInput from "../../../components/share/StyledInput";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  display: flex;
  /* flex-direction: column;s */
  padding: 50px 16px 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const ColumnWrapContainer = styled.div`
  width: 100%;
  /* height: 890px; */
  display: flex;
  flex-direction: column;
`;

const TitleBox = styled.div`
  width: 372px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const RowBox = styled.div`
  width: 372px;
  display: flex;

  align-items: flex-end;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
  ${({ SAFE }) =>
    SAFE &&
    css`
      margin-bottom: 12px;
      align-items: center;
    `}
`;

const PhoneNumber = styled.div`
  width: 314px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  font-weight: bold;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
`;

const ScrollWrapper = styled.div`
  width: 392px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.scrollBackgroundColor};
  }
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const CheckListTextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 75px;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
`;

const TextBox = styled.div`
  display: -webkit-box;
  /* background-color: red; */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 295px;
  padding-top: 3px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
  max-height: 34px;
`;

const CategoryPresenter = ({
  labelInput,
  setLabelInput,
  safetyCategory,
  setSafetyCategory,
  labelList = [],
  onClickAddLabel,
  onClickMinus,

  handleCreateSafetyCategory,
  handleDeleteSafetyCategory,
  safetyList = [],
}) => {
  return (
    <Container>
      <GuideLine label={["운영관리", "카테고리 설정"]} />
      <ContentContainer>
        <CategoryWrapper>
          <TitleBox>라벨</TitleBox>
          <RowBox margin="20px 0 22px 0">
            <ColumnWrapContainer>
              <StyledInput
                width={314}
                value={labelInput.kor}
                onChange={(e) =>
                  setLabelInput({
                    ...labelInput,
                    kor: e.target.value,
                  })
                }
                placeholder="(한글)라벨을 입력하세요"
                margin="0 13px 0 0"
              />
              <StyledInput
                width={314}
                value={labelInput.eng}
                onChange={(e) =>
                  setLabelInput({ ...labelInput, eng: e.target.value })
                }
                placeholder="(영문)라벨을 입력하세요"
                margin="0 13px 0 0"
              />
            </ColumnWrapContainer>
            <StyledIconButton PLUS onClick={onClickAddLabel} />
          </RowBox>
          <ScrollWrapper>
            {labelList.map((label, index) => (
              <RowBox key={index}>
                <CheckListTextWrapper>
                  <TextBox>{label?.lb_name}</TextBox>
                  <TextBox>{label?.lb_name_eng}</TextBox>
                </CheckListTextWrapper>
                <StyledIconButton
                  MINUS
                  GRAY
                  onClick={() => onClickMinus(label.lb_id)}
                />
              </RowBox>
            ))}
          </ScrollWrapper>
        </CategoryWrapper>
        <CategoryWrapper>
          <TitleBox>안전범주</TitleBox>
          <RowBox margin="20px 0 22px 0">
            <ColumnWrapContainer>
              <StyledInput
                width={314}
                value={safetyCategory.kor}
                onChange={(e) =>
                  setSafetyCategory({
                    ...safetyCategory,
                    kor: e.target.value,
                  })
                }
                placeholder="(한글)안전범주를 입력하세요"
                margin="0 13px 0 0"
                NOLENGTHVIEW={true}
                maxLength={101}
              />
              <StyledInput
                width={314}
                value={safetyCategory.eng}
                onChange={(e) =>
                  setSafetyCategory({
                    ...safetyCategory,
                    eng: e.target.value,
                  })
                }
                placeholder="(영문)안전범주를 입력하세요"
                NOLENGTHVIEW={true}
                maxLength={101}
                margin="0 13px 0 0"
              />
            </ColumnWrapContainer>
            <StyledIconButton PLUS onClick={handleCreateSafetyCategory} />
          </RowBox>
          <ScrollWrapper>
            {safetyList?.map((label, index) => (
              <RowBox key={index} SAFE>
                <CheckListTextWrapper>
                  <TextBox>{label?.sc_name_kr}</TextBox>
                  <TextBox>{label?.sc_name_eng}</TextBox>
                </CheckListTextWrapper>
                <StyledIconButton
                  MINUS
                  GRAY
                  onClick={() => handleDeleteSafetyCategory(label.sc_id)}
                />
              </RowBox>
            ))}
          </ScrollWrapper>
        </CategoryWrapper>
      </ContentContainer>
    </Container>
  );
};

export default CategoryPresenter;
