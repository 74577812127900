import { ApolloClient, InMemoryCache } from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";

const uploadLink = createUploadLink({
  uri: `//${process.env.REACT_APP_SERVER_IP}`,
  headers: {
    "Apollo-Require-Preflight": "true",
    Authorization: !localStorage.getItem("hanmi_admin_token")
      ? ""
      : `Bearer ${localStorage.getItem("hanmi_admin_token")}`,
  },
});

export default new ApolloClient({
  link: uploadLink,
  cache: new InMemoryCache(),
});
