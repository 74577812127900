export const projectSortChanger = (value) => {
    switch (value) {
        case "최신순":
            return "desc";
        case "오래된순":
            return "asc";
        case "프로젝트명":
            return "title";
        case "현장주소":
            return "address";
    }
};

export const projectStatusChanger = (value) => {
    switch (value) {
        case "전체":
            return "total";
        case "진행중":
            return "inProgress";
        case "종료예정":
            return "toEnd";
        case "종료":
            return "end";
        case "total":
            return "전체";
        case "inProgress":
            return "진행중";
        case "toEnd":
            return "종료예정";
        case "end":
            return "종료";
    }
};

export const scarSortChanger = (value) => {
    switch (value) {
        case "최신순":
            return "desc";
        case "위반사항":
            return "title";
    }
};

export const scarStatusChanger = (value) => {
    switch (value) {
        case "전체":
            return "total";
        case "미조치":
            return "notAction";
        case "진행중":
            return "inProgress";
        case "조치불가":
            return "impossible";
        case "완료":
            return "complete";
        case "반려":
            return "reject";
        case "지연":
            return "delay";
        case "total":
            return "전체";
        case "notAction":
            return "미조치";
        case "inProgress":
            return "진행중";
        case "impossible":
            return "조치불가";
        case "complete":
            return "완료";
        case "reject":
            return "반려";
        case "delay":
            return "지연";
    }
};

export const riskGradeChanger = (value) => {
    switch (value) {
        case "전체":
            return "total";
        case "상":
            return "A";
        case "중":
            return "B";
        case "하":
            return "C";
        case "A":
            return "상";
        case "B":
            return "중";
        case "C":
            return "하";
    }
};

export const checkListSortChanger = value => {
    switch (value) {
        case '최신순':
            return 'byWeek';
        case '평가완료일':
            return 'complete';
        case '준수율':
            return 'complyRate';
    }
}

export const checkListStatusChanger = value => {
    switch (value) {
        case '전체':
            return 'total';
        case '미평가':
            return 'notEvaluation';
        case '평가중':
            return 'inProgress';
        case '평가완료':
            return 'complete';
      case 'notEvaluation':
        return '미평가';
      case 'inProgress':
        return '평가중';
      case 'complete':
        return '평가완료';
    }
}

export const checkListRatioChanger = value => {
  switch (value) {
    case '전체':
      return 'A';
    case '준수':
      return 'B';
    case '대부분준수':
      return 'C';
    case '일부준수':
      return 'D';
  }
}