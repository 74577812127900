import { gql } from "@apollo/client";

export const CREATE_RISK_CODE = gql`
  mutation createRiskCode(
    $rc_name: String
    $rc_title: String
    $rc_text: String
  ) {
    createRiskCode(rc_name: $rc_name, rc_title: $rc_title, rc_text: $rc_text) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_RISK_CODE = gql`
  mutation deleteRiskCode($rc_id: Int) {
    deleteRiskCode(rc_id: $rc_id) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_RISK_CODE = gql`
  mutation editRiskCode(
    $rc_id: Int
    $rc_name: String
    $rc_title: String
    $rc_text: String
  ) {
    editRiskCode(
      rc_id: $rc_id
      rc_name: $rc_name
      rc_title: $rc_title
      rc_text: $rc_text
    ) {
      tokenExpired
      result
    }
  }
`;
