import React, { useCallback, useState } from "react";
import styled from "styled-components";
import StyledModal from "../../../../share/StyledModal";
import { useMutation } from "@apollo/client";
import { SEND_MESSAGE_USER } from "../../../../../graphql/User/UserList/UserList/mutation";
import { toast } from "react-toastify";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
`;

const InputBox = styled.textarea`
  width: 560px;
  height: 230px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const MessageModal = ({ handleMessage, id }) => {
  const [message, setMessage] = useState("");

  const [sendMessageUser] = useMutation(SEND_MESSAGE_USER);

  const handleSave = useCallback(async () => {
    if (message.trim() === "") {
      toast.error("메세지를 입력해주세요.");
      return;
    }
    try {
      const { data } = await sendMessageUser({
        variables: {
          user_ids: [id],
          msg: message,
        },
      });
      if (data?.sendMessageUser?.result) {
        toast.success("메세지를 전송하였습니다.");
        handleMessage();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [message]);

  return (
    <StyledModal
      title="문자 보내기"
      width={640}
      height={477}
      handleClose={handleMessage}
      handleSave={handleSave}
    >
      <Text>내용</Text>
      <InputBox
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="내용을 입력하세요"
      />
    </StyledModal>
  );
};

export default MessageModal;
