import { gql } from "@apollo/client";

export const SEE_ONE_LIST = gql`
  query seeOneList(
    $searchTerm: String
    $sort: oneOnOneSort
    $answerStatus: String
    $take: Int
    $cursor: Int
  ) {
    seeOneList(
      searchTerm: $searchTerm
      sort: $sort
      answerStatus: $answerStatus
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      oneList {
        one_id
        one_createdAt
        user_name
        user_groupCompany
        user_group
        user_cellphone
        user_email
        one_question
        one_answerText
        one_answer
      }
    }
  }
`;
