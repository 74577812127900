import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { HomeContext } from "../../../../routes/Home/Stat/StatContainer";

const Container = styled.div`
  width: 100%;
  min-width: 1694px;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Set = styled.div`
  display: flex;
  margin-right: 60px;
  :last-child {
    margin-left: auto;
    margin-right: 32px;
  }
`;

const Text = styled.div`
  color: ${({ theme }) => theme.defaultFontColor};
  :last-child {
    margin-left: 12px;
  }
`;

const SummaryLine = ({ groundAuthCode }) => {
  const today = new Date().getTime();
  const endDate = new Date(2024, 2, 1).getTime();

  const [Dday, setDday] = useState(
    Math.ceil((endDate - today) / (24 * 60 * 60 * 1000))
  );

  return (
    <Container>
      <Set>
        <Text>PM본사 : </Text>
        <Text>{groundAuthCode.head} </Text>
      </Set>
      <Set>
        <Text>PM현장 : </Text>
        <Text> {groundAuthCode.local} </Text>
      </Set>
      <Set>
        <Text>시공사 :</Text>
        <Text> {groundAuthCode.builder} </Text>
      </Set>
      <Set>
        <Text>발주처 :</Text>
        <Text> {groundAuthCode.owner} </Text>
      </Set>
      <Set>
        <Text>종료 기한 :</Text>
        <Text> {`2024. 3. 1 (D-${Dday})`} </Text>
      </Set>
    </Container>
  );
};

export default SummaryLine;
