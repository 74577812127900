import React, {
  useState,
  useCallback,
  createContext,
  useEffect,
  useContext,
} from "react";
import FaqPresenter from "./FaqPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_FAQ_LIST } from "../../../graphql/Management/Faq/query";
import { DELETE_FAQ } from "../../../graphql/Management/Faq/mutation";
import { faqSortChanger } from "../../../lib/ManageUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const FaqContext = createContext();

const FaqContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [radioIndex, setRadioIndex] = useState(null);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [faqList, setFaqList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "등록일 순",
  });

  const [deleteFAQ] = useMutation(DELETE_FAQ);

  const {
    data: faqData,
    loading: faqLoading,
    refetch: faqRefetch,
  } = useQuery(SEE_FAQ_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: faqSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "최신 순",
    });
  }, [inputs]);

  const handlePlus = useCallback(() => {
    setAddModal(!addModal);
  }, []);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      window.alert("삭제할 질문을 선택해주세요.");
      return;
    }
    if (window.confirm("질문을 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteFAQ({
          variables: {
            faq_id: faqList[radioIndex].faq_id,
          },
        });
        if (data?.deleteFAQ?.result) {
          toast.success("질문을 삭제하였습니다.");
          await faqRefetch();
        }
        if (data?.deleteFAQ && !data?.deleteFAQ?.tokenExpired) {
          userLogout();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setRadioIndex(null);
      }
    }
  }, [radioIndex]);

  const handleEdit = useCallback(() => {
    if (radioIndex === null) {
      window.alert("수정할 공지를 선택해주세요");
      return;
    }
    setEditModal(!editModal);
  }, [radioIndex]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  useEffect(() => {
    if (faqData?.seeFAQList?.tokenExpired) {
      setFaqList(faqData.seeFAQList.FAQList);
      setTotalLength(faqData.seeFAQList.totalLength);
    }
    if (faqData?.seeFAQList && !faqData?.seeFAQList?.tokenExpired) {
      userLogout();
    }
  }, [faqData]);

  return (
    <FaqContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handlePlus,
        handleMinus,
        handleEdit,
        page,
        totalLength,
        onClickPage,
        addModal,
        setAddModal,
        editModal,
        setEditModal,
        radioIndex,
        onClickRadio,
        faqList,
        faqRefetch,
      }}
    >
      <FaqPresenter />
    </FaqContext.Provider>
  );
};

export default FaqContainer;
