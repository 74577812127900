import React, { useContext, useState } from "react";
import styled from "styled-components";
import MemberModal from "./MemberModal";
import { dateFormat } from "../../../../../utils/common";
import { projectStatusChanger } from "../../../../../lib/ProjectUtils";
import { groupChanger } from "../../../../../lib/UserUtils";
import { PrjDetailContext } from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import { FiImage } from "react-icons/fi";
import emptyProfile from "../../../../../assets/icon/emptyProfile.svg";

const Container = styled.div`
  width: 1646px;
  height: 182px;
  display: flex;
  padding: 20px;
  margin: 8px 0 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const ProjectImage = styled.div`
  width: 142px;
  height: 142px;
  margin-right: 20px;
  border-radius: 10px;
  background-image: ${({ src }) => `url(${src})`};
  background-position: 50%;
  background-size: cover;
  background-color: ${({ theme }) => theme.textFieldBorder};
  color: ${({ theme }) => theme.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
`;

const ColumnBox = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop && 17}px;
`;

const InfoBox = styled.div`
  width: 100%;
  height: 47px;
  display: flex;
`;
const Text = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 3px;
  margin-right: ${({ DEEPGRAY }) => DEEPGRAY && 6}px;
  font-size: ${({ BLUE }) => BLUE && 14}px;
  color: ${({ theme, GRAY, DEEPGRAY, BLUE }) =>
    GRAY
      ? `${theme.textFieldPlaceHolder}`
      : DEEPGRAY
      ? `${theme.profileTeamFontColor}`
      : BLUE
      ? `${theme.projectDetailGroupFontColor}`
      : `${theme.defaultFontColor}`};
`;

const TextSet = styled.div`
  width: ${({ width }) => width}px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const RowScroll = styled.div`
  width: 1444px;
  display: flex;
  padding-bottom: 5px;
  margin-top: auto;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.scrollBackgroundColor};
    border-radius: 5px;
  }
`;

const ProfileSet = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  flex-shrink: 0;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }
`;

const ProfileImage = styled.div`
  width: 42px;
  height: 42px;
  margin-right: 8px;
  border-radius: 50%;
  background-image: ${({ src }) =>
    src === "" ? `url(${emptyProfile})` : `url(${src})`};
  background-size: ${({ src }) => (src === "" ? `40%` : `cover`)};
  background-position: 50%;
  background-repeat: no-repeat;
  border: 0.5px solid ${({ theme }) => theme.textFieldBorder};
`;

const Summary = () => {
  const { prjInfo } = useContext(PrjDetailContext);

  const [modal, setModal] = useState(false);
  const [editUser, setEditUser] = useState(null);

  return (
    <>
      <Container>
        <ProjectImage src={prjInfo?.prj_img}>
          {prjInfo?.prj_img === "" && <FiImage />}
        </ProjectImage>
        <ColumnBox>
          <InfoBox>
            <TextSet width={222}>
              <Text GRAY>프로젝트 명</Text>
              <Text>{prjInfo?.prj_title}</Text>
            </TextSet>
            <TextSet width={228}>
              <Text GRAY>프로젝트 기간</Text>
              <Text>
                {dateFormat(
                  new Date(Number(prjInfo?.prj_startDate)),
                  "yyyy.MM.dd"
                )}{" "}
                ~{" "}
                {dateFormat(
                  new Date(Number(prjInfo?.prj_endDate)),
                  "yyyy.MM.dd"
                )}
              </Text>
            </TextSet>
            <TextSet width={433}>
              <Text GRAY>현장 주소</Text>
              <Text>{prjInfo?.prj_address}</Text>
            </TextSet>
            <TextSet width={102}>
              <Text GRAY>상태</Text>
              <Text>{projectStatusChanger(prjInfo?.prj_status)}</Text>
            </TextSet>
            <TextSet width={131}>
              <Text GRAY>문서번호</Text>
              <Text>{prjInfo?.prj_number}</Text>
            </TextSet>
            <TextSet width={178}>
              <Text GRAY>생성일</Text>
              <Text>
                {dateFormat(
                  new Date(Number(prjInfo?.prj_createdAt)),
                  "yyyy.MM.dd"
                )}
              </Text>
            </TextSet>
            <TextSet width={128}>
              <Text GRAY>프로젝트 생성자</Text>
              <Text>
                {groupChanger(prjInfo?.prj_creator_group)}{" "}
                {prjInfo?.prj_creator_name}
              </Text>
            </TextSet>
          </InfoBox>
          <ColumnBox marginTop>
            <Text GRAY>구성원 (총 {prjInfo?.prj_memeber?.length}명)</Text>
            <RowScroll>
              {prjInfo?.prj_memeber.map((profile, index) => (
                <ProfileSet
                  key={index}
                  onClick={() => {
                    setModal(true);
                    setEditUser(profile);
                  }}
                >
                  <ProfileImage src={profile?.user_profileImg} />
                  <ColumnBox>
                    <Text DEEPGRAY>{groupChanger(profile?.user_group)}</Text>
                    {profile?.user_changedPermission !==
                      profile?.user_group && (
                      <Text BLUE>
                        {groupChanger(profile?.user_changedPermission)}
                      </Text>
                    )}
                  </ColumnBox>
                  <Text>{profile?.user_name}</Text>
                </ProfileSet>
              ))}
            </RowScroll>
          </ColumnBox>
        </ColumnBox>
      </Container>
      {modal && (
        <MemberModal
          prjId={prjInfo?.prj_id}
          editUser={editUser}
          handleClose={() => setModal(false)}
        />
      )}
    </>
  );
};

export default Summary;
