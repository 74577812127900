import React, {useContext} from "react";
import SummaryLine from "../../../components/feature/Home/Stat/SummaryLine";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import BarChart from "../../../components/feature/Home/Stat/BarChart";
import CircleChart from "../../../components/feature/Home/Stat/CircleChart";
import Table from "../../../components/feature/Home/Stat/Table";
import {HomeContext} from "./StatContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  height: 965px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  column-gap: 32px;
  padding: 32px 34px;
  background-color: ${({theme}) => theme.defaultBackgroundColor};
`;

const GraphContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const StatPresenter = ({
                           groundAuthCode,
                           prjStatsList,
                           signList,
                           userList,
                           oneList,
                           noticeList,
                           requestList,
                       }) => {
    return (
        <Container>
            <GuideLine label={["홈", "통계"]}/>
            <SummaryLine groundAuthCode={groundAuthCode}/>
            <ContentContainer>
                <GraphContainer>
                    <BarChart title="프로젝트 생성 통계" data={prjStatsList}/>
                </GraphContainer>
                <GraphContainer>
                    <CircleChart data={userList}/>
                </GraphContainer>
                <GraphContainer>
                    <BarChart title="가입자 수 통계" data={signList}/>
                </GraphContainer>
                <GraphContainer>
                    <Table
                        title="최근 일대일 문의"
                        link="/management/qna"
                        label={["작성일", "소속회사명", "작성자", "내용"]}
                        width={[138, 90, 76, 260]}
                        dataKey={["createdAt", "groupCompany", "writer", "question"]}
                        data={oneList}
                    />
                </GraphContainer>
                <GraphContainer>
                    <Table
                        SHORT
                        title="공지"
                        link="/management/notice"
                        label={["제목", "날짜"]}
                        width={[140, 80]}
                        dataKey={["noti_title", "noti_createdAt"]}
                        data={noticeList}
                    />
                </GraphContainer>
                <GraphContainer>
                    <Table
                        title="승인 요청 현황"
                        link="/user/approvalList"
                        label={[
                            "가입일",
                            "소속회사명",
                            "이름",
                            "직책",
                            "연락처",
                            "메일주소",
                        ]}
                        width={[96, 90, 66, 54, 154, 116]}
                        dataKey={[
                            "user_createdAt",
                            "user_groupCompany",
                            "user_name",
                            "user_rank",
                            "user_cellphone",
                            "user_email",
                        ]}
                        data={requestList}
                    />
                </GraphContainer>
            </ContentContainer>
        </Container>
    );
};

export default StatPresenter;
