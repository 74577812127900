import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import {PieChart, Pie, Cell} from "recharts";

const Wrapper = styled.div`
  padding-right: 25%;
  display: flex;
  justify-content: space-between;
`;
const StatsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Stats = styled.div`
  margin-bottom: 12px;
`;
const Text = styled.div`
  white-space: nowrap;
  font-size: ${({fontSize}) => fontSize ? fontSize : 16}px;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 400)};
  color: ${({fontColor, theme}) => fontColor ? fontColor : theme.defaultFontColor};

  & + & {
    margin-top: 6px;
  }
`;

const CircleChart = ({
                         data = [],
                         COLORS = ["#008AF9", "#FF804B", "#FFBB41", "#00C3A1"],
                     }) => {
    return (
        <Wrapper>
            <PieChart width={175} height={175}>
                <Pie
                    data={data}
                    cx={80}
                    cy={80}
                    innerRadius={70}
                    outerRadius={86}
                    paddingAngle={5}
                    dataKey="value"
                    startAngle={120}
                    endAngle={-360}
                >
                    {data?.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                    ))}
                </Pie>
            </PieChart>
            <StatsBox>
                {data?.map((stats, index) => (
                    <Stats key={`${stats.name}-${stats.value}`}>
                        <Text fontSize={12}>{stats.name}</Text>
                        <Text fontWeight={600} fontColor={COLORS[index]}>
                            <CountUp end={stats.value} duration={1}>
                                {stats.value}
                            </CountUp> %
                        </Text>
                    </Stats>
                ))}
            </StatsBox>
        </Wrapper>
    );
};

export default memo(CircleChart);
