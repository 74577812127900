import React, { memo, useState, useContext } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../share/StyledRadioButton";
import PermissionModal from "./PermissionModal";
import { permisstionChanger } from "../../../../lib/AdminUtils";
import { AdminContext } from "../../../../routes/Admin/AdminList/AdminListContainer";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  /* display: flex;
  align-items: center; */
  color: ${({ theme }) => theme.defaultFontColor};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 20px;
`;

const EndButton = styled.div`
  width: 80px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.defaultFontColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;
`;

const RowTable = () => {
  const { adminList, radioIndex, onClickRadio } = useContext(AdminContext);

  const [permissionModal, setPermissionModal] = useState(false);
  const [index, setIndex] = useState(0);

  return (
    <>
      <Container>
        {adminList.map((data, index) => (
          <RowBox key={index}>
            <StyledRadioButton
              clicked={radioIndex === index}
              onClick={() => onClickRadio(index, data.admin_id)}
            />
            <Text width={88}>{index + 1}</Text>
            <Text width={128}>{data.admin_team}</Text>
            <Text width={102}>{data.admin_name}</Text>
            <Text width={108}>{data.admin_rank}</Text>
            <Text width={162}>{data.admin_accountId}</Text>
            <Text width={176}>{data.admin_password}</Text>
            <Text width={428}>{permisstionChanger(data.adminPermission)}</Text>
            <Text width={170}>
              <EndButton
                onClick={() => {
                  setPermissionModal(true);
                  setIndex(index);
                }}
              >
                수정하기
              </EndButton>
            </Text>
          </RowBox>
        ))}
      </Container>
      {permissionModal && (
        <PermissionModal
          handleModal={() => setPermissionModal(false)}
          index={index}
          userData={adminList[index]}
        />
      )}
    </>
  );
};

export default memo(RowTable);
