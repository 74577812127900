import React, {
  useState,
  useCallback,
  createContext,
  useEffect,
  useContext,
} from "react";
import { SEE_ONE_LIST } from "../../../graphql/Management/Qna/query";
import { useQuery, useMutation } from "@apollo/client";
import { DELETE_ONE } from "../../../graphql/Management/Qna/mutation";
import { qnaSortChanger, qnaStatusChanger } from "../../../lib/ManageUtils";
import QnaPresenter from "./QnaPresenter";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const QnaContext = createContext();

const QnaContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [radioIndex, setRadioIndex] = useState(null);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [qnaList, setQnaList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "작성일순",
    state: "전체",
  });

  const [deleteOne] = useMutation(DELETE_ONE);

  const {
    data: qnaData,
    loading: qnaLoading,
    refetch: qnaRefetch,
  } = useQuery(SEE_ONE_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: qnaSortChanger(inputs.sort),
      answerStatus: qnaStatusChanger(inputs.state),
      take: 16,
      cursor: cursor,
    },
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "작성일순",
      state: "전체",
    });
  }, [inputs]);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      window.alert("삭제할 문의를 선택해주세요.");
      return;
    }
    if (window.confirm("문의를 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteOne({
          variables: {
            one_id: qnaList[radioIndex].one_id,
          },
        });
        if (data?.deleteOne?.result) {
          toast.success("문의가 삭제되었습니다.");
          await qnaRefetch();
        }
        if (data?.deleteOne && !data?.deleteOne?.tokenExpired) {
          userLogout();
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setRadioIndex(null);
      }
    }
  }, [radioIndex]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  useEffect(() => {
    if (qnaData?.seeOneList?.tokenExpired) {
      setQnaList(qnaData.seeOneList.oneList);
      setTotalLength(qnaData.seeOneList.totalLength);
    }
    if (qnaData?.seeOneList && !qnaData?.seeOneList?.tokenExpired) {
      userLogout();
    }
  }, [qnaData]);

  return (
    <QnaContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handleMinus,
        page,
        totalLength,
        onClickPage,
        radioIndex,
        onClickRadio,
        qnaList,
        qnaRefetch,
      }}
    >
      <QnaPresenter />
    </QnaContext.Provider>
  );
};

export default QnaContainer;
