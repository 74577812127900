import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import {
  BarChart as Chart,
  Bar,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
} from "recharts";
import { theme } from "../../../../styles/theme";

const Wrapper = styled.div`
  width: 650px;
  height: 100%;
  padding: 18px 18px 0 18px;
  position: relative;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 9px;
`;
const Text = styled.div`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : 14)}px;
  color: ${({ theme }) => theme.defaultFontColor};
`;
const CharBox = styled.div`
  width: 100%;
  padding: 10px;
  margin-bottom: 43px;
  border-top: 2px solid ${({ theme }) => theme.graphBorderColor};
  border-bottom: 2px solid ${({ theme }) => theme.graphBorderColor};
`;
const CustomizeChart = styled(Chart)`
  .recharts-cartesian-axis-line,
  .recharts-cartesian-axis-tick-line {
    display: none;
  }
`;

const BarChart = ({
  title,
  data = [
    { month: 1, data: 17 },
    { month: 2, data: 5 },
    { month: 3, data: 19 },
    { month: 4, data: 13 },
    { month: 5, data: 25 },
    { month: 6, data: 18 },
    { month: 7, data: 9 },
    { month: 8, data: 13 },
    { month: 9, data: 23 },
    { month: 10, data: 15 },
    { month: 11, data: 25 },
    { month: 12, data: 28 },
  ],
}) => {
  return (
    <Wrapper>
      <Header>
        <Text fontSize={16}>{title}</Text>
        {/* <Text>최근 6개월</Text> */}
      </Header>
      <CharBox>
        <CustomizeChart
          width={612}
          height={300}
          data={data}
          margin={{
            top: 16,
            right: 30,
            left: 30,
            bottom: 10,
          }}
        >
          <CartesianGrid
            vertical={false}
            strokeDasharray="2"
            stroke={"#DDDDDD"}
          />
          <XAxis dataKey="month" />
          <YAxis width={10} tickCount={7} tick={{ fontSize: 10 }} />
          <Bar
            dataKey="uv"
            fill={theme.lightTheme.mainColor}
            barSize={8}
            radius={10}
          />
          {/* <Bar
            dataKey="pv"
            fill={colors.grayBarColor}
            barSize={8}
            radius={10}
          /> */}
        </CustomizeChart>
      </CharBox>
    </Wrapper>
  );
};

export default memo(BarChart);
