import React, { useCallback, useState, useContext, useEffect } from "react";
import LoginPresenter from "./LoginPresenter";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ADMIN_LOGIN } from "../../graphql/Login/mutation";
import { AuthContext } from "../../context/AuthProvider";
import { toast } from "react-toastify";
import { SEE_ADMIN_PERMISSION } from "../../graphql/Login/query";

const LoginContainer = () => {
  const { userLogin } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    id: "",
    pw: "",
  });

  // const {
  //   data: permissionData,
  //   loading: permissionLoading,
  //   refetch: permissionRefetch,
  // } = useLazyQuery(SEE_ADMIN_PERMISSION, {
  //   variables: {
  //     accountId: inputs.id,
  //     password: inputs.pw,
  //   },
  // });

  const [adminLogin] = useMutation(ADMIN_LOGIN);

  const onChangeLogin = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const LoginHaldle = useCallback(async () => {
    setIsLoading(true);
    if (inputs.id.trim() === "" || inputs.pw.trim() === "") {
      toast.error("아이디와 비밀번호를 모두 입력해주세요.");
      setIsLoading(false);
      return;
    }
    try {
      const { data } = await adminLogin({
        variables: {
          accountId: inputs.id,
          password: inputs.pw,
        },
      });

      if (data.adminLogin.token) {
        userLogin(data.adminLogin.token);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }, [inputs]);

  return (
    <LoginPresenter
      inputs={inputs}
      onChangeLogin={onChangeLogin}
      LoginHaldle={LoginHaldle}
      isLoading={isLoading}
    />
  );
};

export default LoginContainer;
