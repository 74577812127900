import React, {useCallback, useContext, useState} from "react";
import styled from "styled-components";
import {PrjDetailContext} from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import {FaChevronRight} from "react-icons/fa";
import {checkListStatusChanger} from "../../../../../lib/ProjectUtils";
import {theme} from "../../../../../styles/theme";
import {useLazyQuery} from "@apollo/client";
import {SEE_ADMIN_PRJ_CHECK_ITEM_DETAIL} from "../../../../../graphql/Project/Project/ProjectDetail/query";
import {toast} from "react-toastify";
import CheckListItemHeader from "./CheckListItemHeader";
import CheckListDetailModal from "./CheckListDetailModal";

const Container = styled.div`
  height: 100%;
  padding: 20px;

  & > hr {
    height: 0.5px;
    background-color: black;
    border: 1px solid black;
  }
`;
const TextBox = styled.div`
  display: flex;
  margin: ${({margin}) => margin};
  cursor: pointer;
`;
const Text = styled.div`
  color: ${({theme, fontColor}) =>
          fontColor ? fontColor : theme.defaultFontColor};
  font-size: ${({fontSize}) => (fontSize ? fontSize : 18)}px;
  font-weight: ${({BOLD}) => BOLD && "bold"};
  margin: ${({margin}) => margin};
`;

const ScrollBox = styled.div`
  height: ${({minusHeight}) => `calc(100% - ${minusHeight}px)`};
  overflow-y: scroll;
`;
const RowBox = styled.div`
  display: flex;
  align-items: center;
  margin: ${({margin}) => margin};
  cursor: ${({POINTER}) => POINTER && "pointer"};
  justify-content: ${({justifyContent}) => justifyContent};
`;
const CheckImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 8px;
`;
const CommentBox = styled.div`
  width: 100%;
  padding-top: 2px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
`;
const WarningText = styled.span`
  color: ${({theme}) => theme.passwordCheckRedColor};
  margin-right: 8px;
`;

const CheckListDetail = () => {
    const {setSelectCheckList, checkListDetailData} =
        useContext(PrjDetailContext);

    const [detail, setDetail] = useState(null);
    const [modal, setModal] = useState(false);

    const [
        seeAdminPrjCheckItemDetail,
        {data: checkItemDetailData, loading, refetch},
    ] = useLazyQuery(SEE_ADMIN_PRJ_CHECK_ITEM_DETAIL);

    const onClickCheckItemDetail = useCallback(async (wscId) => {
        try {
            await seeAdminPrjCheckItemDetail({
                variables: {
                    wscId: wscId,
                },
            });
            setDetail(wscId);
        } catch (e) {
            toast.error(e.message);
        }
    }, []);

    console.info("checkListDetailData", checkListDetailData);

    const handleGoBack = useCallback(() => {
        if (detail === null) {
            setSelectCheckList(null);
        } else {
            setDetail(null);
        }
    }, [detail]);

    React.useEffect(() => {
        console.info(
            "checkItemDetailData",
            checkItemDetailData?.seeAdminPrjCheckItemDetail?.weeklyCheckItemList
        );
    }, [checkItemDetailData, checkListDetailData]);

    return (
        <Container>
            <CheckListItemHeader
                detail={detail}
                goBack={handleGoBack}
                weeklySafetyCategory={
                    detail &&
                    checkItemDetailData?.seeAdminPrjCheckItemDetail
                        ?.weeklySafetyCategoryInfo?.wsc_safetyCategory_kr
                }
                checkListDetailData={checkListDetailData}
            />
            <hr/>
            {detail === null && (
                <>
                    <ScrollBox minusHeight={280}>
                        {checkListDetailData?.weeklySafetyCategory.map((list) => (
                            <RowBox
                                key={list.wsc_id}
                                POINTER
                                margin="20px 0 40px 0"
                                onClick={() => onClickCheckItemDetail(list.wsc_id)}
                            >
                                <Text margin="0 auto 0 0" BOLD fontSize={18}>
                                    {list?.wsc_safetyCategory_kr} ({list?.wsc_complyRate}%)
                                </Text>
                                <RowBox>
                                    <Text
                                        fontSize={18}
                                        margin="0 11px 0 0"
                                        fontColor={
                                            list?.wsc_status === "complete"
                                                ? theme.lightTheme.projectDetailStateCompleteColor
                                                : list?.wsc_status === "inProgress"
                                                ? theme.lightTheme.approvalBorderColor
                                                : theme.lightTheme.defaultFontColor
                                        }
                                    >
                                        {checkListStatusChanger(list?.wsc_status)}
                                    </Text>
                                    <FaChevronRight/>
                                </RowBox>
                            </RowBox>
                        ))}
                    </ScrollBox>
                    <hr/>
                    <Text margin="20px 0 12px 0" fontSize={18}>
                        평가 사진 및 의견
                    </Text>

                    <CommentBox>
                        <Text>
                            {checkListDetailData?.weeklyCheckListInfo?.wcl_evaluation}
                        </Text>
                    </CommentBox>

                    <RowBox margin="12px 0 0 0" justifyContent='space-between'>
                        <div>
                            {checkListDetailData?.weeklyCheckListInfo?.wcl_img.map((img, index) => (
                                <CheckImage key={index} src={img?.wcli_url}/>
                            ))}
                        </div>
                        <TextBox
                            margin="0 0 0 auto"
                            onClick={() => setModal(true)}
                        >
                            <Text fontSize={18}>더보기</Text>
                        </TextBox>
                    </RowBox>
                    {modal && (
                        <CheckListDetailModal
                            checkListInfo={checkListDetailData}
                            handleClose={() => setModal(false)}
                        />
                    )}
                </>
            )}

            {detail !== null && checkItemDetailData && (
                <>
                    <ScrollBox minusHeight={150}>
                        {checkItemDetailData?.seeAdminPrjCheckItemDetail?.weeklyCheckItemList.map((list, index) => (
                            <RowBox
                                key={list.wci_id}
                                margin="20px 0 40px 0"
                                justifyContent="space-between"
                            >
                                <Text>
                                    {String.fromCharCode(index + 97)}. {list.wci_checkItem_kr}
                                </Text>
                                <Text>
                                    {list?.wci_repeatedViolation && (
                                        <WarningText>(반복위반)</WarningText>
                                    )}
                                    {list.wci_score === 999 ? "N/A" : list.wci_score} 점
                                </Text>
                            </RowBox>
                        ))}
                    </ScrollBox>
                    <hr/>
                </>
            )}
        </Container>
    );
};

export default CheckListDetail;
