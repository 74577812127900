import React, { useState, useCallback } from "react";
import styled from "styled-components";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";

const Text = styled.div`
  width: 100%;
  height: ${({ height }) => height}px;
  color: ${({ theme, GRAY }) =>
    GRAY ? `${theme.textFieldPlaceHolder}` : `${theme.defaultFontColor}`};
  margin-top: ${({ marginTop }) => marginTop}px;
  line-height: ${({ lineHeight }) => lineHeight && 24}px;
  overflow-y: ${({ scroll }) => scroll && "auto"};
  /* overflow-x: hidden; */
  white-space: normal;
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.mainColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnBox = styled.div`
  width: ${({ width }) => width}px;
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
`;

const TextArea = styled.textarea`
  width: 560px;
  height: 230px;
  line-height: 1.5;
  margin-top: 20px;
  font-size: 16px;
`;

const CodeDetailModal = ({ data, handleClose }) => {
  return (
    <StyledModal
      title="리스크 코드"
      width={640}
      height={580}
      CLOSEBTN
      handleClose={handleClose}
    >
      <RowBox>
        <ColumnBox width={220}>
          <Text GRAY>코드명</Text>
          <Text marginTop={24}>{data?.rc_name}</Text>
        </ColumnBox>
        <ColumnBox width={322}>
          <Text GRAY>제목</Text>
          <Text marginTop={24}>{data?.rc_title}</Text>
        </ColumnBox>
      </RowBox>
      <ColumnBox width={562}>
        <Text GRAY marginTop={44}>
          코드 내용
        </Text>
        <TextArea value={data?.rc_text} readOnly />
      </ColumnBox>
    </StyledModal>
  );
};

export default CodeDetailModal;
