import React from "react";
import styled from "styled-components";
import {theme} from "../../../../styles/theme";
import CircleChart from "../StatRenewal/CircleChart";
import Loader from "../../../share/Loader";
import CountUp from "react-countup";

const Wrapper = styled.div`
  min-width: 394px;
  height: 278px;
  flex: 1;
  margin-right: 20px;
  background-color: ${({theme}) => theme.whiteColor};

  &:last-child {
    margin: 0;
  }
`;
const Title = styled.div`
  height: 50px;
  padding: 20px 20px 12px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({theme}) => theme.lightGrayBorder};
`;
const Text = styled.span`
  font-size: ${({fontSize}) => fontSize ? fontSize : 16}px;
  font-weight: ${({fontWeight}) => fontWeight ? fontWeight : 500};
  color: ${({fontColor, theme}) => fontColor ? fontColor : theme.defaultFontColor};
`;
const Contents = styled.div`
  height: calc(100% - 50px);
  padding: 20px 20px 0;
`;
const BoldText = styled.div`
  height: 100%;
  font-size: 48px;
  font-weight: 600;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Table = styled.div`
  height: 100%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
`;
const FlexBox = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: ${({flexDirection}) => flexDirection ? flexDirection : 'row'};
  align-items: ${({alignItems}) => alignItems ? alignItems : 'center'};
  justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'space-between'};
`;
const ProgressBar = styled.div`
  width: 100%;
  height: 10px;
  position: relative;
  border-radius: 5px;
  background-color: ${({theme}) => theme.lightGrayBorder};
  margin-bottom: 20px;
`;
const ActiveBar = styled.div`
  width: ${({percent}) => percent}%;
  height: 100%;
  position: absolute;
  transition: ease-in-out .5s;
  border-radius: 5px;
  background-color: ${({theme}) => theme.projectStateIngColor};
`;
const WidthTextBox = styled.div`
  display: flex;
  align-items: center;
`;
const WidthText = styled.div`
  width: ${({ width }) => width ? width : 46}px;
`;
const CountUpText = styled(CountUp)`
  min-width: 130px;
  text-align: center;
`;

const TableBox = ({
                      SCAR_TOTAL_COUNT,
                      TABLE,
                      CIRCLE_CHART,
                      PROGRESS_BAR,
                      SIGNUP_CODE,
                      COLORS,
                      scarTotalCount = 0,
                      title,
                      statsText,
                      tableData = [],
                      projectCompleteRatioData,
                      authCodeData,
                      loading,
                  }) => {
    if (loading) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            <Title>
                <Text>{title}</Text>
                <Text>
                    {statsText}
                </Text>
            </Title>
            <Contents>
                {SCAR_TOTAL_COUNT && (
                    <BoldText>
                        <WidthText width={50}>총</WidthText>
                        <CountUpText
                            end={scarTotalCount}
                            separator=','
                        >
                            {scarTotalCount.toLocaleString()}
                        </CountUpText>
                        <WidthText width={50}>건</WidthText>
                    </BoldText>
                )}
                {TABLE && (
                    <Table>
                        {tableData.map((list, index) => (
                            <FlexBox key={`${index}-list`}>
                                {title === '프로젝트 생성 통계'
                                    ? (
                                        <>
                                            <WidthTextBox>
                                                <WidthText>{list.title}</WidthText> 년도
                                            </WidthTextBox>
                                            <Text fontWeight={600}>
                                                <Text fontColor={theme.lightTheme.darkRedColor}>
                                                    {list?.count?.toLocaleString()}&nbsp;
                                                </Text>
                                                건
                                            </Text>
                                        </>
                                    ) : (
                                        <>
                                            <Text>{list.title}</Text>
                                            <Text fontWeight={600}>
                                                <Text fontColor={theme.lightTheme.darkRedColor}>
                                                    {list?.count?.toLocaleString()}&nbsp;
                                                </Text>
                                                건
                                            </Text>
                                        </>
                                    )
                                }
                            </FlexBox>
                        ))}
                    </Table>
                )}
                {CIRCLE_CHART && (
                    <CircleChart
                        data={tableData}
                        COLORS={COLORS}
                    />
                )}
                {PROGRESS_BAR && (
                    <Table>
                        <FlexBox>
                            <Text>프로젝트 완료율</Text>
                            <Text fontWeight={600} fontColor={theme.lightTheme.projectStateIngColor}>
                                {projectCompleteRatioData?.completeRatio}% ({projectCompleteRatioData?.totalCount.toLocaleString()} 건)
                            </Text>
                        </FlexBox>
                        <ProgressBar>
                            <ActiveBar percent={projectCompleteRatioData?.completeRatio}/>
                        </ProgressBar>
                        <FlexBox>
                            <Text>전체 프로젝트 수</Text>
                            <Text fontWeight={600}>
                                <Text fontWeight={600} fontColor={theme.lightTheme.deepBlueColor}>
                                    {projectCompleteRatioData?.totalCount}
                                </Text> 건
                            </Text>
                        </FlexBox>
                        <FlexBox>
                            <Text>완료 / 종결 수</Text>
                            <Text fontWeight={600}>
                                <Text fontWeight={600} fontColor={theme.lightTheme.deepBlueColor}>
                                    {projectCompleteRatioData?.completeCount}
                                </Text> 건
                            </Text>
                        </FlexBox>
                        <FlexBox>
                            <Text>진행중</Text>
                            <Text fontWeight={600}>
                                <Text fontWeight={600} fontColor={theme.lightTheme.deepBlueColor}>
                                    {projectCompleteRatioData?.inProgressCount}
                                </Text> 건
                            </Text>
                        </FlexBox>
                    </Table>
                )}
                {SIGNUP_CODE && (
                    <Table>
                        <FlexBox>
                            <Text>PM본사</Text>
                            <WidthText width={110} fontWeight={600}>{authCodeData?.head}</WidthText>
                        </FlexBox>
                        <FlexBox>
                            <Text>PM현장</Text>
                            <WidthText width={110} fontWeight={600}>{authCodeData?.local}</WidthText>
                        </FlexBox>
                        <FlexBox>
                            <Text>시공사</Text>
                            <WidthText width={110} fontWeight={600}>{authCodeData?.builder}</WidthText>
                        </FlexBox>
                        <FlexBox>
                            <Text>발주처</Text>
                            <WidthText width={110} fontWeight={600}>{authCodeData?.owner}</WidthText>
                        </FlexBox>
                    </Table>
                )}
            </Contents>
        </Wrapper>
    )
}

export default React.memo(TableBox);
