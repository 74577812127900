import { lightTheme, darkTheme } from "../styles/theme";
import { useState, useContext, useCallback, createContext } from "react";
import { ThemeProvider as StyledProvider } from "styled-components";

export const ThemeContext = createContext({});

const ThemeProvider = ({ children }) => {
  const LocalTheme = window.localStorage.getItem("theme") || "light";
  const [ThemeMode, setThemeMode] = useState(LocalTheme);
  const themeObj = ThemeMode === "light" ? lightTheme : darkTheme;

  return (
    <ThemeContext.Provider value={{ ThemeMode, setThemeMode }}>
      <StyledProvider theme={themeObj}>{children}</StyledProvider>
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  const { ThemeMode, setThemeMode } = useContext(ThemeContext);

  const toggleTheme = useCallback(() => {
    if (ThemeMode === "light") {
      setThemeMode("dart");
      window.localStorage.setItem("theme", "dart");
    } else {
      setThemeMode("light");
      window.localStorage.setItem("theme", "light");
    }
  }, [ThemeMode]);

  return [ThemeMode, toggleTheme];
};

export { ThemeProvider, useTheme };
