import { gql } from "@apollo/client";

export const CLOSE_PROJECT = gql`
  mutation closeProject($prj_id: Int) {
    closeProject(prj_id: $prj_id) {
      tokenExpired
      result
    }
  }
`;

export const CREATE_PRJ_END_REQUEST_LIST_EXCEL = gql`
  mutation createPrjEndRequestListExcel(
    $searchTerm: String
    $sort: projectSort
  ) {
    createPrjEndRequestListExcel(searchTerm: $searchTerm, sort: $sort) {
      tokenExpired
      result
      endReqExcelUrl
    }
  }
`;
