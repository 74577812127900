export const lightTheme = {
  // General
  mainColor: "#05508E",
  whiteColor: "#fff",

  // Font
  defaultFontColor: "#000",
  guideLineFontColor: "#7B7B80",
  textFieldPlaceHolder: "#AAAAAA",
  logoutBtnColor: "#A6AFBD",
  graphFontColor: "#A6AFBD",
  tableLinkFontColor: "#999999",
  rowTableFontColor: "#7B7B80",
  profileTeamFontColor: "#666666",
  projectDetailGroupFontColor: "#339AF0",
  passwordCheckRedColor: "#ff0000",
  darkRedColor: '#BE282A',
  deepBlueColor: '#004B89',
  // Hover
  mainColorHover: "#115A96",
  // Bolder
  textFieldBorder: "#CCCCCC",
  graphBorderColor: "#E0E0E0",
  styledBorderColor: "#CACBD4",
  checkboxBorderColor: "#D1D1D1",
  projectDetailTableBolder: "#E6E6E6",
  approvalBorderColor: "#00C2F9",
  lightGrayBorder: '#EEEEEE',
  ultraLightGrayBorder: '#DDDDDD',
  // Background
  grayBackgroundColor: "#EFEFF3",
  defaultBackgroundColor: "#fff",
  projectDetailTableGrayColor: "#F2F2F2",
  projectDetailPiChartBgColor: "#D4E0F0",
  scrollBackgroundColor: "#999999",

  // state
  projectStateIngColor: "#00C0F3",
  projectStateExpectColor: "#ED9035",
  projectStateEndColor: "#999999",
  projectDetailStateIngColor: "#00C0F3",
  projectDetailStateCompleteColor: "#B5B5B5",
  projectDetailStateOAColorl: "#FF0026",
  projectDetailPiChartCompleteColor: "#B5B5B5",
  projectDetailPiChartIngColor: "#00C0F3",
  projectDetailPieChartDelayColor: "#FFC952",
  checkboxSelectColor: "#57EA78",
};

export const darkTheme = {
  // General
  mainColor: "#05508E",
  whiteColor: "#fff",
  // Font
  defaultFontColor: "#fff",
  guideLineFontColor: "#7B7B80",
  textFieldPlaceHolder: "#AAAAAA",
  logoutBtnColor: "#A6AFBD",
  graphFontColor: "#A6AFBD",
  tableLinkFontColor: "#999999",
  rowTableFontColor: "#7B7B80",
  profileTeamFontColor: "#666666",
  passwordCheckRedColor: "#ff0000",
  darkRedColor: '#BE282A',
  // Hover
  mainColorHover: "#115A96",
  // Bolder
  textFieldBorder: "#CCCCCC",
  graphBorderColor: "#E0E0E0",
  styledBorderColor: "#CACBD4",
  checkboxBorderColor: "#D1D1D1",
  projectDetailTableBolder: "#E6E6E6",
  lightGrayBorder: '#EEEEEE',
  // Background
  grayBackgroundColor: "#888888",
  defaultBackgroundColor: "#000",
  projectDetailTableGrayColor: "#F2F2F2",
  projectDetailPiChartBgColor: "#D4E0F0",
  // state
  projectStateIngColor: "#00C0F3",
  projectStateExpectColor: "#ED9035",
  projectStateEndColor: "#999999",
  projectDetailStateIngColor: "#00C0F3",
  projectDetailStateCompleteColor: "#B5B5B5",
  projectDetailStateOAColorl: "#FF0026",
  projectDetailPiChartCompleteColor: "#B5B5B5",
  projectDetailPiChartIngColor: "#00C0F3",
  projectDetailPieChartDelayColor: "#FFC952",
};

export const theme = {
  lightTheme,
  darkTheme,
};
