import React, {useContext, useState, useCallback, useEffect} from "react";
import StyledModal from "../../../../share/StyledModal";
import {PrjDetailContext} from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import styled from "styled-components";
import checkbox from "../../../../../assets/icon/checkbox.svg";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {SEE_CHECK_LIST_ITEM_SETTING} from "../../../../../graphql/Project/Project/ProjectDetail/query";
import {toast} from "react-toastify";
import {EDIT_CHECK_LIST_ITEM_SETTING} from "../../../../../graphql/Project/Project/ProjectDetail/mutation";

const Text = styled.div`
  font-weight: ${({BOLD}) => BOLD && "bold"};
  color: ${({GRAY, theme}) => GRAY && `${theme.rowTableFontColor}`};
  margin: ${({margin}) => margin};
`;

const CheckCard = styled.div`
  height: 94px;
  padding: 25px 0 0 0;
  position: relative;

  & > * + * {
    margin-top: 12px;
  }
`;

const ScrollBox = styled.div`
  height: 454px;
  border-top: 1px solid ${({theme}) => theme.defaultFontColor};
  border-bottom: 1px solid ${({theme}) => theme.defaultFontColor};
  margin: 26px 0 12px 0;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > ${CheckCard} + ${CheckCard} {
    border-top: 1px solid ${({theme}) => theme.projectDetailTableGrayColor};
  }
`;

const CheckBtn = styled.div`
  background-color: ${({theme, selected}) =>
          selected
                  ? `${theme.checkboxSelectColor}`
                  : `${theme.projectDetailStateCompleteColor}`};
  width: 20px;
  height: 20px;
  background-image: url(${checkbox});
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 50%;
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 10px;
  transition: 0.2s;
  cursor: pointer;
`;

const CheckListEditModal = () => {
    const {setCheckSetModal, radioIndex, setRadioIndex, checkListSettingRefetch} = useContext(PrjDetailContext);
    const [checkListItem, setCheckListItem] = useState([]);

    const [editCheckListItemSetting] = useMutation(EDIT_CHECK_LIST_ITEM_SETTING);
    const [seeCheckListItemSetting, { data, loading, refetch }] = useLazyQuery(SEE_CHECK_LIST_ITEM_SETTING);

    useEffect(() => {
        (async () => {
            if (radioIndex) {
                await seeCheckListItemSetting({
                    variables: {
                        prjScsId: radioIndex
                    },
                    fetchPolicy: 'network-only'
                });
            }
        })();
    }, [radioIndex]);

    const handleSaveCheckList = useCallback(async () => {
        try {
            const { data } = await editCheckListItemSetting({
                variables: {
                    cliInfo: checkListItem?.map(item => ({ prj_cli_id: item.prj_cli_id, prj_cli_used: item.prj_cli_used }))
                }
            });
            console.info('editCheckListItemSetting : ', data);
            if (data?.editCheckListItemSetting?.result) {
                await checkListSettingRefetch();
                toast.success('체크리스트를 수정했습니다.');;
                setCheckSetModal(false);
                setRadioIndex(null);
            }
        } catch(e) {
            toast.error(e.message);
        }
    },[checkListItem]);

    const handleCheckedItem = useCallback(cliId => {
        const checkedItem = checkListItem.map(item => item.prj_cli_id === cliId ? { ...item, prj_cli_used: !item.prj_cli_used } : item);
        setCheckListItem(checkedItem);
    }, [checkListItem]);

    useEffect(() => {
        if (!loading && data) {
            setCheckListItem(data?.seeCheckListItemSetting?.checkListItem);
        }
    }, [data, loading]);

    return (
        <StyledModal
            title="체크리스트 수정하기"
            width={557}
            height={780}
            btnLabel="등록"
            handleClose={() => setCheckSetModal(false)}
            handleSave={handleSaveCheckList}
        >
            <Text GRAY>안전범주명</Text>
            <Text BOLD margin="16px 0 0 0">
                {data?.seeCheckListItemSetting?.safetyCategoryNameKr}
            </Text>
            <ScrollBox>
                {checkListItem.map((item, index) => (
                    <CheckCard key={index}>
                        <Text BOLD>{item?.prj_cli_riskCode} | {item?.prj_cli_safetyCategory_kr}</Text>
                        <Text>
                            {item?.prj_cli_checkItem_kr}
                        </Text>
                        <CheckBtn
                            onClick={() => handleCheckedItem(item.prj_cli_id)}
                            selected={item.prj_cli_used}
                        />
                    </CheckCard>
                ))}
            </ScrollBox>
            <Text>
                총 {data?.seeCheckListItemSetting?.checkListItem?.length}개 중 {checkListItem?.filter(item => item.prj_cli_used).length}개 선택
            </Text>
        </StyledModal>
    );
};

export default CheckListEditModal;
