import React, { memo } from "react";
import styled from "styled-components";
import StyledCheckbox from "./StyledCheckbox";
import StyledRadioButton from "./StyledRadioButton";

const Container = styled.div`
  width: 100%;
  min-width: ${({ width }) => width}px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  margin-top: 18px;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  font-weight: 500;
  padding-top: 3px;
  color: ${({ theme }) => theme.rowTableFontColor};
  flex-shrink: 0;
`;

const StyledRowTable = ({
  width = 1645,
  CHECKBOX,
  RADIO,
  label = [],
  clicked,
  paddingLeft = 16,
  handleCheckbox = () => {},
}) => {
  return (
    <Container width={width} paddingLeft={paddingLeft}>
      {CHECKBOX && (
        <StyledCheckbox clicked={clicked} onClick={() => handleCheckbox(-1)} />
      )}
      {RADIO && <div style={{ width: "32px" }} />}
      {label.map(({ text, width }, index) => (
        <Text key={index} width={width}>
          {text}
        </Text>
      ))}
    </Container>
  );
};

export default memo(StyledRowTable);
