import { gql } from "@apollo/client";

export const SEE_NOTICE_LIST = gql`
  query seeNoticeList(
    $searchTerm: String
    $sort: noticeSort
    $take: Int
    $cursor: Int
  ) {
    seeNoticeList(
      searchTerm: $searchTerm
      sort: $sort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      totalLength
      noticeList {
        noti_id
        noti_createdAt
        noti_title
        noti_text
        noti_img
      }
    }
  }
`;
