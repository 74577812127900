import React, { memo } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import projectImage from "../../../../../assets/image/projectImage.jpeg";
import { dateFormat } from "../../../../../utils/common";
import { projectStatusChanger } from "../../../../../lib/ProjectUtils";
import { groupChanger } from "../../../../../lib/UserUtils";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.grayBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  padding-right: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-top: 3px;

  color: ${({ theme }) => theme.defaultFontColor};
`;

const Image = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  /* background-image: ${({ src }) => `url(${src})`};
  background-size: cover;
  background-position: 50%; */
  object-fit: cover;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 6px;
  border-radius: 50%;
  background-color: ${({ bgColor, theme }) =>
    bgColor === "진행중"
      ? `${theme.projectStateIngColor}`
      : bgColor === "종료"
      ? `${theme.projectStateEndColor}`
      : `${theme.projectStateExpectColor}`};
`;

const RowTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <Container>
      {data?.map((data, index) => (
        <RowBox
          key={index}
          onClick={() => {
            navigate("/project/projectList/detail");
            localStorage.setItem("prjId", data?.prj_id);
          }}
        >
          <Text width={72}>
            <Image src={data?.prj_img === "" ? projectImage : data?.prj_img} />
          </Text>
          <Text width={207}>{data?.prj_title}</Text>
          <StatusWrapper>
            <Dot bgColor={projectStatusChanger(data?.prj_status)} />
            <Text width={88}>{projectStatusChanger(data?.prj_status)}</Text>
          </StatusWrapper>
          <Text width={230}>
            {dateFormat(new Date(Number(data?.prj_startDate)), "yyyy.MM.dd")}~
            {dateFormat(new Date(Number(data?.prj_endDate)), "yyyy.MM.dd")}
          </Text>
          <Text width={328}>{data?.prj_address}</Text>
          <Text width={142}>{data?.prj_number}</Text>
          <Text width={107}>{data?.prj_memberCount} 명</Text>
          <Text width={163}>{data?.prj_scarCount} 건</Text>
          <Text width={220}>
            {groupChanger(data.prj_creatorGroup)} {data?.prj_creatorName}
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
