import React, { memo } from "react";
import styled from "styled-components";
import GuideLine from "../../../../components/layout/GuideLine";
import SortingForm from "../../../../components/layout/SortingForm";
import StyledRowTable from "../../../../components/share/StyledRowTable";
import PageNation from "../../../../components/layout/PageNation";
import RowTable from "../../../../components/feature/Project/Project/ProjectList/RowTable";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  width: 100%;
  min-width: 1694px;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const ProjectListPresenter = ({
  inputs,
  setInputs,
  handleReset,
  onChangeDate,
  handleExcel,
  page,
  totalLength,
  onClickPage,
  prjList,
}) => {
  return (
    <Container>
      <GuideLine label={["프로젝트 관리", "프로젝트 목록"]} />
      <ContentContainer>
        <SortingForm
          inputs={inputs}
          setInputs={setInputs}
          handleReset={handleReset}
          onChangeDate={onChangeDate}
          handleExcel={handleExcel}
          placeholder="프로젝트명, 생성자로 검색"
          SEARCH
          PRJSORT
          STATE
          DATE
          RESET
          EXCEL
        />
        <StyledRowTable
          label={[
            { text: "이미지", width: 72 },
            { text: "프로젝트 명", width: 207 },
            { text: "상태", width: 104 },
            { text: "프로젝트 기간", width: 230 },
            { text: "현장 주소", width: 328 },
            { text: "문서번호", width: 142 },
            { text: "구성원", width: 107 },
            { text: "SCAR 발행 건수", width: 163 },
            { text: "프로젝트 생성자", width: 220 },
          ]}
        />
        <RowTable data={prjList} />
        <PageNation
          page={page}
          onClickPage={onClickPage}
          totalLength={totalLength}
        />
      </ContentContainer>
    </Container>
  );
};

export default memo(ProjectListPresenter);
