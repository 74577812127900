import {gql} from "@apollo/client";

export const CREATE_CHECK_LIST_ITEM = gql`
    mutation createCheckListItem(
        $cliRiskCode: String!
        $cliSafetyCategoryKr: String!
        $cliSafetyCategoryEng: String!
        $cliCheckItemKr: String!
        $cliCheckItemEng: String!
    ) {
        createCheckListItem(
            cli_riskCode: $cliRiskCode
            cli_safetyCategory_kr: $cliSafetyCategoryKr
            cli_safetyCategory_eng: $cliSafetyCategoryEng
            cli_checkItem_kr: $cliCheckItemKr
            cli_checkItem_eng: $cliCheckItemEng
        ) {
            tokenExpired
            result
        }
    }
`;

export const DELETE_CHECK_LIST_ITEM = gql`
    mutation deleteCheckListItem($cliId: Int!) {
        deleteCheckListItem(cli_id: $cliId) {
            tokenExpired
            result
        }
    }
`;

export const EDIT_CHECK_LIST_ITEM = gql`
    mutation editCheckListItem(
        $cliId: Int!
        $cliRiskCode: String
        $cliSafetyCategoryKr: String
        $cliSafetyCategoryEng: String
        $cliCheckItemKr: String
        $cliCheckItemEng: String
    ) {
        editCheckListItem(
            cli_id: $cliId
            cli_riskCode: $cliRiskCode
            cli_safetyCategory_kr: $cliSafetyCategoryKr
            cli_safetyCategory_eng: $cliSafetyCategoryEng
            cli_checkItem_kr: $cliCheckItemKr
            cli_checkItem_eng: $cliCheckItemEng
        ) {
            tokenExpired
            result
        }
    }
`;