import { gql } from "@apollo/client";

export const SEE_ADMIN_LIST = gql`
  query seeAdminList($searchTerm: String, $take: Int, $cursor: Int) {
    seeAdminList(searchTerm: $searchTerm, take: $take, cursor: $cursor) {
      tokenExpired
      totalLength
      admin {
        admin_id
        admin_master
        admin_team
        admin_name
        admin_rank
        admin_accountId
        admin_password
        adminPermission {
          ap_id
          home
          user_manage
          project
          manage
        }
      }
    }
  }
`;
