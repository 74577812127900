import React, {useEffect} from "react";
import styled, {css} from "styled-components";
import {
    riskGradeChanger,
    scarStatusChanger,
} from "../../../../../lib/ProjectUtils";
import {groupChanger} from "../../../../../lib/UserUtils";
import {dateFormat} from "../../../../../utils/common";

import StyledSwiper from "../../../../share/StyledSwiper";
import ApprovalLine from "./ApprovalLine";
import StyledInput from "../../../../share/StyledInput";
import StyledModal from "../../../../share/StyledModal";
import {useState} from "react";
import {useCallback} from "react";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  column-gap: 50px;
  grid-template-columns: 1fr 310px;
  grid-template-rows: 1fr 1fr;
`;

const RowBox = styled.div`
  grid-column: 1 / 2;
  width: 100%;
  display: flex;

  &:first-child {
    border-bottom: 1px solid ${({theme}) => theme.textFieldBorder};
    /* height: 359px; */
  }
`;

const InfoBox = styled.div`
  width: 100%;
  height: ${({height}) => (height ? height : 290)}px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 26px;
  background-color: red;
`;

const Text = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  font-weight: ${({BOLD}) => BOLD && "bold"};
  margin-bottom: ${({BOLD}) => BOLD && 26}px;
  color: ${({theme, GRAY}) => GRAY && theme.textFieldPlaceHolder};

  ${({absolute}) =>
          absolute &&
          css`
            position: absolute;
            top: -45.5px;
          `}
  & > span::after {
    content: ", ";
    margin-right: 4px;
  }

  & > span:last-child::after {
    content: "";
    margin: 0;
  }
`;
const CommentBox = styled.div`
  line-height: 1.4;
`;
const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const CheckListDetailModal = ({checkListInfo, handleClose}) => {
    const [inputs, setInputs] = useState({
        comment: "",
    });
    const onChangeInputs = useCallback((e) => {
        const {name, value} = e.target;
        const maxLength = e.target.maxLength;
        const truncatedValue = value.slice(0, maxLength);

        setInputs({...inputs, [name]: truncatedValue});
    }, [inputs]);

    console.info('checkListInfo', checkListInfo?.weeklyCheckListInfo?.wcl_evaluation)

    return (
        <Container>
            <StyledModal
                title="평가사진 및 의견"
                width={900}
                height={550}
                CLOSEBTN
                handleClose={handleClose}
            >
                <RowBox>
                    <StyledSwiper
                        imageList={checkListInfo?.weeklyCheckListInfo?.wcl_img?.map(
                            (img) => img.wcli_url
                        )}
                    />

                    <TextBox>
                        <Text BOLD>평가의견</Text>
                        <CommentBox>
                            {checkListInfo?.weeklyCheckListInfo?.wcl_evaluation}
                        </CommentBox>
                    </TextBox>
                </RowBox>
            </StyledModal>
        </Container>
    );
};

export default CheckListDetailModal;
