import React, {useCallback, useEffect, useState} from "react";
import TabMenu from "./TabMenu";
import styled from "styled-components";
// import SideBarLogo from "../../../assets/icon/SideBarLogo.svg";
import SideBarLogo from "../../../assets/icon/SideBarLogo.png";
import {useNavigate, useLocation} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {SEE_ADMIN_PERMISSION} from "../../../graphql/Login/query";

const Container = styled.div`
  width: 226px;
  height: 100vh;
  position: sticky;
  top: 0;
  padding: 33px 30px;
  flex-shrink: 0;
  background-color: ${({theme}) => theme.mainColor};
`;

const LogoImage = styled.img`
  width: 157px;
  margin-bottom: 102px;
  padding-right: 10px;

  cursor: pointer;
`;

const SideBar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState("/home");

    const {
        data: permissionData,
        loading: permissionLoading,
        refetch: permissionRefetch,
    } = useQuery(SEE_ADMIN_PERMISSION);

    // 권한
    const [permission, setPermission] = useState({
        home: true,
        manage: true,
        project: true,
        user_manage: true,
        master: true,
    });

    const onClickTabMenu = useCallback((permission, url) => {
        if (permission) navigate(url);
        else toast.error("탭 접근 권한이 없습니다.");
    }, []);

    useEffect(() => {
        setCurrentPath(location.pathname);
    }, [location]);

    useEffect(() => {
        if (permissionData?.seeAdminPermission?.result) {
            setPermission({
                home: permissionData?.seeAdminPermission?.home,
                manage: permissionData?.seeAdminPermission?.manage,
                project: permissionData?.seeAdminPermission?.project,
                user_manage: permissionData?.seeAdminPermission?.user_manage,
                master: permissionData?.seeAdminPermission?.master,
            });
        }
    }, [permissionData]);

    return (
        <Container>
            <LogoImage
                src={SideBarLogo}
                onClick={() => onClickTabMenu(permission.home, "/home")}
            />

            {/* 탭버튼 */}
            <TabMenu
                TAB
                label="홈"
                active={currentPath === "/home" || currentPath === "/"}
                onClick={() => onClickTabMenu(permission.home, "/home")}
            />
            {(currentPath.split("/")[1] === "" ||
                currentPath.split("/")[1] === "home") && (
                <TabMenu
                    label="통계"
                    active={
                        currentPath.split("/")[1] === "home" ||
                        currentPath.split("/")[1] === ""
                    }
                    onClick={() => onClickTabMenu(permission.home, "/home")}
                />
            )}

            <TabMenu
                TAB
                label="프로젝트관리"
                active={currentPath.split("/")[1] === "project"}
                onClick={() =>
                    onClickTabMenu(permission.project, "/project/projectList")
                }
            />
            {currentPath.split("/")[1] === "project" && (
                <>
                    <TabMenu
                        label="프로젝트 목록"
                        active={
                            currentPath === "/project/projectList" ||
                            currentPath === "/project/projectList/detail"
                        }
                        onClick={() =>
                            onClickTabMenu(permission.project, "/project/projectList")
                        }
                    />
                    <TabMenu
                        label="종료 요청 목록"
                        active={currentPath === "/project/endList"}
                        onClick={() =>
                            onClickTabMenu(permission.project, "/project/endList")
                        }
                    />
                </>
            )}

            <TabMenu
                TAB
                label="유저관리"
                active={currentPath.split("/")[1] === "user"}
                onClick={() => onClickTabMenu(permission.user_manage, "/user/userList")}
            />
            {currentPath.split("/")[1] === "user" && (
                <>
                    <TabMenu
                        label="유저 목록"
                        active={
                            currentPath === "/user/userList" ||
                            currentPath === "/user/userList/detail"
                        }
                        onClick={() =>
                            onClickTabMenu(permission.user_manage, "/user/userList")
                        }
                    />
                    <TabMenu
                        label="승인 요청 목록"
                        active={currentPath === "/user/approvalList"}
                        onClick={() =>
                            onClickTabMenu(permission.user_manage, "/user/approvalList")
                        }
                    />
                </>
            )}
            <TabMenu
                TAB
                label="운영관리"
                active={currentPath.split("/")[1] === "management"}
                onClick={() =>
                    onClickTabMenu(permission.manage, "/management/category")
                }
            />
            {currentPath.split("/")[1] === "management" && (
                <>
                    <TabMenu
                        label="카테고리 설정"
                        active={currentPath === "/management/category"}
                        onClick={() =>
                            onClickTabMenu(permission.manage, "/management/category")
                        }
                    />
                    <TabMenu
                        label="체크리스트 관리"
                        active={currentPath === "/management/checkList"}
                        onClick={() =>
                            onClickTabMenu(permission.manage, "/management/checkList")
                        }
                    />
                    <TabMenu
                        label="리스크 코드 관리"
                        active={currentPath === "/management/riskCode"}
                        onClick={() =>
                            onClickTabMenu(permission.manage, "/management/riskCode")
                        }
                    />
                    <TabMenu
                        label="일대일 문의"
                        active={currentPath === "/management/qna"}
                        onClick={() => onClickTabMenu(permission.manage, "/management/qna")}
                    />
                    <TabMenu
                        label="자주 묻는 질문"
                        active={currentPath === "/management/faq"}
                        onClick={() => onClickTabMenu(permission.manage, "/management/faq")}
                    />
                    <TabMenu
                        label="공지사항"
                        active={currentPath === "/management/notice"}
                        onClick={() =>
                            onClickTabMenu(permission.manage, "/management/notice")
                        }
                    />
                    <TabMenu
                        label="고객센터"
                        active={currentPath === "/management/serviceCenter"}
                        onClick={() =>
                            onClickTabMenu(permission.manage, "/management/serviceCenter")
                        }
                    />
                </>
            )}
            <TabMenu
                TAB
                label="사용자관리"
                active={currentPath.split("/")[1] === "admin"}
                onClick={() => onClickTabMenu(permission.master, "/admin/adminList")}
            />
            {currentPath.split("/")[1] === "admin" && (
                <TabMenu
                    label="사용자목록"
                    active={currentPath === "/admin/adminList"}
                    onClick={() => onClickTabMenu(permission.master, "/admin/adminList")}
                />
            )}
        </Container>
    );
};

export default SideBar;
