import React from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import refreshIcon from "../../../assets/icon/refreshIcon.svg";
import TableBox from "../../../components/feature/Home/StatRenewal/TableBox";
import LargeTableBox from "../../../components/feature/Home/StatRenewal/LargeTableBox";

const Wrapper = styled.div`
  min-width: 1694px;
  width: 100%;
  min-height: 1080px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;
const Container = styled.div`
  max-width: 1920px;
  padding: 26px 32px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const FlexBox = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : 0)};
`;
const RefreshButton = styled.button`
  width: 120px;
  height: 36px;
  font-size: 16px;
  color: ${({ theme }) => theme.whiteColor};
  margin-right: 20px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.textFieldPlaceHolder};

  &:active {
    opacity: 0.7;
  }
`;
const Icon = styled.img`
  margin-left: 10px;
`;
const GrayText = styled.span`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
`;
const Line = styled.div`
  height: 1px;
  margin: 20px 0;
  background-color: ${({ theme }) => theme.ultraLightGrayBorder};
`;

const StatPresenterRenewal = ({
  dDay,
  refreshTime,
  handleAllRefresh,

  allScarTotalCount = 0,
  scarTotalLoading,

  scarCount,
  scarTotalCount = 0,
  scarCountLoading,

  checkListCount,
  checkListInProgressCount = 0,
  checkListCountLoading,

  userStats,
  userLoading,

  scarProgressCount,
  scarProgressTotalCount = 0,
  scarProgressCountLoading,

  scarYearCount,
  scarYearLoading,

  projectScarData,
  projectScarLoading,

  projectStats,
  yearProjectLoading,

  projectCompleteRatioData,
  projectCompleteRatioLoading,

  csData,
  csLoading,

  authCodeData,
  authCodeLoading,
}) => {
  return (
    <Wrapper>
      <GuideLine label={["홈", "통계"]} />
      <Container>
        <Header>
          <FlexBox>
            <RefreshButton onClick={handleAllRefresh}>
              전체 새로고침
            </RefreshButton>
            {refreshTime !== "" && (
              <>
                <GrayText>최근 {refreshTime}</GrayText>
                <Icon src={refreshIcon} />
              </>
            )}
          </FlexBox>
          <GrayText>종료 기한 : {`2024. 3. 1 (D-${dDay.current})`}</GrayText>
        </Header>

        <FlexBox>
          <TableBox
            TABLE
            title="전체 SCAR 발행 건수"
            statsText={`총 ${scarTotalCount?.toLocaleString()} 건`}
            tableData={scarCount}
            loading={scarCountLoading}
          />
          <TableBox
            CIRCLE_CHART
            title="금주 체크리스트 진행율"
            statsText={`진행중 ${checkListInProgressCount} 건`}
            tableData={checkListCount}
            COLORS={["#008AF9", "#FF804B", "#AAAAAA"]}
            loading={checkListCountLoading}
          />
          <TableBox
            CIRCLE_CHART
            title="사용자 통계"
            statsText={`총 ${358} 명`}
            tableData={userStats}
            loading={userLoading}
          />
          <TableBox
            CIRCLE_CHART
            title="SCAR 완료율"
            statsText={`완료 ${scarProgressTotalCount} 건`}
            tableData={scarProgressCount}
            COLORS={["#008AF9", "#FF804B", "#AAAAAA"]}
            loading={scarProgressCountLoading}
          />
        </FlexBox>

        <Line />

        <FlexBox>
          <LargeTableBox
            CHART
            title="SCAR 발행 추이"
            data={scarYearCount}
            loading={scarYearLoading}
          />
          <LargeTableBox
            TABLE
            title="프로젝트별 SCAR 발행 건수"
            subTitle="TOP 10"
            data={projectScarData}
            loading={projectScarLoading}
          />
        </FlexBox>

        <Line />

        <FlexBox>
          <TableBox
            TABLE
            title="프로젝트 생성 통계"
            tableData={projectStats}
            loading={yearProjectLoading}
          />
          <TableBox
            PROGRESS_BAR
            title="프로젝트 총 완료율"
            projectCompleteRatioData={projectCompleteRatioData}
            loading={projectCompleteRatioLoading}
          />
          <TableBox
            TABLE
            title="CS 업무"
            tableData={[
              {
                title: "미처리 가입 승인 건수",
                count: csData?.unProcessSignupCount,
              },
              { title: "일대일 문의 건수", count: csData?.oneOnOneCount },
            ]}
            loading={csLoading}
          />
          <TableBox
            SIGNUP_CODE
            title="가입 코드"
            authCodeData={authCodeData}
            loading={authCodeLoading}
          />
        </FlexBox>
      </Container>
    </Wrapper>
  );
};

export default React.memo(StatPresenterRenewal);
