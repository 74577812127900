import React, { useState, useCallback, createContext, useContext } from "react";
import AdminListPresenter from "./AdminListPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_ADMIN_LIST } from "../../../graphql/Admin/query";
import { DELETE_ADMIN } from "../../../graphql/Admin/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

export const AdminContext = createContext();

const AdminListContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [radioIndex, setRadioIndex] = useState(null);
  const [adminId, setAdminId] = useState(null);
  const [addAdminModal, setAddAdminModal] = useState(false);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [adminList, setAdminList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
  });

  const [deleteAdmin] = useMutation(DELETE_ADMIN);

  const {
    data: adminListData,
    loading,
    refetch: adminListRefetch,
  } = useQuery(SEE_ADMIN_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      take: 16,
      cursor: cursor,
    },
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
    });
  }, [inputs]);

  const handlePlus = useCallback(() => {
    setAddAdminModal(true);
  }, []);

  const handleMinus = useCallback(async () => {
    if (radioIndex === null) {
      window.alert("삭제할 사원을 선택해주세요.");
      return;
    }
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        const { data } = await deleteAdmin({
          variables: {
            admin_id: adminId,
          },
        });
        if (data?.deleteAdmin?.result) {
          await adminListRefetch();
          toast.success("사용자를 삭제하였습니다.");
        }
      } catch (e) {
        toast.error(e.message);
      } finally {
        setAdminId(null);
        setRadioIndex(null);
      }
    }
  }, [radioIndex]);

  const handleAddModalClose = useCallback(() => {
    setAddAdminModal(!addAdminModal);
  }, [addAdminModal]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  const onClickRadio = useCallback(
    (index, id) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        setAdminId(null);
        return;
      }
      setRadioIndex(index);
      setAdminId(id);
    },
    [radioIndex, adminId]
  );

  React.useEffect(() => {
    if (adminListData?.seeAdminList?.tokenExpired) {
      setTotalLength(adminListData.seeAdminList.totalLength);
      setAdminList(adminListData.seeAdminList.admin);
    }
    if (adminListData && !adminListData?.seeAdminList?.tokenExpired) {
      userLogout();
    }
  }, [adminListData]);

  return (
    <AdminContext.Provider
      value={{
        inputs,
        setInputs,
        handleReset,
        handlePlus,
        handleMinus,
        page,
        totalLength,
        onClickPage,
        addAdminModal,
        setAddAdminModal,
        radioIndex,
        onClickRadio,
        adminList,
        adminListRefetch,
        handleAddModalClose,
        setAdminId,
      }}
    >
      <AdminListPresenter />
    </AdminContext.Provider>
  );
};

export default AdminListContainer;
