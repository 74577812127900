import React, { useState, useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import StyledButton from "../../../share/StyledButton";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";
import { useMutation } from "@apollo/client";
import { CREATE_NOTICE } from "../../../../graphql/Management/Notice/mutatioin";
import { toast } from "react-toastify";
import { NoticeContext } from "../../../../routes/Management/Notice/NoticeContainer";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
  position: relative;
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

const InputBox = styled.textarea`
  width: 520px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const FileUpLoadCover = styled.div`
  white-space: nowrap;
  width: 240px;
  height: 46px;
  padding: 14px 16px;
  margin-top: 11px;
  margin-right: 16px;
  border-radius: 8px;
  color: ${({ GRAY, theme }) => GRAY && `${theme.textFieldPlaceHolder}`};
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  overflow-x: auto;
`;

const FileUpLoad = styled.input`
  display: none;
`;

const NoticeAddModal = ({ handleClose }) => {
  const { userLogout } = useContext(AuthContext);
  const { noticeRefetch } = useContext(NoticeContext);

  const [inputs, setInputs] = useState({
    title: "",
    contents: "",
    file: "",
  });

  const [createNotice] = useMutation(CREATE_NOTICE);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const onClickUpload = (e) => {
    e.preventDefault();
    const fileUpload = document.getElementById("fileUpload");
    fileUpload.click();
  };

  const onChangeFile = (e) => {
    const files = e.target.files[0];

    if (
      !["jpeg", "jpg", "png"].includes(
        String(files.name).split(".").reverse()[0]
      )
    ) {
      toast.error("이미지만 업로드 가능합니다.");
      return;
    }

    if (files.size / 1024 / 1024 > 5) {
      toast.error("이미지 파일은 5MB 이하로 업로드해주세요.");
      return;
    }

    setInputs({ ...inputs, file: files });
  };

  const handleSave = useCallback(async () => {
    if (inputs.title.trim() === "") {
      toast.error("제목을 입력해주세요.");
      return;
    }
    if (inputs.contents.trim() === "") {
      toast.error("내용을 입력해주세요.");
    }

    try {
      let variables;
      if (inputs.file === "") {
        variables = {
          noti_title: inputs.title,
          noti_text: inputs.contents,
          noti_imgSelect: false,
        };
      } else {
        variables = {
          noti_title: inputs.title,
          noti_text: inputs.contents,
          noti_imgSelect: true,
          noti_img: inputs.file,
        };
      }
      const { data } = await createNotice({
        variables: variables,
      });
      if (data?.createNotice?.result) {
        toast.success("공지를 등록하였습니다.");
        await noticeRefetch();
      }
      if (data?.createNotice && !data?.createNotice?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      handleClose();
    }
  }, [inputs]);

  return (
    <StyledModal
      title="공지 등록하기"
      width={600}
      height={698}
      btnLabel="등록"
      handleClose={handleClose}
      handleSave={handleSave}
    >
      <StyledInput
        name="title"
        label="제목"
        width={520}
        placeholder="공지 제목을 입력하세요"
        value={inputs.title}
        onChange={onChangeInputs}
      />

      <Text marginTop={26}>
        내용
        <Text absolute>({inputs.contents.length}/200)</Text>
      </Text>
      <InputBox
        name="contents"
        height={247}
        placeholder="공지 내용을 입력하세요"
        value={inputs.contents}
        onChange={onChangeInputs}
        maxLength={200}
      />
      <Text marginTop={26}>첨부파일</Text>
      <RowBox>
        <FileUpLoadCover GRAY={inputs.file === ""}>
          {inputs.file === "" ? "첨부파일을 업로드해주세요" : inputs.file.name}
        </FileUpLoadCover>
        <FileUpLoad
          id="fileUpload"
          name="file"
          type="file"
          accept=".jpeg, .jpg, .png"
          onChange={onChangeFile}
        />
        <StyledButton
          BLACK
          label="파일첨부"
          onClick={(e) => onClickUpload(e)}
        />
      </RowBox>
    </StyledModal>
  );
};

export default NoticeAddModal;
