export const userSortChanger = (value) => {
  switch (value) {
    case "최신순":
      return "desc";
    case "오래된순":
      return "asc";
    case "이름순":
      return "name";
    case "회사명순":
      return "company";
    case "프로젝트명":
      return "title";
    case "현장주소":
      return "address";
  }
};

export const groupChanger = (value) => {
  switch (value) {
    case "전체":
      return "total";
    case "PM본사":
      return "head";
    case "PM현장":
      return "local";
    case "시공사":
      return "builder";
    case "발주처":
      return "owner";
    case "head":
      return "PM본사";
    case "pm본사":
      return "PM본사";
    case "local":
      return "PM현장";
    case "builder":
      return "시공사";
    case "owner":
      return "발주처";
  }
};

export const userStopChanger = (value) => {
  switch (value) {
    case "전체":
      return "total";
    case "이용중":
      return "false";
    case "정지":
      return "true";
  }
};

export const authChanger = (value) => {
  switch (value) {
    case "전체":
      return "total";
    case "승인":
      return "true";
    case "미승인":
      return "false";
  }
};
