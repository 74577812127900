import { gql } from "@apollo/client";

export const DELETE_TOS = gql`
  mutation deleteTOS($tos_id: Int) {
    deleteTOS(tos_id: $tos_id) {
      tokenExpired
      result
    }
  }
`;

export const CREATE_VOC_NUMBER = gql`
  mutation createVOCNumber($vn_callNumber: String) {
    createVOCNumber(vn_callNumber: $vn_callNumber) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_VOC_NUMBER = gql`
  mutation deleteVOCNumber($vn_id: Int) {
    deleteVOCNumber(vn_id: $vn_id) {
      tokenExpired
      result
    }
  }
`;

export const CREATE_TOS = gql`
  mutation createTOS($tos_text: String) {
    createTOS(tos_text: $tos_text) {
      tokenExpired
      result
    }
  }
`;
