import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export default createGlobalStyle`
    ${reset};
    * {
      box-sizing: border-box;
      scrollbar-width: none;
      -ms-overflow-style: none;

      *::-webkit-scrollbar {
        display: none;
      }
    }
    
    body {
      width: 100vw;
      font-size: 16px;
      font-family: 'AppleSD Medium', 'Apple SD Gothic Neo', "AppleGothic", sans-serif;
      box-sizing: border-box;
      scrollbar-width: none;
      -ms-overflow-style: none;
      background-color: ${({ theme }) => theme.defaultBackgroundColor} ;
      color:${({ theme }) => theme.defaultFontColor};

    }
    a {
      text-decoration: none;
    }
    textarea {
      border: none;
      resize: none;
    }
    input {
      height: 100%;
      border: none;
    }
    textarea:focus,
    input:focus{
      outline: none;
      border: none;
    }
    input::placeholder,
    textarea::placeholder {
      font-size: 16px;
    }
    button {
      border: none;
      outline: none;
      cursor: pointer;
    }
    select {
      outline: none;
      cursor: pointer;
      -webkit-appearance: none; /* 화살표 없애기 for chrome*/
      -moz-appearance: none; /* 화살표 없애기 for firefox*/
      appearance: none; /* 화살표 없애기 공통*/
    }
`;
