import React, { useState, useCallback, useEffect, useContext } from "react";
import RequestPresenter from "./EndPresenter";
import { useMutation, useQuery } from "@apollo/client";
import { SEE_PRJ_END_REQUEST_LIST } from "../../../graphql/Project/EndList/query";
import { CREATE_PRJ_END_REQUEST_LIST_EXCEL } from "../../../graphql/Project/EndList/mutation";
import { projectSortChanger } from "../../../lib/ProjectUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

const EndContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [prjList, setPrjList] = useState([]);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신순",
  });

  const {
    data: endListData,
    loading: endListLoading,
    refetch: endListRefetch,
  } = useQuery(SEE_PRJ_END_REQUEST_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: projectSortChanger(inputs.sort),
      take: 16,
      cursor: cursor,
    },
  });

  const [createPrjEndRequestListExcel] = useMutation(
    CREATE_PRJ_END_REQUEST_LIST_EXCEL
  );

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "최신순",
    });
  }, [inputs]);

  const handleExcel = useCallback(async () => {
    try {
      const { data } = await createPrjEndRequestListExcel({
        variables: {
          searchTerm: inputs.searchTerm,
          sort: projectSortChanger(inputs.sort),
        },
      });
      if (data?.createPrjEndRequestListExcel?.result) {
        window.open(data.createPrjEndRequestListExcel.endReqExcelUrl);
        toast.success("엑셀 파일을 다운로드 하였습니다.");
      }
      if (
        data?.createPrjEndRequestListExcel &&
        !data?.createPrjEndRequestListExcel?.tokenExpired
      ) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  useEffect(() => {
    if (endListData?.seePrjEndRequestList?.result) {
      setPrjList(endListData.seePrjEndRequestList.projectList);
      setTotalLength(endListData.seePrjEndRequestList.totalLength);
    }
    if (
      endListData?.seePrjEndRequestList &&
      !endListData?.seePrjEndRequestList?.tokenExpired
    ) {
      userLogout();
    }
  }, [endListData]);

  return (
    <RequestPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleReset={handleReset}
      handleExcel={handleExcel}
      page={page}
      totalLength={totalLength}
      onClickPage={onClickPage}
      prjList={prjList}
      endListRefetch={endListRefetch}
    />
  );
};

export default EndContainer;
