import { gql } from "@apollo/client";

export const SEE_USER_DETAIL_INFO = gql`
  query seeUserDetailInfo($user_id: Int) {
    seeUserDetailInfo(user_id: $user_id) {
      tokenExpired
      result
      userInfo {
        user_id
        user_createdAt
        user_name
        user_rank
        user_countryCode
        user_cellphone
        user_email
        user_emailAuth
        user_group
        user_groupCompany
        user_profileImg
        user_tosAgree
        user_tosAgreeDate
        user_privacyPolicyAgree
        user_privacyPolicyAgreeDate
        user_language
        user_headAuth
        user_stop
        user_pushNoti
        user_msgNoti
        user_emailNoti
        user_kakaoNoti
        user_withdrawal
        user_withdrawalDate
        notification {
          notifi_id
          notifi_createdAt
          notifi_content
        }
      }
    }
  }
`;

export const SEE_USER_PRJ_LIST = gql`
  query seeUserPrjList(
    $userId: Int
    $searchTerm: String
    $sort: projectSort
    $take: Int
    $cursor: Int
  ) {
    seeUserPrjList(
      user_id: $userId
      searchTerm: $searchTerm
      sort: $sort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      result
      totalLength
      userProjectList {
        prj_id
        prj_img
        prj_title
        prj_status
        prj_startDate
        prj_endDate
        prj_address
        prj_number
        prj_creatorInfo
        prj_memberCount
        prj_scarCount
      }
    }
  }
`;
