import React, { useState, useCallback, useEffect, useContext } from "react";
import CategoryPresenter from "./CategoryPresenter";
import {
  SEE_LABEL_LIST,
  SEE_SAFETY_CATEGORY,
} from "../../../graphql/Management/Category/query";
import {
  CREATE_LABEL,
  DELETE_LABEL,
  CREATE_SAFETY_CATEGORY,
  DELETE_SAFETY_CATEGORY,
} from "../../../graphql/Management/Category/mutation";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AuthContext } from "../../../context/AuthProvider";

const CategoryContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [labelInput, setLabelInput] = useState({
    kor: "",
    eng: "",
  });
  const [safetyCategory, setSafetyCategory] = useState({
    kor: "",
    eng: "",
  });
  const [labelList, setLabelList] = useState([
    { lb_name: "추락", lb_name_eng: "" },
    { lb_name: "낙하물", lb_name_eng: "" },
    { lb_name: "화재", lb_name_eng: "" },
  ]);

  const {
    data: categoryData,
    loading: categoryLoading,
    refetch: categoryRefetch,
  } = useQuery(SEE_LABEL_LIST, {
    variables: {
      searchTerm: "",
    },
  });
  const {
    data: safetyData,
    loading: safetyLoading,
    refetch: safetyRefetch,
  } = useQuery(SEE_SAFETY_CATEGORY, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    console.info(
      "safetyData",
      safetyData?.seeSafetyCategory?.safetyCategoryList
    );
  }, [safetyData]);

  const [createLabel] = useMutation(CREATE_LABEL);
  const [deleteLabel] = useMutation(DELETE_LABEL);
  const [createSafetyCategory] = useMutation(CREATE_SAFETY_CATEGORY);
  const [deleteSafetyCategory] = useMutation(DELETE_SAFETY_CATEGORY);

  const onClickMinus = useCallback(async (id) => {
    if (!window.confirm("라벨을 삭제하시겠습니까?")) return;
    try {
      const { data } = await deleteLabel({
        variables: {
          lb_id: id,
        },
      });
      if (data?.deleteLabel?.result) {
        toast.success("라벨을 삭제하였습니다.");
        await categoryRefetch();
      }
      if (data?.deleteLabel && !data?.deleteLabel?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  const onClickAddLabel = useCallback(async () => {
    if (labelInput.kor.trim() === "" || labelInput.eng.trim() === "") {
      toast.error("라벨을 입력해주세요.");
      return;
    }
    try {
      const { data } = await createLabel({
        variables: {
          labelName: labelInput.kor,
          labelNameEng: labelInput.eng,
        },
      });
      if (data?.createLabel?.result) {
        toast.success("라벨을 생성하였습니다.");
        await categoryRefetch();
      }
      if (data?.createLabel && !data?.createLabel?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLabelInput({
        kor: "",
        eng: "",
      });
    }
  }, [labelInput]);

  const handleCreateSafetyCategory = useCallback(async () => {
    if (safetyCategory.kor.trim() === "" || safetyCategory.eng.trim() === "") {
      toast.error("안전범주를 입력해주세요.");
      return;
    }
    try {
      const { data } = await createSafetyCategory({
        variables: {
          scNameKr: safetyCategory.kor,
          scNameEng: safetyCategory.eng,
        },
      });

      if (data.createSafetyCategory?.result) {
        toast.success("안전범주를 생성하였습니다.");
        await safetyRefetch();
        setSafetyCategory({
          kor: "",
          eng: "",
        });
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [safetyCategory]);

  const handleDeleteSafetyCategory = useCallback(async (scId) => {
    if (!window.confirm("안전범주를 삭제하시겠습니까?")) return;
    try {
      const { data } = await deleteSafetyCategory({
        variables: {
          scId: scId,
        },
      });
      console.info(data);
      if (data?.deleteSafetyCategory.result) {
        toast.success("안전범주를 삭제하였습니다.");
        await safetyRefetch();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, []);

  useEffect(() => {
    if (categoryData?.seeLabelList?.tokenExpired) {
      setLabelList(categoryData.seeLabelList.labelList);
    }
    if (
      categoryData?.seeLabelList &&
      !categoryData?.seeLabelList?.tokenExpired
    ) {
      userLogout();
    }
  }, [categoryData]);

  return (
    <CategoryPresenter
      labelInput={labelInput}
      setLabelInput={setLabelInput}
      safetyCategory={safetyCategory}
      setSafetyCategory={setSafetyCategory}
      labelList={labelList}
      onClickAddLabel={onClickAddLabel}
      onClickMinus={onClickMinus}
      handleCreateSafetyCategory={handleCreateSafetyCategory}
      handleDeleteSafetyCategory={handleDeleteSafetyCategory}
      safetyList={safetyData?.seeSafetyCategory?.safetyCategoryList}
    />
  );
};

export default CategoryContainer;
