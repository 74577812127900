import { gql } from "@apollo/client";

export const SEE_TOS_LIST = gql`
  query seeTOSList($take: Int, $cursor: Int) {
    seeTOSList(take: $take, cursor: $cursor) {
      tokenExpired
      totalLength
      tosList {
        tos_id
        tos_createdAt
        tos_creator
        tos_text
        tos_deleteDate
      }
    }
  }
`;

export const SEE_VOC_NUMBER = gql`
  query seeVOCNumber {
    seeVOCNumber {
      tokenExpired
      totalLength
      vocNumberList {
        vn_id
        vn_callNumber
      }
    }
  }
`;
