import { gql } from "@apollo/client";

export const CREATE_ADMIN = gql`
  mutation createAdmin(
    $team: String
    $name: String
    $rank: String
    $accountId: String
    $password: String
    $user_manage: Boolean
    $project: Boolean
    $manage: Boolean
  ) {
    createAdmin(
      team: $team
      name: $name
      rank: $rank
      accountId: $accountId
      password: $password

      user_manage: $user_manage
      project: $project
      manage: $manage
    ) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation deleteAdmin($admin_id: Int) {
    deleteAdmin(admin_id: $admin_id) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_PERMISSION = gql`
  mutation editPermission(
    $admin_id: Int!
    $user_manage: Boolean
    $project: Boolean
    $manage: Boolean
  ) {
    editPermission(
      admin_id: $admin_id
      user_manage: $user_manage
      project: $project
      manage: $manage
    ) {
      tokenExpired
      result
    }
  }
`;
