import React from "react";
import styled from "styled-components";

const InputWrapper = styled.div`
  width: 300px;
  height: 46px;
  position: relative;
  margin-bottom: 20px;
`;

const InputTitle = styled.span`
  position: absolute;
  top: 17px;
  left: 14px;
  padding: 0 5px;
  font-size: 14px;
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  transition: 0.3s;
`;

const InputBox = styled.input`
  all: unset;
  width: 100%;
  height: 100%;
  font-size: 14px;
  padding-left: 14px;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.textFieldBorder};
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
  &:focus ~ span,
  &:valid ~ span {
    top: -2px;
    font-size: 10px;
    color: ${({ theme }) => theme.mainColor};
  }
`;

const TextField = ({
  title = "아이디",
  name,
  value = "",
  PASSWORD,
  onChange = () => {},
  onKeyPress = () => {},
}) => {
  return (
    <InputWrapper>
      <InputBox
        type={PASSWORD && "password"}
        value={value}
        name={name}
        onChange={(e) => onChange(e)}
        onKeyPress={(e) => onKeyPress(e)}
        required
      />
      <InputTitle>{title}</InputTitle>
    </InputWrapper>
  );
};

export default TextField;
