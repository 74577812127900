import { gql } from "@apollo/client";

export const SEE_LABEL_LIST = gql`
  query seeLabelList($searchTerm: String) {
    seeLabelList(searchTerm: $searchTerm) {
      tokenExpired
      labelList {
        lb_id
        lb_name
        lb_name_eng
      }
    }
  }
`;

export const SEE_SAFETY_CATEGORY = gql`
  query seeSafetyCategory {
    seeSafetyCategory {
      tokenExpired
      result
      safetyCategoryList {
        sc_id
        sc_name_kr
        sc_name_eng
      }
    }
  }
`;
