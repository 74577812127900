import React, { useState, useCallback, useEffect, useContext } from "react";
import styled from "styled-components";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";
import StyledSelect from "../../../share/StyledSelect";
import StyledCheckbox from "../../../share/StyledCheckbox";
import { useMutation } from "@apollo/client";
import { CREATE_ADMIN } from "../../../../graphql/Admin/mutation";
import { toast } from "react-toastify";
import { AdminContext } from "../../../../routes/Admin/AdminList/AdminListContainer";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  margin: ${({ margin }) => margin};
  color: ${({ gray, theme }) =>
    gray ? `${theme.textFieldPlaceHolder}` : `${theme.defaultFontColor}`};
`;

const RowBox = styled.div`
  display: flex;
  margin: ${({ margin }) => margin};
  & > ${Text} {
    cursor: pointer;
  }
`;
const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
`;

const PasswordCheck = styled.div`
  margin-top: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.passwordCheckRedColor};
`;

const AddModal = () => {
  const { userLogout } = useContext(AuthContext);
  const { handleAddModalClose, adminListRefetch } = useContext(AdminContext);

  const [pwCheckText, setPwCheckText] = useState(false);
  const [inputs, setInputs] = useState({
    team: "",
    rank: "",
    name: "",
    id: "",
    pw: "",
    pwCheck: "",
  });

  const [permission, setPermission] = useState({
    home: false,
    project: false,
    user_manage: false,
    manage: false,
  });

  const [createAdmin] = useMutation(CREATE_ADMIN);

  const onChangePermission = useCallback(
    (name, value) => {
      setPermission({
        ...permission,
        [name]: value,
      });
    },
    [permission]
  );

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const onChangeSelect = useCallback((name, value) => {
    setInputs({ ...inputs, [name]: value });
  });

  const handleSave = useCallback(async () => {
    if (inputs.team.trim() === "") {
      toast.error("부서를 입력해주세요.");
      return;
    }
    if (inputs.rank.trim() === "") {
      toast.error("직급을 입력해주세요.");
      return;
    }
    if (inputs.name.trim() === "") {
      toast.error("이름을 입력해주세요.");
      return;
    }

    if (inputs.id.includes(" ")) {
      toast.error("아이디에 공백이 포함되어 있습니다.");
      return;
    }
    if (inputs.id.trim() === "") {
      toast.error("아이디를 입력해주세요.");
      return;
    }
    if (inputs.pw.trim() === "") {
      toast.error("비밀번호를 입력해주세요.");
      return;
    }

    if (inputs.pw.trim() !== inputs.pwCheck) {
      toast.error("비밀번호가 올바르지 않습니다.");
      return;
    }
    try {
      const { data } = await createAdmin({
        variables: {
          team: inputs.team,
          name: inputs.name,
          rank: inputs.rank,
          accountId: inputs.id,
          password: inputs.pw,

          user_manage: permission.user_manage,
          project: permission.project,
          manage: permission.manage,
        },
      });
      if (data?.createAdmin?.result) {
        toast.success("사용자가 추가되었습니다.");
        await adminListRefetch();
        handleAddModalClose();
      }
      if (data?.createAdmin && !data?.createAdmin?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [permission, inputs]);

  useEffect(() => {
    if (inputs.pw !== inputs.pwCheck) setPwCheckText(true);
    else setPwCheckText(false);
  }, [inputs.pw, inputs.pwCheck]);

  return (
    <StyledModal
      title="사용자 등록하기"
      width={760}
      height={600}
      btnLabel="등록"
      handleClose={handleAddModalClose}
      handleSave={handleSave}
    >
      <RowBox>
        <ColumnBox>
          <RowBox>
            <StyledInput
              width={196}
              margin="0 18px 26px 0"
              label="부서"
              name="team"
              placeholder="부서를 입력하세요."
              value={inputs.team}
              onChange={onChangeInputs}
              maxLength={30}
              NOLENGTHVIEW
            />
            <StyledInput
              width={196}
              margin="0 18px 26px 0"
              label="직급"
              name="rank"
              placeholder="직급을 입력하세요."
              value={inputs.rank}
              onChange={onChangeInputs}
              maxLength={30}
              NOLENGTHVIEW
            />
          </RowBox>
          <RowBox>
            <StyledInput
              width={196}
              margin="0 18px 26px 0"
              label="이름"
              name="name"
              placeholder="이름을 입력하세요."
              value={inputs.name}
              onChange={onChangeInputs}
              maxLength={30}
              NOLENGTHVIEW
            />
            <StyledInput
              width={196}
              label="아이디"
              name="id"
              placeholder="아이디를 입력하세요."
              value={inputs.id}
              onChange={onChangeInputs}
              maxLength={30}
              NOLENGTHVIEW
            />
          </RowBox>
          <StyledInput
            width={288}
            margin="0 0 26px 0"
            label="비밀번호"
            name="pw"
            placeholder="비밀번호를 입력하세요."
            value={inputs.pw}
            onChange={onChangeInputs}
            maxLength={30}
            NOLENGTHVIEW
          />
          <StyledInput
            width={288}
            label="비밀번호 확인"
            name="pwCheck"
            placeholder="비밀번호를 다시 입력하세요."
            value={inputs.pwCheck}
            onChange={onChangeInputs}
            maxLength={30}
            NOLENGTHVIEW
          />
          {inputs.pwCheck !== "" && pwCheckText && (
            <PasswordCheck>비밀번호가 다릅니다.</PasswordCheck>
          )}
        </ColumnBox>
        <ColumnBox margin="0 0 0 80px">
          <Text gray>사용자 권한 종류</Text>
          <RowBox
            margin="21px 0 26px 0 "
            onClick={() => onChangePermission("project", !permission.project)}
          >
            <StyledCheckbox clicked={permission.project} />
            <Text>프로젝트관리</Text>
          </RowBox>
          <RowBox
            margin="0 0 26px 0 "
            onClick={() =>
              onChangePermission("user_manage", !permission.user_manage)
            }
          >
            <StyledCheckbox clicked={permission.user_manage} />
            <Text>유저관리</Text>
          </RowBox>
          <RowBox
            margin="0 0 26px 0 "
            onClick={() => onChangePermission("manage", !permission.manage)}
          >
            <StyledCheckbox clicked={permission.manage} />
            <Text>운영관리</Text>
          </RowBox>
        </ColumnBox>
      </RowBox>
    </StyledModal>
  );
};

export default AddModal;
