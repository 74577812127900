import { gql } from "@apollo/client";

export const SEE_ADMIN_PERMISSION = gql`
  query seeAdminPermission {
    seeAdminPermission {
      master
      home
      manage
      project
      result
      user_manage
    }
  }
`;
