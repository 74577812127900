import React, {memo} from "react";
import styled from "styled-components";
import searchIcon from "../../assets/icon/searchIcon.svg";

const Container = styled.div`
  width: ${({width}) => width}px;
  position: relative;
  margin: ${({margin}) => margin};
`;

const Input = styled.input`
  width: 100%;
  height: 46px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid ${({theme}) => theme.styledBorderColor};
  font-size: 16px;

  &::placeholder {
    color: ${({theme}) => theme.textFieldBorder};
  }

  &:focus {
    border: 1px solid ${({theme}) => theme.graphBorderColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.div`
  margin-bottom: 12px;
  color: ${({theme}) => theme.textFieldPlaceHolder};
`;

const Icon = styled.img`
  width: 22px;
  position: absolute;
  right: 15px;
  bottom: calc(23px - 11px);
  cursor: pointer;
`;

const StyledInput = ({
                         SEARCH,
                         READONLY,
                         name = "",
                         label = "",
                         placeholder = "",
                         value = "",
                         onChange = () => {
                         },
                         width = 280,
                         maxLength,
                         margin,
                         NOLENGTHVIEW,
                     }) => {
    return (
        <Container width={width} margin={margin}>
            {!NOLENGTHVIEW && maxLength ? (
                <RowBox>
                    <Label>{label}</Label>
                    <Label>
                        ({value.length} / {maxLength})
                    </Label>
                </RowBox>
            ) : (
                <Label>{label}</Label>
            )}
            <Input
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                maxLength={maxLength && maxLength - 1}
                readOnly={READONLY && true}
            />
            {SEARCH && <Icon src={searchIcon}/>}
        </Container>
    );
};

export default memo(StyledInput);
