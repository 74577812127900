import React, {useContext} from "react";
import styled from "styled-components";
import GuideLine from "../../../../components/layout/GuideLine";
import SortingForm from "../../../../components/layout/SortingForm";
import StyledRowTable from "../../../../components/share/StyledRowTable";
import PageNation from "../../../../components/layout/PageNation";
import RowTable from "../../../../components/feature/User/UserList/UserList/RowTable";
import MessageModal from "../../../../components/feature/User/UserList/UserList/MessageModal";
import MailModal from "../../../../components/feature/User/UserList/UserList/MailModal";
import {UserContext} from "./UserListContainer";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({theme}) => theme.defaultBackgroundColor};
`;

const UserListPresenter = ({}) => {
    const {
        inputs,
        setInputs,
        handleReset,
        handleMail,
        handleMessage,
        page,
        totalLength,
        onClickPage,
        messageModal,
        mailModal,
        checkIndex,
        onClickCheckBox,
    } = useContext(UserContext);

    return (
        <>
            <Container>
                <GuideLine label={["유저관리", "유저 목록"]}/>
                <ContentContainer>
                    <SortingForm
                        inputs={inputs}
                        setInputs={setInputs}
                        handleReset={handleReset}
                        handleMail={handleMail}
                        handleMessage={handleMessage}
                        placeholder="유저이름, 이메일, 휴대폰번호, 회사명"
                        SEARCH
                        width={330}
                        USERSORT
                        TEAM
                        USESTATE
                        RESET
                        MAIL
                        MESSAGE
                    />
                    <StyledRowTable
                        CHECKBOX
                        clicked={checkIndex?.length === 16}
                        handleCheckbox={onClickCheckBox}
                        label={[
                            {text: "가입일", width: 132},
                            {text: "프로필", width: 102},
                            {text: "소속회사명", width: 150},
                            {text: "소속", width: 128},
                            {text: "이름", width: 128},
                            {text: "직책", width: 109},
                            {text: "국가", width: 88},
                            {text: "연락처", width: 162},
                            {text: "메일주소", width: 256},
                            {text: "이용여부", width: 132},
                        ]}
                    />
                    <RowTable/>
                    <PageNation
                        page={page}
                        onClickPage={onClickPage}
                        totalLength={totalLength}
                    />
                </ContentContainer>
            </Container>
            {messageModal && <MessageModal/>}
            {mailModal && <MailModal/>}
        </>
    );
};

export default UserListPresenter;
