import React from "react";
import styled from "styled-components";
import Logo from "../../assets/icon/Logo.svg";
import TextField from "../../components/share/TextField";
import loadingIcon from "../../assets/icon/loadingIcon.svg";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.mainColor};
`;

const LogoImage = styled.img`
  width: 218px;
  position: fixed;
  top: 34px;
  left: 34px;
`;

const LoginBox = styled.div`
  width: 380px;
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 80px 40px 0 40px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const TitleText = styled.div`
  width: 100%;
  margin-bottom: 36px;
  font-size: 24px;
  font-weight: bold;
`;

const LoginButton = styled.button`
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  border-radius: 4px;
  margin-top: 65px;
  color: ${({ theme }) => theme.whiteColor};
  background-color: ${({ theme }) => theme.mainColor};
  &:hover {
    background-color: ${({ theme }) => theme.mainColorHover};
  }
`;

const LoadingIcon = styled.img`
  width: 40px;
`;

const LoginPresenter = ({ inputs, onChangeLogin, LoginHaldle, isLoading }) => {
  return (
    <Container>
      <LogoImage src={Logo} />
      <LoginBox>
        <TitleText>RiskZero HG 관리자</TitleText>
        <TextField name="id" value={inputs.id} onChange={onChangeLogin} />
        <TextField
          name="pw"
          title="비밀번호"
          PASSWORD
          value={inputs.pw}
          onChange={onChangeLogin}
          onKeyPress={(e) => e.key === "Enter" && LoginHaldle()}
        />
        <LoginButton onClick={() => LoginHaldle()}>
          {isLoading ? <LoadingIcon src={loadingIcon} /> : "로그인"}
        </LoginButton>
      </LoginBox>
    </Container>
  );
};

export default LoginPresenter;
