import React, { useCallback, useEffect, useState, useContext } from "react";
import ProjectPresenter from "./ProjectPresenter";
import { useQuery, useMutation } from "@apollo/client";
import { SEE_PRJ_LIST } from "../../../../graphql/Project/Project/ProjectList/query";
import { CREATE_PRJ_EXCEL } from "../../../../graphql/Project/Project/ProjectList/mutation";
import {
  projectSortChanger,
  projectStatusChanger,
} from "../../../../lib/ProjectUtils";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";

const ProjectListContainer = () => {
  const { userLogout } = useContext(AuthContext);
  const [cursor, setCursor] = useState(0);
  const [page, setPage] = useState(1);
  const [totalLength, setTotalLength] = useState(0);
  const [prjList, setPrjList] = useState(null);
  // 시작일 (1년 전 1월 1일)
  const now = new Date();
  const searchStartDate = new Date(2019, 0, 1);
  // 종료일 (1년 후 12월 31일)
  const searchEndDate = new Date(now.getFullYear() + 1, 11, 31);
  const [inputs, setInputs] = useState({
    searchTerm: "",
    sort: "최신 순",
    state: "전체",
    date: [searchStartDate, searchEndDate],
  });

  const [createPrjExcel] = useMutation(CREATE_PRJ_EXCEL);

  const {
    data: prjData,
    loading: prjLoading,
    refetch: prjRefetch,
  } = useQuery(SEE_PRJ_LIST, {
    variables: {
      searchTerm: inputs.searchTerm,
      sort: projectSortChanger(inputs.sort),
      status: projectStatusChanger(inputs.state),
      start: inputs.date[0],
      end: inputs.date[1],
      take: 16,
      cursor: cursor,
    },
    pollInterval: 5000,
  });

  const handleReset = useCallback(() => {
    setInputs({
      searchTerm: "",
      sort: "최신 순",
      state: "전체",
      date: [searchStartDate, searchEndDate],
    });
  }, [inputs]);

  const onChangeDate = useCallback(
    (value) => {
      setInputs({ ...inputs, date: value });
    },
    [inputs]
  );

  const handleExcel = useCallback(async () => {
    try {
      const { data } = await createPrjExcel({
        variables: {
          searchTerm: inputs.searchTerm,
          sort: projectSortChanger(inputs.sort),
          status: projectStatusChanger(inputs.state),
          start: inputs.date[0],
          end: inputs.date[1],
        },
      });
      if (data?.createPrjExcel?.result) {
        window.open(data.createPrjExcel.projectExcelUrl);
        toast.success("엑셀 파일을 다운로드 하였습니다.");
      }
      if (data?.createPrjExcel && !data?.createPrjExcel?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  const onClickPage = useCallback((page) => {
    setPage(page);
    if (page !== 1) {
      setCursor((page - 1) * 16);
    }
    if (page === 1) {
      setCursor(0);
    }
  }, []);

  useEffect(() => {
    console.log(prjData);
    if (prjData?.seePrjList?.result) {
      setPrjList(prjData.seePrjList.projectList);
      setTotalLength(prjData.seePrjList.totalLength);
    }
    if (prjData?.seePrjList && !prjData?.seePrjList?.tokenExpired) {
      userLogout();
    }
  }, [prjData]);

  return (
    <ProjectPresenter
      inputs={inputs}
      setInputs={setInputs}
      handleReset={handleReset}
      onChangeDate={onChangeDate}
      handleExcel={handleExcel}
      page={page}
      totalLength={totalLength}
      onClickPage={onClickPage}
      prjList={prjList}
    />
  );
};

export default ProjectListContainer;
