import React, { memo, useContext } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { ServiceCenterContext } from "../../../../routes/Management/ServiceCenter/ServiceCenterContainer";
import { dateFormat } from "../../../../utils/common";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 20px;
  font-weight: 500;
  color: ${({ theme, grayColor }) =>
    grayColor ? theme.tableLinkFontColor : theme.defaultFontColor};
`;

const GrayLine = styled.div`
  width: 1377px;
  height: 0px;
  display: ${({ del }) => (del ? "block" : "none")};
  position: absolute;
  left: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.tableLinkFontColor};
`;

const RowTable = () => {
  const { radioIndex, tosList, onClickRadio } =
    useContext(ServiceCenterContext);

  return (
    <Container>
      {tosList.map((data, index) => (
        <RowBox key={index}>
          <StyledRadioButton
            clicked={radioIndex === index}
            onClick={() => onClickRadio(index)}
          />
          <GrayLine del={data.tos_deleteDate !== ""} />
          <Text width={142} grayColor={data.tos_deleteDate !== ""}>
            {dateFormat(new Date(Number(data.tos_createdAt)), "yyyy.MM.dd")}
          </Text>
          <Text width={102} grayColor={data.tos_deleteDate !== ""}>
            {data.tos_creator}
          </Text>
          <Text width={1212} grayColor={data.tos_deleteDate !== ""}>
            {data.tos_text}
          </Text>
          <Text width={120}>
            {data.tos_deleteDate === ""
              ? "-"
              : dateFormat(new Date(Number(data.tos_deleteDate)), "yyyy.MM.dd")}
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
