import React, { useContext } from "react";
import styled from "styled-components";
import guideLineIcon from "../../assets/icon/guideLineIcon.svg";
import { AuthContext } from "../../context/AuthProvider";

const Container = styled.div`
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  margin-bottom: 8px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const ArrowIcon = styled.img`
  margin: 0 12px;
  display: ${({ last }) => last && "none"};
`;

const LabelText = styled.div`
  font-weight: bold;
  color: ${({ theme }) => theme.guideLineFontColor};
`;

const LogoutButton = styled.div`
  color: ${({ theme }) => theme.logoutBtnColor};
  margin: 0 32px 0 auto;
  cursor: pointer;
`;

const GuideLine = ({ label = [] }) => {
  const { userLogout } = useContext(AuthContext);

  return (
    <Container>
      {label.map((text, index) => (
        <LabelText key={index}>
          {text}{" "}
          <ArrowIcon last={label.length === index + 1} src={guideLineIcon} />
        </LabelText>
      ))}
      <LogoutButton onClick={userLogout}>로그아웃</LogoutButton>
    </Container>
  );
};

export default GuideLine;
