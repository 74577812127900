import React, {memo, useEffect, useState} from "react";
import styled from "styled-components";
import CountUp from "react-countup";
import {PieChart, Pie, Cell} from "recharts";

const Wrapper = styled.div`
  width: 292px;
  margin-top: 30px;
`;

const Header = styled.div`
  color: ${({theme}) => theme.defaultFontColor};
  padding-bottom: 9px;
`;

const ChartBox = styled.div``;

const StatsBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Stats = styled.div`
  margin-bottom: 16px;
`;

const Text = styled.div`
  white-space: nowrap;
  font-weight: ${({fontWeight}) => (fontWeight ? fontWeight : 400)};
  color: ${({fontColor, theme}) =>
          fontColor ? fontColor : theme.defaultFontColor};

  & + & {
    margin-top: 6px;
  }
`;

const RowBox = styled.div`
  display: flex;
`;

const CircleChart = ({
                         CHECK_LIST,
                         title = "SCAR 처리 상태별",
                         data,
                         margin = "0 30px",
                         padding = "18px",
                         border,
                         checkListTotal,
                     }) => {
    const COLORS = ["#B5B5B5", "#00C0F3", "#FFC952"];
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (!CHECK_LIST && data) {
            setTotal(data[0].value + data[1].value + data[2].value);
        }
    }, [CHECK_LIST, data]);

    return (
        <Wrapper margin={margin} padding={padding}>
            <Header>{title}</Header>
            <RowBox>
                {!CHECK_LIST && (
                    <>
                        <ChartBox border={border}>
                            <PieChart width={256} height={256}>
                                <Pie
                                    data={data}
                                    cx={118}
                                    cy={118}
                                    innerRadius={83}
                                    outerRadius={100}
                                    paddingAngle={5}
                                    dataKey="value"
                                    startAngle={120}
                                    endAngle={-360}
                                >
                                    {data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                                    ))}
                                </Pie>
                            </PieChart>
                        </ChartBox>
                        <StatsBox>
                            {data?.map((stats, index) => (
                                <Stats key={`${stats.name}-${stats.value}`}>
                                    <Text>{stats.name}</Text>
                                    <Text fontWeight={600} fontColor={COLORS[index]}>
                                        <CountUp end={stats.value} duration={1}>
                                            {stats.ratio}
                                        </CountUp>
                                        % ({stats.value} / {total})
                                    </Text>
                                </Stats>
                            ))}
                        </StatsBox>
                    </>
                )}
                {CHECK_LIST && (
                    <>
                        <ChartBox border={border}>
                            <PieChart width={256} height={256}>
                                <Pie
                                    data={data}
                                    cx={118}
                                    cy={118}
                                    innerRadius={83}
                                    outerRadius={100}
                                    paddingAngle={5}
                                    dataKey="value"
                                    startAngle={120}
                                    endAngle={-360}
                                >
                                    {data?.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={COLORS[index]}/>
                                    ))}
                                </Pie>
                            </PieChart>
                        </ChartBox>
                        <StatsBox>
                            {data?.map((stats, index) => (
                                <Stats key={`${stats.name}-${stats.value}`}>
                                    {console.info('stats : ', stats.ratio)}
                                    <Text>{stats.name}</Text>
                                    <Text fontWeight={600} fontColor={COLORS[index]}>
                                        <CountUp end={stats.ratio} duration={1} />
                                        % ({stats.value} / {checkListTotal})
                                    </Text>
                                </Stats>
                            ))}
                        </StatsBox>
                    </>
                )}
            </RowBox>
        </Wrapper>
    );
};

export default memo(CircleChart);
