import { gql } from "@apollo/client";

export const SEE_PRJ_LIST = gql`
  query seePrjList(
    $searchTerm: String
    $sort: projectSort
    $status: projectStatus
    $start: String
    $end: String
    $take: Int
    $cursor: Int
  ) {
    seePrjList(
      searchTerm: $searchTerm
      sort: $sort
      status: $status
      start: $start
      end: $end
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      result
      totalLength
      projectList {
        prj_id
        prj_openAvailable
        prj_title
        prj_address
        prj_number
        prj_img
        prj_startDate
        prj_endDate
        prj_endRequestDate
        prj_endRequestUserId
        prj_endRequestUserInfo
        prj_status
        prj_memberCount
        prj_scarCount
        prj_creatorName
        prj_creatorGroup
      }
    }
  }
`;
