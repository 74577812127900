import React, { useState, useContext, useCallback } from "react";
import styled from "styled-components";
import StyledModal from "../../../../share/StyledModal";
import { SEND_EMAIL_USER } from "../../../../../graphql/User/UserList/UserList/mutation";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { UserContext } from "../../../../../routes/User/UserList/UserList/UserListContainer";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const InputBox = styled.textarea`
  width: 560px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const MailModal = () => {
  const { handleMail, checkIndex, onClickCheckBox } = useContext(UserContext);

  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");

  const [sendEmailUser] = useMutation(SEND_EMAIL_USER);

  const handleSave = useCallback(async () => {
    if (message.trim() === "") {
      toast.error("메세지를 입력해주세요.");
      return;
    }
    try {
      const { data } = await sendEmailUser({
        variables: {
          user_ids: checkIndex,
          subject: title,
          msg: message,
        },
      });
      if (data?.sendEmailUser?.result) {
        toast.success("메일을 전송하였습니다.");
        handleMail();
        onClickCheckBox(-1);
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [message]);

  return (
    <StyledModal
      title="메일 보내기"
      width={640}
      height={580}
      handleClose={handleMail}
      handleSave={handleSave}
    >
      <Text>제목</Text>
      <InputBox
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        height={46}
        placeholder="제목을 입력하세요"
      />
      <Text marginTop={26}>내용</Text>
      <InputBox
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        height={230}
        placeholder="내용을 입력하세요"
      />
    </StyledModal>
  );
};

export default MailModal;
