import React, {useCallback} from "react";
import styled from "styled-components";
import goBackIcon from "../../../../../assets/icon/goBackIcon.svg";
import {groupChanger} from "../../../../../lib/UserUtils";
import downloadIcon from "../../../../../assets/icon/downloadIcon.svg";
import {toast} from "react-toastify";

const Text = styled.div`
  color: ${({theme, fontColor}) => fontColor ? fontColor : theme.defaultFontColor};
  font-size: ${({fontSize}) => fontSize}px;
  font-weight: ${({BOLD}) => BOLD && "bold"};
  margin: ${({margin}) => margin};
`;
const RowBox = styled.div`
  display: flex;
  align-items: center;
  margin: ${({margin}) => margin};
  cursor: ${({POINTER}) => POINTER && "pointer"};
  justify-content: ${({justifyContent}) => justifyContent};
`;
const Icon = styled.img` 
  cursor: pointer;
`;

const CheckListItemHeader = ({
                                 detail,
                                 checkListDetailData,
                                 weeklySafetyCategory,
                                 goBack = () => null
                             }) => {

    const handleDownloadPDF = useCallback(() => {
        if (checkListDetailData?.weeklyCheckListInfo?.wcl_evaluationPdf === '') {
            toast.error('생성된 PDF파일이 없습니다.');
            return;
        }
        window.open(checkListDetailData?.weeklyCheckListInfo?.wcl_evaluationPdf)
    }, [checkListDetailData]);

    return (
        <>
            <Icon src={goBackIcon} onClick={goBack}/>
            <RowBox margin="20px 0">
                <Text fontSize={18} BOLD>
                    {checkListDetailData?.weeklyCheckListInfo?.wcl_year}년&nbsp;
                    {checkListDetailData?.weeklyCheckListInfo?.wcl_month}월&nbsp;
                    {checkListDetailData?.weeklyCheckListInfo?.wcl_week}주차
                    {weeklySafetyCategory && (
                        <>&nbsp;- {weeklySafetyCategory}</>
                    )}
                </Text>
                <Text margin="0 0 0 12px">
                    ( 평가자 :&nbsp;
                    {groupChanger(checkListDetailData?.weeklyCheckListInfo?.wcl_evaluatorGroup)}&nbsp;
                    {checkListDetailData?.weeklyCheckListInfo?.wcl_evaluatorName}&nbsp;)
                </Text>
            </RowBox>
            <RowBox margin="20px 0">
                <Text>준수율 : {checkListDetailData?.weeklyCheckListInfo?.wcl_complyRate}%</Text>
                <Text margin="0 40px">총점 : {checkListDetailData?.weeklyCheckListInfo?.wcl_totalScore}</Text>
                <Text>평가점수 : {checkListDetailData?.weeklyCheckListInfo?.wcl_evaluationScore}</Text>
                <Text margin="0 auto 0 40px">반복위반
                    : {checkListDetailData?.weeklyCheckListInfo?.wcl_repeatedViolation}</Text>
                {!detail && <Icon src={downloadIcon} onClick={handleDownloadPDF} />}
            </RowBox>
        </>
    )
}

export default React.memo(CheckListItemHeader);