import { gql } from "@apollo/client";

export const CREATE_LABEL = gql`
  mutation createLabel($labelName: String, $labelNameEng: String) {
    createLabel(labelName: $labelName, labelNameEng: $labelNameEng) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_LABEL = gql`
  mutation deleteLabel($lb_id: Int) {
    deleteLabel(lb_id: $lb_id) {
      tokenExpired
      result
    }
  }
`;

export const CREATE_SAFETY_CATEGORY = gql`
  mutation createSafetyCategory($scNameKr: String!, $scNameEng: String!) {
    createSafetyCategory(sc_name_kr: $scNameKr, sc_name_eng: $scNameEng) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_SAFETY_CATEGORY = gql`
  mutation deleteSafetyCategory($scId: Int!) {
    deleteSafetyCategory(sc_id: $scId) {
      tokenExpired
      result
    }
  }
`;
