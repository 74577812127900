import React, { useState, useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import StyledInput from "../../../share/StyledInput";
import StyledModal from "../../../share/StyledModal";
import { useMutation } from "@apollo/client";
import { CREATE_FAQ } from "../../../../graphql/Management/Faq/mutation";
import { FaqContext } from "../../../../routes/Management/Faq/FaqContainer";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  color: ${({ theme }) => theme.textFieldPlaceHolder};
  margin-top: ${({ marginTop }) => marginTop}px;
  position: relative;
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

const InputBox = styled.textarea`
  width: 480px;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const FaqAddModal = () => {
  const { userLogout } = useContext(AuthContext);
  const { setAddModal, faqRefetch } = useContext(FaqContext);

  const [inputs, setInputs] = useState({
    title: "",
    contents: "",
  });

  const [createFAQ] = useMutation(CREATE_FAQ);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const handleSave = useCallback(async () => {
    if (inputs.title.trim() === "") {
      toast.error("질문명을 입력해주세요.");
      return;
    }
    if (inputs.contents.trim() === "") {
      toast.error("답변 내용을 입력해주세요.");
      return;
    }
    try {
      const { data } = await createFAQ({
        variables: {
          faq_question: inputs.title,
          faq_answer: inputs.contents,
        },
      });
      if (data?.createFAQ?.result) {
        toast.success("질문이 등록되었습니다.");
        setAddModal(false);
        await faqRefetch();
      }
      if (data?.createFAQ && !data?.createFAQ?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  return (
    <StyledModal
      title="질문 등록하기"
      btnLabel="등록"
      width={560}
      height={600}
      handleClose={() => setAddModal(false)}
      handleSave={() => handleSave()}
    >
      <StyledInput
        name="title"
        label="질문명"
        width={480}
        placeholder="질문명을 입력하세요"
        value={inputs.title}
        onChange={onChangeInputs}
        maxLength={80}
      />

      <Text marginTop={26}>
        답변 내용
        <Text absolute>({inputs.contents.length}/200)</Text>
      </Text>
      <InputBox
        name="contents"
        height={247}
        placeholder="답변 내용을 입력하세요"
        value={inputs.contents}
        onChange={onChangeInputs}
        maxLength={200}
      />
    </StyledModal>
  );
};

export default FaqAddModal;
