import React from "react";
import styled from "styled-components";
import {Area, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis, AreaChart} from 'recharts';
import {theme} from "../../../../styles/theme";

const Wrapper = styled.div`
  height: 100%;
`;
const ChartContainer = styled(ResponsiveContainer)`
  width: 100%;
  height: 100%;
`;

const LineChart = ({ data }) => {
    return (
        <Wrapper>
            <ChartContainer>
                <AreaChart
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid
                        strokeDasharray="1 0"
                        vertical={false}
                    />
                    <XAxis dataKey="name" />
                    <YAxis

                    />
                    <Area
                        type="monotone"
                        dataKey="value"
                        stroke={theme.lightTheme.mainColor}
                        fill={theme.lightTheme.projectDetailGroupFontColor}
                    />
                </AreaChart>
            </ChartContainer>
        </Wrapper>
    )
}

export default React.memo(LineChart);
