import React, { useState } from "react";
import styled, { css } from "styled-components";

const TabContainer = styled.div`
  width: 1646px;
  height: 48px;
  display: flex;
  margin-left: 16px;
  border-bottom: 2px solid ${({ theme }) => theme.textFieldBorder};
`;

const TabButton = styled.div`
  width: 147px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;
  color: ${({ theme, select }) =>
    select ? `${theme.whiteColor}` : `${theme.defaultFontColor}`};
  background-color: ${({ theme, select }) => select && `${theme.mainColor}`};
  cursor: pointer;
`;

const Text = styled.div`
  font-weight: ${({ BOLD }) => BOLD && "bold"};
  margin: ${({ margin }) => margin};
`;

const DetailTab = ({ tab = "참여 프로젝트" }) => {
  return (
    <>
      <Text BOLD margin="0 0 20px 16px">
        유저 활동 기록
      </Text>
      <TabContainer>
        <TabButton select={tab === "참여 프로젝트"}>참여 프로젝트</TabButton>
      </TabContainer>
    </>
  );
};

export default DetailTab;
