import React, { useState, useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import { autoHyphen, dateFormat } from "../../../../utils/common";
import StyledModal from "../../../share/StyledModal";
import { useMutation } from "@apollo/client";
import { CREATE_ONE_ANSWER } from "../../../../graphql/Management/Qna/mutation";
import { toast } from "react-toastify";
import { QnaContext } from "../../../../routes/Management/Qna/QnaContainer";
import { groupChanger } from "../../../../lib/UserUtils";
import { AuthContext } from "../../../../context/AuthProvider";

const Text = styled.div`
  color: ${({ BLACK, theme }) =>
    !BLACK ? `${theme.textFieldPlaceHolder}` : `${theme.defaultFontColor}`};
  margin-top: ${({ marginTop, marginAuto }) =>
    marginAuto ? "auto" : `${marginTop}px`};
  line-height: ${({ lineHeight }) => lineHeight && 22}px;
  position: relative;
  ${({ absolute }) =>
    absolute &&
    css`
      position: absolute;
      top: 0;
      right: 0;
    `}
`;

const InputBox = styled.textarea`
  width: 100%;
  height: ${({ height }) => height}px;
  padding: 14px 16px;
  margin-top: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.styledBorderColor};
  ::placeholder {
    color: ${({ theme }) => theme.textFieldPlaceHolder};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.mainColor};
  }
`;

const RowBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const QnaAnswerModal = ({ oneData, handleClose }) => {
  const { qnaRefetch } = useContext(QnaContext);
  const { userLogout } = useContext(AuthContext);

  const [inputs, setInputs] = useState({
    contents: "",
  });

  const [createOneAnswer] = useMutation(CREATE_ONE_ANSWER);

  const onChangeInputs = useCallback(
    (e) => {
      const { name, value } = e.target;
      setInputs({ ...inputs, [name]: value });
    },
    [inputs]
  );

  const handleSave = useCallback(async () => {
    if (inputs.contents.trim() === "") {
      toast.error("답변 내용을 입력해주세요.");
      return;
    }
    try {
      const { data } = await createOneAnswer({
        variables: {
          one_id: oneData.one_id,
          answerText: inputs.contents,
        },
      });
      if (data?.createOneAnswer?.result) {
        await qnaRefetch();
        toast.success("문의 답변이 저장되었습니다.");
        handleClose();
      }
      if (data?.createOneAnswer && !data?.createOneAnswer?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [inputs]);

  return (
    <StyledModal
      title="문의 답변하기"
      width={780}
      height={660}
      handleClose={handleClose}
      handleSave={handleSave}
    >
      <RowBox>
        <ColumnBox>
          <Text>작성일</Text>
          <Text BLACK marginTop={13}>
            {dateFormat(new Date(Number(oneData.one_createdAt)), "yyyy.MM.dd")}
          </Text>
        </ColumnBox>
        <ColumnBox>
          <Text>작성자</Text>
          <Text BLACK marginTop={13}>
            {oneData.user_name}
          </Text>
        </ColumnBox>
        <ColumnBox>
          <Text>소속회사명</Text>
          <Text BLACK marginTop={13}>
            {oneData.user_groupCompany}
          </Text>
        </ColumnBox>
        <ColumnBox>
          <Text>소속</Text>
          <Text BLACK marginTop={13}>
            {groupChanger(oneData.user_group)}
          </Text>
        </ColumnBox>
        <ColumnBox>
          <Text>연락처</Text>
          <Text BLACK marginTop={13}>
            {autoHyphen(oneData.user_cellphone)}
          </Text>
        </ColumnBox>
        <ColumnBox>
          <Text>메일주소</Text>
          <Text BLACK marginTop={13}>
            {oneData.user_email}
          </Text>
        </ColumnBox>
      </RowBox>
      <ColumnBox>
        <Text marginTop={30}>문의 내용</Text>
        <Text BLACK marginTop={13} lineHeight>
          {oneData.one_question}
        </Text>
      </ColumnBox>
      <Text marginAuto>
        답변 내용
        <Text absolute>({inputs.contents.length} / 200)</Text>
      </Text>
      <InputBox
        name="contents"
        height={173}
        placeholder="답변 내용을 입력하세요"
        value={inputs.contents}
        onChange={onChangeInputs}
        maxLength={200}
      />
    </StyledModal>
  );
};

export default QnaAnswerModal;
