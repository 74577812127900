import {gql} from "@apollo/client";

export const SEE_PRJ_DETAIL_INFO = gql`
    query seePrjDetailInfo($prjId: Int) {
        seePrjDetailInfo(prj_id: $prjId) {
            projectInfo {
                prj_id
                prj_creatorId
                prj_createdAt
                prj_creator_group
                prj_creator_name
                prj_openAvailable
                prj_title
                prj_address
                prj_number
                prj_img
                prj_startDate
                prj_endDate
                prj_endRequestDate
                prj_endRequestUserId
                prj_endRequestUserInfo
                prj_status
                prj_memeber {
                    user_id
                    user_name
                    user_groupCompany
                    user_group
                    user_changedPermission
                    user_cellphone
                    user_email
                    user_profileImg
                }
            }
            result
            tokenExpired
        }
    }
`;

export const SEE_PRJ_SCAR_LIST = gql`
    query seePrjScarList(
        $prj_id: Int
        $searchTerm: String
        $start: String
        $end: String
        $sort: scarSort
        $status: scarSignStatusFilter
        $riskGrade: riskGrade
        $label: String
        $take: Int
        $cursor: Int
    ) {
        seePrjScarList(
            prj_id: $prj_id
            searchTerm: $searchTerm
            start: $start
            end: $end
            sort: $sort
            status: $status
            riskGrade: $riskGrade
            label: $label
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            result
            totalLength
            scarList {
                scar_id
                scar_createdAt
                scar_endDate
                scar_completeDate
                scar_creatorId
                scar_title
                scar_number
                scar_spot
                scar_riskGrade
                scar_riskcode
                scar_circulatorLink
                scar_fixedDoc
                scar_signNumber
                scar_signStatus
                now_signLine_group
                now_signLine_name
                signLine {
                    sl_id
                    createdAt
                    create_id
                    create_group
                    create_name
                    create_rank
                    create_countryCode
                    create_cellphone
                    create_email
                    create_img
                    create_status
                    create_rejectStatus
                    create_rejectContent
                    check_id
                    check_group
                    check_name
                    check_rank
                    check_img
                    check_countryCode
                    check_cellphone
                    check_email
                    check_status
                    check_rejectStatus
                    check_rejectContent
                    action_id
                    action_group
                    action_name
                    action_rank
                    action_img
                    action_countryCode
                    action_cellphone
                    action_email
                    action_status
                    action_rejectStatus
                    action_rejectContent
                    manager_id
                    manager_group
                    manager_name
                    manager_rank
                    manager_img
                    manager_countryCode
                    manager_cellphone
                    manager_email
                    manager_status
                    manager_rejectStatus
                    manager_rejectContent
                    actionCheck_id
                    actionCheck_group
                    actionCheck_name
                    actionCheck_rank
                    actionCheck_img
                    actionCheck_countryCode
                    actionCheck_cellphone
                    actionCheck_email
                    actionCheck_status
                    actionCheck_rejectStatus
                    actionCheck_rejectContent
                    finalSign_id
                    finalSign_group
                    finalSign_name
                    finalSign_rank
                    finalSign_img
                    finalSign_countryCode
                    finalSign_cellphone
                    finalSign_email
                    finalSign_status
                    finalSign_rejectStatus
                    finalSign_rejectContent
                    circulators {
                        circ_id
                        circ_cellphone
                        circ_email
                    }
                }
                scarAction {
                    sa_id
                    sa_createdAt
                    sa_text
                    sa_completeDate
                    scarActionImg {
                        sai_id
                        sai_img
                    }
                }
                scar_label {
                    sLbl_id
                    sLbl_name
                }
                img {
                    si_id
                    si_img
                }
                history {
                    sh_id
                    sh_createdAt
                    sh_text
                    sh_userName
                    sh_userImg
                    sh_userRank
                }
            }
        }
    }
`;

export const SEE_LABEL_LIST = gql`
    query seeLabelList($searchTerm: String) {
        seeLabelList(searchTerm: $searchTerm) {
            tokenExpired
            labelList {
                lb_id
                lb_name
            }
        }
    }
`;

export const SEE_SCAR_TOTAL_COUNT = gql`
    query seeScarTotalCount(
        $prjId: Int
        $searchStart: String
        $searchEnd: String
    ) {
        seeScarTotalCount(
            prj_id: $prjId
            searchStart: $searchStart
            searchEnd: $searchEnd
        ) {
            result
            error
            byScarCount {
                total
                complete
                unprocessed
            }
            byLabelScarCount {
                labelName
                createCount
                completeCount
                imcompleteCount
                ratio
            }
            byStatus {
                totalCount
                completeCount
                completeRatio
                inProgressCount
                inProgressRatio
                delayCount
                delayRatio
            }
        }
    }
`;

export const SEE_CHECK_LIST_STATISTICS = gql`
    query seeCheckListStatistics(
        $prjId: Int!
        $searchStart: String!
        $searchEnd: String!
    ) {
        seeCheckListStatistics(
            prj_id: $prjId
            searchStart: $searchStart
            searchEnd: $searchEnd
        ) {
            result
            totalEvaluationInfo {
                totalComplyRate
                avgTotalScore
                avgEvaluationScore
                avgRepeatViolation
            }
            checkListSocreRate {
                totalCheckItemCount
                compliant
                compliantCount
                mainlyCompliant
                mainlyCompliantCount
                partiallyCompliant
                partiallyCompliantCount
                notapplicable
                notapplicableCount
            }
            byMonthStatistics {
                month
                complyRate
            }
            bySafetyCategoryStatistics {
                categoryStatisticsList {
                    item_kr
                    item_eng
                    MA
                    CO
                    PE
                    RE
                    SC
                }
                totalMA
                totalCO
                totalPE
                totalRE
                totalSC
            }
        }
    }
`;

export const SEE_ADMIN_PRJ_CHECK_LIST = gql`
    query seeAdminPrjCheckList(
        $prjId: Int!
        $sort: checkListSort!
        $status: weeklyCheckListStatus!
        $complyRate: String!
        $take: Int!
        $cursor: Int!
    ) {
        seeAdminPrjCheckList(
            prj_id: $prjId
            sort: $sort
            status: $status
            complyRate: $complyRate
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            result
            totalLength
            weeklyCheckList {
                wcl_id
                wcl_createdAt
                wcl_completeDate
                wcl_year
                wcl_month
                wcl_week
                wcl_status
                wcl_complyRate
                wcl_totalScore
                wcl_evaluationScore
                wcl_repeatedViolation
                wcl_evaluation
                wcl_img {
                    wcli_id
                    wcli_url
                }
                wcl_evaluatorGroup
                wcl_evaluatorName
            }
        }
    }
`;

export const SEE_ADMIN_PRJ_CHECK_LIST_DETAIL = gql`
    query seeAdminPrjCheckListDetail($wclId: Int!) {
        seeAdminPrjCheckListDetail(wcl_id: $wclId) {
            tokenExpired
            result
            weeklyCheckListInfo {
                wcl_id
                wcl_year
                wcl_month
                wcl_week
                wcl_evaluatorGroup
                wcl_evaluatorName
                wcl_complyRate
                wcl_totalScore
                wcl_evaluationScore
                wcl_repeatedViolation
                wcl_evaluation
                wcl_evaluationPdf
                wcl_img {
                    wcli_id
                    wcli_url
                }
            }
            weeklySafetyCategory {
                wsc_id
                wsc_safetyCategory_kr
                wsc_safetyCategory_eng
                wsc_complyRate
                wsc_status
            }
        }
    }
`;

export const SEE_ADMIN_PRJ_CHECK_ITEM_DETAIL = gql`
    query seeAdminPrjCheckItemDetail($wscId: Int!) {
        seeAdminPrjCheckItemDetail(wsc_id: $wscId) {
            tokenExpired
            result
            weeklySafetyCategoryInfo {
                wsc_year
                wsc_month
                wsc_week
                wsc_safetyCategory_kr
                wsc_safetyCategory_eng
                wsc_evaluatorGroup
                wsc_evaluatorName
                wsc_complyRate
                wsc_totalScore
                wsc_evaluationScore
                wsc_repeatedViolation
            }
            weeklyCheckItemList {
                wci_id
                wci_checkItem_kr
                wci_checkItem_eng
                wci_score
                wci_comment
                wci_repeatedViolation
            }
        }
    }
`;

export const SEE_CHECK_LIST_SETTING_LIST = gql`
    query seeCheckListSettingList(
        $prjId: Int
        $safetyCategory: String
        $take: Int
        $cursor: Int
    ) {
        seeCheckListSettingList(
            prj_id: $prjId
            safetyCategory: $safetyCategory
            take: $take
            cursor: $cursor
        ) {
            tokenExpired
            result
            totalLength
            prjSafetyCategorySetting {
                prj_scs_id
                safetyCategoryNameKr
                safetyCategoryNameEng
                settingCount
                editCount
                editDate
            }
        }
    }
`;

export const SEE_CHECK_LIST_ITEM_SETTING = gql`
    query seeCheckListItemSetting($prjScsId: Int!) {
        seeCheckListItemSetting(prj_scs_id: $prjScsId) {
            tokenExpired
            result
            safetyCategoryNameKr
            safetyCategoryNameEng
            checkListItem {
                prj_cli_id
                prj_cli_used
                prj_cli_riskCode
                prj_cli_safetyCategory_kr
                prj_cli_safetyCategory_eng
                prj_cli_checkItem_kr
                prj_cli_checkItem_eng
            }
        }
    }
`;

