export const autoHyphen = (phoneNumber) => {
    return phoneNumber
        .replace(/[^0-9]/g, "")
        .replace(
            /(^02|^0505|^1[0-9]{3}|^0[0-9]{2})([0-9]+)?([0-9]{4})$/,
            "$1-$2-$3"
        )
        .replace("--", "-");
};

export const dateFormat = (dateString, type) => {
    const weekDay = ["일", "월", "화", "수", "목", "금", "토"];
    let year;
    let month;
    let day;
    let week;
    let hour;
    let minute;
    let date = new Date(dateString);

    switch (type) {
        case "yyyy.MM":
            year = date.getFullYear();
            month = date.getMonth() + 1;
            month = month >= 10 ? month : "0" + month;
            return `${year}. ${month}`;
        case "yy.MM.dd":
            year = date.getFullYear().toString().slice(-2);
            month = date.getMonth() + 1;
            day = date.getDate();
            week = weekDay[date.getDay()];

            month = month >= 10 ? month : "0" + month;
            day = day >= 10 ? day : "0" + day;

            return year + ". " + month + ". " + day;
        case "yy.MM.dd (W)":
            year = date.getFullYear().toString().slice(-2);
            month = date.getMonth() + 1;
            day = date.getDate();
            week = weekDay[date.getDay()];

            month = month >= 10 ? month : "0" + month;
            day = day >= 10 ? day : "0" + day;

            return year + "." + month + "." + day + ` (${week})`;
        case "yyyy.MM.dd":
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();
            week = weekDay[date.getDay()];

            month = month >= 10 ? month : "0" + month;
            day = day >= 10 ? day : "0" + day;

            return year + ". " + month + ". " + day;
        case "yyyy-MM-dd":
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();
            week = weekDay[date.getDay()];

            month = month >= 10 ? month : "0" + month;
            day = day >= 10 ? day : "0" + day;

            return year + "-" + month + "-" + day;
        case "yyyy.MM.dd - hh:mm":
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();

            hour = date.getHours();
            minute = date.getMinutes();

            month = month >= 10 ? month : "0" + month;
            day = day >= 10 ? day : "0" + day;
            hour = hour >= 10 ? hour : "0" + hour;
            minute = minute >= 10 ? minute : "0" + minute;

            return year + ". " + month + ". " + day + " - " + hour + ":" + minute;
        case "yyyy.MM.dd hh:mm":
            year = date.getFullYear().toString();
            month = date.getMonth() + 1;
            day = date.getDate();

            hour = date.getHours();
            minute = date.getMinutes();

            month = month >= 10 ? month : "0" + month;
            day = day >= 10 ? day : "0" + day;
            hour = hour >= 10 ? hour : "0" + hour;
            minute = minute >= 10 ? minute : "0" + minute;

            return year + ". " + month + ". " + day + " " + hour + ":" + minute;
      case "yyyy-MM-dd hh:mm":
        year = date.getFullYear().toString();
        month = date.getMonth() + 1;
        day = date.getDate();

        hour = date.getHours();
        minute = date.getMinutes();

        month = month >= 10 ? month : "0" + month;
        day = day >= 10 ? day : "0" + day;
        hour = hour >= 10 ? hour : "0" + hour;
        minute = minute >= 10 ? minute : "0" + minute;

        return year + "-" + month + "-" + day + " " + hour + ":" + minute;
        default:
            return date;
    }
};
