import React, { memo, useContext, useState, useCallback } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../../share/StyledRadioButton";
import { PrjDetailContext } from "../../../../../routes/Project/Project/ProjectDetail/ProjectDetailContainer";
import { dateFormat } from "../../../../../utils/common";

const Container = styled.div`
  width: 100%;
  height: 480px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: ${({ theme }) => theme.scrollBackgroundColor};
  }
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.grayBackgroundColor};
  }
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  margin: ${({ margin }) => margin};
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  white-space: nowrap;
  padding-top: 3px;
  color: ${({ theme, GRAY }) =>
    GRAY ? `${theme.tableLinkFontColor}` : `${theme.defaultFontColor}`};
`;

const CheckSetRowTable = ({ checkListSettingList }) => {
  const { radioIndex, setRadioIndex } = useContext(PrjDetailContext);

  const onClickRadio = useCallback(
    (index) => {
      if (index === radioIndex) {
        setRadioIndex(null);
        return;
      }
      setRadioIndex(index);
    },
    [radioIndex]
  );

  return (
    <Container>
      {checkListSettingList?.map((data, index) => (
        <RowBox
          key={data?.prj_scs_id}
          onClick={() => onClickRadio(data?.prj_scs_id)}
        >
          <StyledRadioButton clicked={radioIndex === data?.prj_scs_id} />

          <Text width={263}>{data?.safetyCategoryNameKr}</Text>
          <Text width={166}>{data?.settingCount}개</Text>
          <Text width={999}>{data?.editCount}개</Text>
          <Text width={150}>
            {data?.editDate !== ""
              ? dateFormat(parseInt(data?.editDate, 10), "yyyy-MM-dd hh:mm")
              : "-"}
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(CheckSetRowTable);
