import { gql } from "@apollo/client";

export const CREATE_NOTICE = gql`
  mutation createNotice(
    $noti_title: String
    $noti_text: String
    $noti_imgSelect: Boolean
    $noti_img: Upload
  ) {
    createNotice(
      noti_title: $noti_title
      noti_text: $noti_text
      noti_imgSelect: $noti_imgSelect
      noti_img: $noti_img
    ) {
      tokenExpired
      result
    }
  }
`;

export const EDIT_NOTICE = gql`
  mutation editNotice(
    $noti_id: Int
    $noti_title: String
    $noti_text: String
    $noti_imgSelect: Boolean
    $noti_img: Upload
  ) {
    editNotice(
      noti_id: $noti_id
      noti_title: $noti_title
      noti_text: $noti_text
      noti_imgSelect: $noti_imgSelect
      noti_img: $noti_img
    ) {
      tokenExpired
      result
    }
  }
`;

export const DELETE_NOTICE = gql`
  mutation deleteNotice($noti_id: Int) {
    deleteNotice(noti_id: $noti_id) {
      tokenExpired
      result
    }
  }
`;
