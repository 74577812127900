import React, { useState, useContext, useCallback } from "react";
import StyledModal from "../../../share/StyledModal";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftjsToHtml from "draftjs-to-html";
import { ServiceCenterContext } from "../../../../routes/Management/ServiceCenter/ServiceCenterContainer";
import { useMutation } from "@apollo/client";
import { CREATE_TOS } from "../../../../graphql/Management/ServiceCenter/mutation";
import { toast } from "react-toastify";
import { AuthContext } from "../../../../context/AuthProvider";

const AddModal = () => {
  const { userLogout } = useContext(AuthContext);
  const { handleAddTermsModal, TOSRefetch } = useContext(ServiceCenterContext);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlString, setHtmlString] = useState("");

  const [createTOS] = useMutation(CREATE_TOS);

  const updateTextDescription = (state) => {
    setEditorState(state);
    const html = draftjsToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtmlString(html);
  };

  const handleSave = useCallback(async () => {
    if (htmlString.trim() === "") {
      toast.error("약관 내용을 입력해주세요.");
      return;
    }
    try {
      const { data } = await createTOS({
        variables: {
          tos_text: htmlString,
        },
      });
      if (data?.createTOS?.result) {
        await TOSRefetch();
        toast.success("약관을 등록하였습니다.");
        handleAddTermsModal();
      }
      if (data?.createTOS && !data?.createTOS?.tokenExpired) {
        userLogout();
      }
    } catch (e) {
      toast.error(e.message);
    }
  }, [htmlString]);

  return (
    <StyledModal
      title="약관 등록하기"
      width={1000}
      height={637}
      handleClose={handleAddTermsModal}
      handleSave={handleSave}
      btnLabel="등록"
    >
      <Editor
        placeholder="게시글을 작성해주세요"
        editorState={editorState}
        onEditorStateChange={updateTextDescription}
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "history",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        localization={{ locale: "ko" }}
        editorStyle={{
          height: "350px",
          width: "100%",
          border: "1px solid lightgray",
          padding: "20px",
        }}
      />
    </StyledModal>
  );
};

export default AddModal;
