import { gql } from "@apollo/client";

export const SEE_PRJ_END_REQUEST_LIST = gql`
  query SeePrjEndRequestList(
    $searchTerm: String
    $sort: projectSort
    $take: Int
    $cursor: Int
  ) {
    seePrjEndRequestList(
      searchTerm: $searchTerm
      sort: $sort
      take: $take
      cursor: $cursor
    ) {
      tokenExpired
      result
      totalLength
      projectList {
        prj_id
        prj_img
        prj_endRequestDate
        prj_title
        prj_startDate
        prj_endDate
        prj_address
        prj_number
        prj_memberCount
        prj_scarCount
        prj_requester
      }
    }
  }
`;
