import { gql } from "@apollo/client";

export const SEE_RISKCODE_LIST = gql`
  query seeRiskCodeList($searchTerm: String, $take: Int, $cursor: Int) {
    seeRiskCodeList(searchTerm: $searchTerm, take: $take, cursor: $cursor) {
      tokenExpired
      totalLength
      riskCodeList {
        rc_id
        rc_name
        rc_title
        rc_text
      }
    }
  }
`;
