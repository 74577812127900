import React from "react";
import styled from "styled-components";
import modalCloseBtn from "../../assets/icon/modalCloseBtn.svg";
import StyledButton from "./StyledButton";

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 100;
`;

const DarkBg = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
`;

const Modal = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  padding: 40px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 30px;
`;

const CloseIcon = styled.div`
  width: 27px;
  height: 27px;
  position: absolute;
  right: 40px;
  background-image: url(${modalCloseBtn});
  cursor: pointer;
`;

const BottomBtnWrapper = styled.div`
  width: 100%;
  text-align: end;
  margin-top: auto;
`;

const StyledModal = ({
  children,
  width = 640,
  height = 580,
  title = "메일 보내기",
  CLOSEBTN,
  handleClose = () => {},
  handleSave = () => {},
  btnLabel = "전송",
}) => {
  return (
    <Container>
      <DarkBg onClick={() => handleClose()} />

      <Modal width={width} height={height}>
        <Title>{title}</Title>
        <CloseIcon onClick={() => handleClose()} />
        {children}
        <BottomBtnWrapper>
          {CLOSEBTN ? (
            <StyledButton BLACK label="닫기" onClick={() => handleClose()} />
          ) : (
            <>
              <StyledButton label="취소" onClick={() => handleClose()} />
              <StyledButton
                BLACK
                label={btnLabel}
                margin="0  0 0 20px"
                onClick={() => handleSave()}
              />
            </>
          )}
        </BottomBtnWrapper>
      </Modal>
    </Container>
  );
};

export default StyledModal;
