import React, { useContext } from "react";
import styled from "styled-components";
import GuideLine from "../../../components/layout/GuideLine";
import SortingForm from "../../../components/layout/SortingForm";
import StyledRowTable from "../../../components/share/StyledRowTable";
import PageNation from "../../../components/layout/PageNation";
import RowTable from "../../../components/feature/Management/ServiceCenter/RowTable";
import AddModal from "../../../components/feature/Management/ServiceCenter/AddModal";
import StyledInput from "../../../components/share/StyledInput";
import StyledButton from "../../../components/share/StyledButton";
import { ServiceCenterContext } from "./ServiceCenterContainer";
import StyledIconButton from "../../../components/share/StyledIconButton";

const Container = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1080px;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const ContentContainer = styled.div`
  min-width: 1694px;
  width: 100%;
  height: 1041px;
  padding: 20px 16px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const TitleBox = styled.div`
  width: 372px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin-top: 30px;
  background-color: ${({ theme }) => theme.grayBackgroundColor};
`;

const RowBox = styled.div`
  width: 372px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
`;

const PhoneNumberContainer = styled.div`
  height: 840px;
  max-width: 1646px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
`;

const PhoneNumber = styled.div`
  width: 372px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px;
  margin-right: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
`;

const ServiceCenterPresenter = ({}) => {
  const {
    inputs,
    setInputs,
    handlePlus,
    handleMinus,
    page,
    totalLength,
    onClickPage,
    addTermsModal,
    phoneNumberInput,
    setPhoneNumberInput,
    onClickAddPhoneNumber,
    vocList,
    handleMinusPhoneNumber,
  } = useContext(ServiceCenterContext);

  return (
    <>
      <Container>
        <GuideLine label={["운영관리", "고객센터"]} />
        <ContentContainer>
          <SortingForm
            inputs={inputs}
            setInputs={setInputs}
            handlePlus={handlePlus}
            handleMinus={handleMinus}
            PLUS={inputs.sort === "이용약관 등록"}
            MINUS={inputs.sort === "이용약관 등록"}
            SERVICESORT
          />
          {!(inputs.sort === "이용약관 등록") ? (
            <PhoneNumberContainer>
              <TitleBox>고객센터 전화번호 등록</TitleBox>
              <RowBox>
                <StyledInput
                  width={290}
                  placeholder="전화번호를 입력하세요"
                  value={phoneNumberInput}
                  onChange={(e) => setPhoneNumberInput(e.target.value)}
                />
                <StyledButton
                  BLACK
                  label="등록"
                  onClick={onClickAddPhoneNumber}
                />
              </RowBox>
              {vocList.map((num, index) => (
                <PhoneNumber key={index}>
                  {num.vn_callNumber}
                  <StyledIconButton
                    MINUS
                    GRAY
                    onClick={() => handleMinusPhoneNumber(num.vn_id)}
                  />
                </PhoneNumber>
              ))}
            </PhoneNumberContainer>
          ) : (
            <>
              <StyledRowTable
                RADIO
                label={[
                  { text: "등록일", width: 142 },
                  { text: "작성자", width: 102 },
                  { text: "약관 내용", width: 1212 },
                  { text: "삭제일", width: 120 },
                ]}
              />
              <RowTable />
              <PageNation
                page={page}
                onClickPage={onClickPage}
                totalLength={totalLength}
              />
            </>
          )}
        </ContentContainer>
      </Container>
      {addTermsModal && <AddModal />}
    </>
  );
};

export default ServiceCenterPresenter;
