import React, { memo, useContext } from "react";
import styled from "styled-components";
import StyledRadioButton from "../../../share/StyledRadioButton";
import { NoticeContext } from "../../../../routes/Management/Notice/NoticeContainer";
import { dateFormat } from "../../../../utils/common";

const Container = styled.div`
  width: 100%;
  height: 834px;
`;

const RowBox = styled.div`
  width: 100%;
  min-width: 1645px;
  height: 48px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.grayBackgroundColor};
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
`;

const Text = styled.div`
  width: ${({ width }) => width}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  padding-right: 20px;
  color: ${({ theme }) => theme.defaultFontColor};
  padding-top: 3px;
`;

const RowTable = () => {
  const { noticeList, radioIndex, onClickRadio } = useContext(NoticeContext);
  return (
    <Container>
      {noticeList.map((data, index) => (
        <RowBox key={index}>
          <StyledRadioButton
            clicked={radioIndex === index}
            onClick={() => onClickRadio(index)}
          />
          <Text width={142}>
            {dateFormat(Number(data.noti_createdAt), "yyyy.MM.dd")}
          </Text>
          <Text width={238}>{data.noti_title}</Text>
          <Text width={946}>
            {data.noti_text === "" ? "-" : data.noti_text}
          </Text>
          <Text width={244}>
            {data.noti_img.split("/").reverse()[0].split("-")[1]}
          </Text>
        </RowBox>
      ))}
    </Container>
  );
};

export default memo(RowTable);
