import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import StyledCheckbox from "../../../share/StyledCheckbox";
import { FiX } from "react-icons/fi";
import StyledButton from "../../../share/StyledButton";
import { useMutation } from "@apollo/client";
import { EDIT_PERMISSION } from "../../../../graphql/Admin/mutation";
import { toast } from "react-toastify";
import { AdminContext } from "../../../../routes/Admin/AdminList/AdminListContainer";

const DarkBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 10;
`;

const ModalContainer = styled.div`
  width: 308px;
  height: 588px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin-left: 1162px;
  margin-top: ${({ index }) => `calc(208px + 46px * ${index})`};
  border-radius: 19px;
  background-color: ${({ theme }) => theme.defaultBackgroundColor};
  position: relative;
  z-index: 20;
`;

const Title = styled.div`
  font-weight: 400;
`;

const CloseIcon = styled.div`
  width: 18px;
  cursor: pointer;
`;

const BottomButtons = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  margin: ${({ margin }) => margin};
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;
const Text = styled.div`
  margin: ${({ margin }) => margin};
  color: ${({ gray, theme }) =>
    gray ? `${theme.textFieldPlaceHolder}` : `${theme.defaultBackgroundColor}`};
`;

const RowBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  & > ${Text} {
    cursor: pointer;
  }
`;

const BlackSection = styled.div`
  width: 310px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 40px;
  margin-left: -41px;
  color: ${({ theme }) => theme.defaultBackgroundColor};
  background-color: ${({ theme }) => theme.mainColor};
`;

const PermissionModal = ({ handleModal, index, userData }) => {
  const { adminListRefetch } = useContext(AdminContext);

  const [permission, setPermission] = useState({
    home: userData.adminPermission.home,
    project: userData.adminPermission.project,
    user_manage: userData.adminPermission.user_manage,
    manage: userData.adminPermission.manage,
  });

  const [editPermission] = useMutation(EDIT_PERMISSION);

  const onChangePermission = useCallback(
    (name, value) => {
      setPermission({
        ...permission,
        [name]: value,
      });
    },
    [permission]
  );

  const onClickSave = useCallback(async () => {
    try {
      const { data } = await editPermission({
        variables: {
          admin_id: userData.admin_id,
          user_manage: permission.user_manage,
          project: permission.project,
          manage: permission.manage,
        },
      });
      if (data?.editPermission?.result) {
        toast.success("권한이 수정되었습니다.");
        await adminListRefetch();
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      handleModal();
    }
  }, [permission]);

  return (
    <DarkBackground>
      <ModalContainer index={index <= 5 ? index : 5}>
        <BottomButtons justifyContent="space-between" margin="0 0 25px 0">
          <Title>권한수정</Title>
          <CloseIcon onClick={handleModal}>
            <FiX />
          </CloseIcon>
        </BottomButtons>
        <Container>
          <BlackSection>
            {userData?.admin_name} {userData?.admin_rank}
          </BlackSection>
          <Text gray margin="20px 0 12px 0">
            권한선택
          </Text>

          <RowBox
            onClick={() => onChangePermission("project", !permission.project)}
          >
            <StyledCheckbox clicked={permission.project} />
            <Text gray>프로젝트관리</Text>
          </RowBox>
          <RowBox
            onClick={() =>
              onChangePermission("user_manage", !permission.user_manage)
            }
          >
            <StyledCheckbox clicked={permission.user_manage} />
            <Text gray>유저관리</Text>
          </RowBox>
          <RowBox
            onClick={() => onChangePermission("manage", !permission.manage)}
          >
            <StyledCheckbox clicked={permission.manage} />
            <Text gray>운영관리</Text>
          </RowBox>
        </Container>
        <BottomButtons justifyContent="flex-end" margin="auto 0 0 0">
          <StyledButton
            label="취소"
            margin="0 12px 0 0"
            onClick={handleModal}
          />
          <StyledButton BLACK label="저장" onClick={onClickSave} />
        </BottomButtons>
      </ModalContainer>
    </DarkBackground>
  );
};

export default PermissionModal;
