import {gql} from "@apollo/client";

export const EDIT_USER_CHANGE = gql`
  mutation editUserChange(
    $user_id: Int
    $user_group: String
  ) {
    editUserChange(
      user_id: $user_id
      user_group: $user_group
    ) {
      tokenExpired
      result
    }
  }
`;